import { FC } from "react";
import { Box, Grid, Avatar, Divider } from "@mui/material";
import { useTranslation } from "react-i18next";
import { SearchUser } from "../../../types/Types";

type userCardProps = {
  user: SearchUser;
};

export const UserCard: FC<userCardProps> = ({ user }) => {
  const { t } = useTranslation("common");

  return (
    <>
      <Box
        sx={{
          m: "2vh",
          p: "2vh",
          width: "40%",
          display: "flex",
          flexDirection: "column",
          border: "1px dashed grey",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "start",
            alignItems: "center",
            flexDirection: "row",
          }}
        >
          <Avatar sx={{ margin: "10px 0 10px 10px" }}>
            {user?.givenname[0].toUpperCase()}
          </Avatar>
          <Box sx={{ marginLeft: "5px" }}>{user?.givenname}</Box>
        </Box>

        <Divider />

        <Box sx={{ margin: 5 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <h4>
                {t("Pension Number")}: {user?.pensionnumber}
              </h4>
              <h4>
                {t("Last Name")}: {user?.givenname}
              </h4>
              <h4>
                {t("OU")}: {user?.pensionnumber}
              </h4>
              <h4>
                {t("Phone Number")}: {user?.telephonenumber}
              </h4>
              <h4>
                {t("First Name")}/{t("Last Name")}/{t("Title")}:{" "}
                {user?.displayname}
              </h4>
            </Grid>
            <Grid item xs={12} sm={6}>
              <h4>
                {t("First Name")}: {user?.givenname}
              </h4>
              <h4>
                {t("E-mail")}: {user?.mail}
              </h4>
              <h4>
                {t("Number")}: {user?.dn}
              </h4>
              <h4>
                {t("Title")}: {user?.title}
              </h4>
              <h4>
                {t("Duty")}: {user?.description}
              </h4>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </>
  );
};
