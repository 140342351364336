import "./App.css";

import { SnackbarProvider } from "notistack";
import { QueryClient, QueryClientProvider } from "react-query";
import { BrowserRouter } from "react-router-dom";
import { RouteByAuth } from "./routes";

import { styled } from "@mui/material";
import { ProvideAuth } from "./contexts/Auth.context";
import { I18nextProvider } from "react-i18next";
import i18next from "i18next";

import { StorageInfoProvider } from "./pages/StreamPOC/StorageInfoContext";

import common_en from "./translations/en/common.json";
import common_tr from "./translations/tr/common.json";
import { Global, css } from '@emotion/react';

import {ToastContainer} from "react-toastify";

import 'react-toastify/dist/ReactToastify.css';
import { ProvideSettings } from "./contexts/Settings.context";

i18next.init({
  interpolation: { escapeValue: false },
  lng: "tr",
  resources: {
    en: {
      common: common_en,
    },
    tr: {
      common: common_tr,
    },
  },
});

const queryClient = new QueryClient();
const StyledSnackbarProvider = styled(SnackbarProvider)`
  .notistack-SnackbarContainer:has(&) {
    top: 84px !important;
  }
`;

function App() {
  return (
    <StorageInfoProvider>
      <BrowserRouter>
        <StyledSnackbarProvider
          maxSnack={3}
          style={{
            top: "70px",
          }}
          anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
          autoHideDuration={200000}
        >
          <QueryClientProvider client={queryClient}>
            <I18nextProvider i18n={i18next}>
              <ProvideAuth>
                <ProvideSettings>
                  <Global styles={css`
                    html, body, #root {
                      height: 100%;
                      width: 100%;
                    }

                    *, *::before, *::after {
                      box-sizing: border-box;
                    }
                  `} />
                  <RouteByAuth />
                </ProvideSettings>
              </ProvideAuth>
            </I18nextProvider>
          </QueryClientProvider>
        </StyledSnackbarProvider>
        <ToastContainer
          style={{
            top: 84
          }}
          limit={3}
          newestOnTop
        />
      </BrowserRouter>
    </StorageInfoProvider>
  );
}

export default App;
