import { FC, useEffect, useState } from "react";
import { Box, useTheme, Modal, Card, Alert, AlertTitle } from "@mui/material";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import LockIcon from "@mui/icons-material/Lock";
import DeleteIcon from "@mui/icons-material/Delete";
import TextField from "@mui/material/TextField";
import { UserSignUp } from "../UserSignUp/UserSignUp";
import CloseIcon from "@mui/icons-material/Close";

import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { PasswordModal } from "./PasswordModal/PasswordModal";
import { useTranslation } from "react-i18next";
import { SetUser } from "../../../types/Types";

import{ api } from "../../../utils/api";

export const UserSettings: FC = () => {
  const { t } = useTranslation("common");
  const theme = useTheme();

  const [users, setUsers] = useState<SetUser[]>([]);
  const [search_text, setSearch_text] = useState("");
  const [users_loading, setUsers_loading] = useState(false);
  const [edit_modal_visible, setEdit_modal_visible] = useState(false);
  const [password_modal_visible, setPassword_modal_visible] = useState(false);
  const [selected_user, setSelected_user] = useState({});
  const [modalOpen, setModalOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [currentUser, setCurrentUser] = useState<SetUser>();
  const [alertSuccess, setAlertSuccess] = useState(false);
  const [alertError, setAlertError] = useState(false);
  const [alertcontent, setalertcontent] = useState("");
  const [tableAlertError, setTableAlertError] = useState(false);
  const [tableAlertcontent, setTableAlertcontent] = useState("");

  const columns: GridColDef[] = [
    {
      field: "ID",
      headerName: t("Order"),
      width: 50,
      editable: false,
    },
    {
      field: "SAMACCOUNTNAME",
      headerName: t("ID Number"),
      width: 200,
      editable: false,
    },
    {
      field: "PENSIONNUMBER",
      headerName: t("Pension Number"),
      width: 200,
      editable: false,
    },
    {
      field: "GIVENNAME",
      headerName: t("First Name"),
      width: 200,
      editable: false,
    },
    {
      field: "SN",
      headerName: t("Last Name"),
      width: 200,
      editable: false,
    },
    {
      field: "TITLE",
      headerName: t("Title"),
      width: 200,
      editable: false,
    },
    {
      field: "TYPE",
      headerName: t("User Type"),
      width: 100,
      editable: false,
    },
    {
      field: "actions",
      headerName: t("Actions"),
      sortable: false,
      width: 200,
      renderCell: (params) => {
        return (
          <div
            className="d-flex justify-content-between align-items-center"
            style={{ cursor: "pointer" }}
          >
            <IconButton
              onClick={() => {
                setModalOpen(true);
                setCurrentUser(users.find((user) => user.ID === params.id));
              }}
            >
              <EditIcon />
            </IconButton>
            <IconButton
              onClick={() => {
                setPassword_modal_visible(true);
                setCurrentUser(users.find((user) => user.ID === params.id));
              }}
            >
              <LockIcon />
            </IconButton>
            <IconButton
              onClick={() => {
                setDeleteModalOpen(true);
                setCurrentUser(users.find((user) => user.ID === params.id));
              }}
            >
              <DeleteIcon />
            </IconButton>
          </div>
        );
      },
    },
  ];

  const rows = users
    .map((user) => ({ ...user, id: user.ID }))
    .filter((row) => {
      return (
        row.SAMACCOUNTNAME.toLowerCase().includes(search_text.toLowerCase()) ||
        row.PENSIONNUMBER.toLowerCase().includes(search_text.toLowerCase()) ||
        row.GIVENNAME.toLowerCase().includes(search_text.toLowerCase()) ||
        row.SN.toLowerCase().includes(search_text.toLowerCase())
      );
    });

  useEffect(() => {
    getUsers();
  }, []);

  const getUsers = () => {
    setUsers_loading(true);
    api.base.get("/account/get_users")
      .then((response: any) => {
        const userss = response.data.map((user: SetUser, index: number) => {
          return {
            key: index,
            ...user,
          };
        });
        setUsers(userss);
      })
      .catch(() => {
        setTableAlertcontent(
          t("Unable to get user, failed to connect to server!")
        );
        setTableAlertError(true);
        const timer = setTimeout(() => {
          setTableAlertError(false);
        }, 2000);
        return () => clearTimeout(timer);
      })
      .finally(() => {
        setUsers_loading(false);
      });
  };

  const deleteUser = (id: number | undefined) => () => {
    setUsers_loading(true);
    api.base.delete("/account/delete_user", {
      data: {
        user_id: id,
      }
    })
      .then(() => {
        const userss = users.filter((user) => user.ID !== id);
        setUsers(userss);
        setDeleteModalOpen(true);
        setalertcontent(t("User deleted successfully!"));
        setAlertSuccess(true);
        const timer = setTimeout(() => {
          setAlertSuccess(false);
          setDeleteModalOpen(false);
        }, 2000);
        return () => clearTimeout(timer);
      })
      .catch((error) => {
        setDeleteModalOpen(true);
        setalertcontent(
          t("Unable to delete user, failed to connect to server!")
        );
        setAlertError(true);
        const timer = setTimeout(() => {
          setAlertError(false);
        }, 2000);
        return () => clearTimeout(timer);
      })
      .finally(() => {
        setUsers_loading(false);
      });
  };
  console.log(users, currentUser, "a")
  return (
    <>
      <Box
        sx={{
          mt: 3,
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Button
          sx={{
            fontSize: "small",
            textTransform: "none",
            backgroundColor: theme.palette.primary.main,
            "&:hover": {
              backgroundColor: theme.palette.primary.main,
            },
          }}
          variant="contained"
          fullWidth
          onClick={getUsers}
        >
          {t("Refresh Table")}
        </Button>

        <TextField
          fullWidth
          id="searchbox"
          InputLabelProps={{
            style: {
              fontSize: "12px",
            },
          }}
          label={t("Search User")}
          name="searchbox"
          autoComplete="family-name"
          onChange={(e) => setSearch_text(e.target.value)}
        />
      </Box>

      <Box
        sx={{
          marginTop: "3%",
          opacity: users_loading ? "0.5" : "1",
        }}
      >
        <Modal
          open={modalOpen}
          aria-labelledby="parent-modal-title"
          aria-describedby="parent-modal-description"
        >
          <Card
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-end",
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              stroke: "none",
              padding: 3,
            }}
          >
            <IconButton onClick={() => setModalOpen(false)}>
              <CloseIcon />
            </IconButton>
            <UserSignUp
              modalOpen={true}
              user={users.find(
                (person) =>
                  person.SAMACCOUNTNAME === currentUser?.SAMACCOUNTNAME
              )}
            />
          </Card>
        </Modal>

        <Modal
          open={deleteModalOpen}
          aria-labelledby="parent-modal-title"
          aria-describedby="parent-modal-description"
        >
          <Card
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-end",
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              stroke: "none",
              padding: 3,
            }}
          >
            {alertSuccess && (
              <Alert severity="success" sx={{ mb: 2 }}>
                <AlertTitle sx={{ fontSize: "24px" }}>
                  {t("Success!")}
                </AlertTitle>
                {alertcontent}
              </Alert>
            )}

            {alertError && (
              <Alert severity="error" sx={{ mb: 2 }}>
                <AlertTitle sx={{ fontSize: "24px" }}>{t("Error!")}</AlertTitle>
                {alertcontent}
              </Alert>
            )}

            {t("Are you sure you want to delete the selected user?")}
            <Button
              fullWidth
              variant="contained"
              onClick={() => {
                setDeleteModalOpen(false);
              }}
              sx={{
                mt: 3,
                mb: 2,
                backgroundColor: theme.palette.primary.main,
                "&:hover": {
                  backgroundColor: theme.palette.primary.main,
                },
                textTransform: "none",
              }}
            >
              {t('No')}
            </Button>
            <Button
              fullWidth
              variant="contained"
              onClick={deleteUser(currentUser?.ID)}
              sx={{
                mt: 3,
                mb: 2,
                backgroundColor: theme.palette.primary.main,
                "&:hover": {
                  backgroundColor: theme.palette.primary.main,
                },
                textTransform: "none",
              }}
            >
              {t('Yes')}
            </Button>
          </Card>
        </Modal>

        <Box>
          {tableAlertError && (
            <Alert severity="error" sx={{ mb: 2 }}>
              <AlertTitle sx={{ fontSize: "24px" }}>{t("Error!")}</AlertTitle>
              {tableAlertcontent}
            </Alert>
          )}

          <DataGrid
            rows={rows}
            columns={columns}
            sx={{ fontSize: "small" }}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: rows.length !== 0 ? rows.length : 10,
                },
              },
            }}
            pageSizeOptions={[rows.length !== 0 ? rows.length : 10]}
            checkboxSelection
            disableRowSelectionOnClick
          />
        </Box>
        {password_modal_visible && (
          <Box>
            <PasswordModal
              user={users.find(
                (person) =>
                  person.SAMACCOUNTNAME === currentUser?.SAMACCOUNTNAME
              )}
              setPassword_modal_visible={setPassword_modal_visible}
            />
          </Box>
        )}
      </Box>
    </>
  );
};
