import { FC, useState } from "react";
import { Box } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { TransferList } from "./TransferList/TransferList";
import { UserCard } from "./UserCard/UserCard";
import { UserSearch } from "./UserSearch/UserSearch";
import { Iha, SearchUser } from "../../types/Types";

export const Privileges: FC = () => {
  const [loading, setLoading] = useState(false);
  const [user_searched, setUser_searched] = useState(false);
  const [user, setUser] = useState<SearchUser>();
  const [ihas, setIhas] = useState<Iha[]>([]);

  return (
    <>
      <UserSearch
        setIhas={setIhas}
        setUser={setUser}
        setUser_searched={setUser_searched}
        setLoading={setLoading}
      />
      {loading && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress color="primary" />
        </Box>
      )}
      {!loading && user_searched && user && (
        <Box
          sx={{
            display: "flex",
          }}
        >
          <UserCard user={user} />
          <TransferList ihas={ihas} user={user} />
        </Box>
      )}
    </>
  );
};
