import { BroadcastSettings } from "../../pages/BroadcastSettings/BroadcastSettings";
import { Help } from "../../pages/Help/Help";
import { ImageDetectionUpload } from "../../pages/ImageDetectionUpload/ImageDetectionUpload";
import { PrecisionSetting } from "../../pages/PrecisionSetting/PrecisionSetting";
import { Privacy } from "../../pages/Privacy/Privacy";
import { Privileges } from "../../pages/Privileges/Priveleges";
import { Settings } from "../../pages/Settings/Settings";
import { Stream } from "../../pages/Stream/Stream";
import { Users } from "../../pages/Users/Users";
import { VideoDetectionUpload } from "../../pages/VideoDetectionUpload/VideoDetectionUpload";
import { ExtendedRouteProps } from "../../types/Route";
import { StreamPOC } from '../../pages/StreamPOC';
import { PlaceholderImageDetectionUpload } from '../../pages/PlaceholderImageDetectionUpload';
import { PlaceholderVideoDetectionUpload } from '../../pages/PlaceholderVideoDetectionUpload ';
import { ModelManagement } from "../../pages/ModelManagement/ModelManagement";
import { DetectionLog } from "../../pages/DetectionLog/DetectionLog";

export const authorizedRoutes: ExtendedRouteProps[] = [
  {
    path: "/streampoc",
    element: <StreamPOC />
  },
  {
    path: "/",
    // element: <Stream />
    element: <StreamPOC />
  },
  {
    path: "/broadcast-settings",
    element: <BroadcastSettings />,
  },
  {
    path: "/users",
    element: <Users />,
  },
  {
    path: "/privileges",
    element: <Privileges />,
  },
  {
    path: "/modelmanagement",
    element: <ModelManagement />,
  },
  {
    path: "/settings",
    element: <Settings />,
  },
  {
    path: "/precision-setting",
    element: <PrecisionSetting />,
  },
  {
    path: "/privacy",
    element: <Privacy />,
  },
  {
    path: "/help",
    element: <Help />,
  },
  {
    path: "/stream",
    element: <StreamPOC />,
  },
  {
    path: "/image-detection-upload",
    element: <ImageDetectionUpload />,
  },
  {
    path: "/image-detection",
    element: <PlaceholderImageDetectionUpload />,
  },
  {
    path: "/image-detectionold",
    element: <ImageDetectionUpload />,
  },
  {
    path: "/video-detection-upload",
    element: <PlaceholderVideoDetectionUpload />,
  },
  // {
  //   path: "/video-detection-uploadold",
  //   element: <VideoDetectionUpload />,
  // },
  {
    path: "/video-detection",
    element: <PlaceholderVideoDetectionUpload />,
  },
  // {
  //   path: "/video-detectionold",
  //   element: <VideoDetectionUpload />,
  // },
  {
    path: "/detection-log",
    element: <DetectionLog />,
  },
];
