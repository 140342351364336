import { FC, useEffect, useMemo, useState} from "react";
import Box from "@mui/material/Box";
import { TableContainer, styled, tableCellClasses, TableCell, TableRow, Table, TableHead, TableBody, Paper, TablePagination} from "@mui/material";
import { useTranslation } from "react-i18next";
import { boundingBox } from "../../types/Types";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    fontSize: 16,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 12,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

type DetectionLogTableProps = {
  boundingBoxes: boundingBox[],
  submitted: boolean,
};

const ITEMS_PER_PAGE = 100;

export const DetectionLogTable: FC<DetectionLogTableProps> = ({boundingBoxes, submitted}) => {

  const { t } = useTranslation("common");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [displayedData, setDisplayedData] = useState<boundingBox[]>([]);
  const [loadedPages, setLoadedPages] = useState(0);

  useEffect(() => {
    if (submitted) {
      loadMoreData();
    }
  }, [submitted]);

  const loadMoreData = () => {
    const newLoadedPages = loadedPages + 1;
    const newData = boundingBoxes.slice(0, newLoadedPages * ITEMS_PER_PAGE);
    setDisplayedData(newData);
    setLoadedPages(newLoadedPages);
  };
  
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
    if ((newPage + 1) * rowsPerPage > displayedData.length) {
      loadMoreData();
    }
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const paginatedData = useMemo(() => {
    const startIndex = page * rowsPerPage;
    const endIndex = startIndex + rowsPerPage;
    return displayedData.slice(startIndex, endIndex);
  }, [page, rowsPerPage, displayedData]);

  return (
    <>
      <Box sx={{visibility: submitted ? "visible" : "hidden", marginBottom: 4}}>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 700, height: "100%" }} aria-label="customized table">
            <TableHead sx={{ backgroundColor: 'white', color: 'black'}}>
              <TableRow>
                <StyledTableCell>{t("Frame ID")}</StyledTableCell>
                <StyledTableCell align="center" >{t("Model Class")}</StyledTableCell>
                <StyledTableCell align="center" >{t("Threshold Value")}</StyledTableCell>
                <StyledTableCell align="center" >{t("X1")}</StyledTableCell>
                <StyledTableCell align="center" >{t("Y1")}</StyledTableCell>
                <StyledTableCell align="center" >{t("X2")}</StyledTableCell>
                <StyledTableCell align="center" >{t("Y2")}</StyledTableCell>
                <StyledTableCell align="center" >{t("Date")}</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
                {paginatedData.map((row) => (
                  <StyledTableRow key={row.ID}>
                    <StyledTableCell component="th" scope="row">{row.FRAME_ID}</StyledTableCell>
                    <StyledTableCell align="center">{(row.C === 1 && t('person')) || (row.C === 2 && t('Animal')) || (row.C === 3 && t('Vehicle')) || t('Unknown')}</StyledTableCell>
                    <StyledTableCell align="center">{row.CONF}</StyledTableCell>
                    <StyledTableCell align="center">{row.X1}</StyledTableCell>
                    <StyledTableCell align="center">{row.Y1}</StyledTableCell>
                    <StyledTableCell align="center">{row.X2}</StyledTableCell>
                    <StyledTableCell align="center">{row.Y2}</StyledTableCell>
                    <StyledTableCell align="center">{new Date(row.TARIH).toLocaleString('tr-TR', {year: 'numeric', month: 'numeric', day: 'numeric',})}</StyledTableCell>
                  </StyledTableRow>))}
            </TableBody>
          </Table>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={boundingBoxes.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              labelRowsPerPage={t("Rows per page")}
            />
        </TableContainer>
      </Box>
    </>

  )
};


