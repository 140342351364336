import { FC, useState } from "react";
import {
  Alert,
  AlertTitle,
  Box,
  Grid,
  TextField,
  Typography,
  styled,
  useTheme,
} from "@mui/material";
import moment from "moment";
import Button from "@mui/material/Button";
import { useTranslation } from "react-i18next";
import { Iha, SearchUser } from "../../../types/Types";

import{ api } from "../../../utils/api";

const TitleTypography = styled(Typography)({
  fontSize: "24px",
  marginTop: "2vh",
  marginLeft: "2vh",
  textAlign: "left",
});

type UserSearchProps = {
  setIhas: (value: Iha[]) => void;
  setUser: (value: SearchUser) => void;
  setUser_searched: (value: boolean) => void;
  setLoading: (value: boolean) => void;
};

export const UserSearch: FC<UserSearchProps> = ({
  setIhas,
  setUser,
  setUser_searched,
  setLoading,
}) => {
  const { t } = useTranslation("common");
  const theme = useTheme();

  const [fetchInfoError, setFetchInfoError] = useState("");
  const [samaccountname, setSamaccountname] = useState("14785236914");
  const [target_keys, setTarget_keys] = useState<number[]>([]);
  const [alertSuccess, setAlertSuccess] = useState(false);
  const [alertError, setAlertError] = useState(false);
  const [alertcontent, setalertcontent] = useState("");

  const fetchInfo = () => {
    if (samaccountname === "") return;
    console.log(samaccountname)
    setLoading(true);
    setTimeout(() => {
      api.base.post("/privileges/search_user", {
        samaccountname: samaccountname,
      })
        .then((response: any) => {
          const { ihas, ...user } = response.data;
          const target_keyss = [];
          for (const iha of ihas) {
            iha.key = iha.id;
            //If not authorized then get current date for start_date, +1 year for end_date
            if (iha.authorized) {
              iha.start_date = moment(iha.start_date).format("YYYY-MM-DD");
              iha.end_date = moment(iha.end_date).format("YYYY-MM-DD");
              target_keyss.push(iha.key);
            } else {
              iha.start_date = moment().format("YYYY-MM-DD");
              iha.end_date = moment().add(1, "years").format("YYYY-MM-DD");
            }
          }
          setIhas(ihas);
          setUser(user);
          setUser_searched(true);
          setTarget_keys(target_keyss);
          setalertcontent("");
          setAlertSuccess(true);

          const timer = setTimeout(() => {
            setAlertSuccess(false);
          }, 2000);
          return () => clearTimeout(timer);
        })
        .catch(() => {
          setUser_searched(false);
          setalertcontent(t("Unable to user search!"));
          setAlertError(true);
          const timer = setTimeout(() => {
            setAlertError(false);
          }, 2000);
          return () => clearTimeout(timer);
        })
        .finally(() => {
          setLoading(false);
        });
    }, 2000);
  };

  return (
    <>
      <Grid container>
        {alertSuccess && (
          <Alert severity="success" sx={{ mb: 2 }}>
            <AlertTitle sx={{ fontSize: "24px" }}>{t("Success!")}</AlertTitle>
            {alertcontent}
          </Alert>
        )}
        {alertError && (
          <Alert severity="error" sx={{ mb: 2 }}>
            <AlertTitle sx={{ fontSize: "24px" }}>{t("Error!")}</AlertTitle>
            {alertcontent}
          </Alert>
        )}
        <TitleTypography>{t("User Search")}</TitleTypography>
        <Grid
          item
          xs={12}
          sx={{
            display: "flex",
            justifyContent: "left",
            alignContent: "normal",
          }}
        >
          <Box
            sx={{
              m: "2vh",
              p: "2vh",
              width: "40%",
              display: "flex",
              flexDirection: "column",
              border: "1px dashed grey",
            }}
          >
            <TextField
              id="tc"
              label={t("ID Number")}
              variant="outlined"
              value={samaccountname}
              onChange={(e) => setSamaccountname(e.target.value)}
            />
            <Typography textAlign="center" variant="h6">
              {fetchInfoError}
            </Typography>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Button
                sx={{
                  marginTop: "15px",
                  color: theme.palette.primary.main,
                  "&:hover": {
                    color: theme.palette.primary.main,
                  },
                  fontSize: "small",
                }}
                onClick={() => {
                  fetchInfo();
                  setLoading(true);
                }}
              >
                {t("Search User")}
              </Button>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};
