import { FC, useEffect } from "react";
import Box from "@mui/material/Box";
import { AlertTitle, Alert, IconButton } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from "react-i18next";

export enum EAlertType {
    "success" = "success",
    "error" = "error"
};

export type TAlert = {
    type: EAlertType;
    content: string;
    duration? : number;
}

type TAlertComponentProps = {
    alert?: TAlert;
    onClose: () => void;
};

export const AlertMessage: FC<TAlertComponentProps> = ({
    alert,
    onClose
}) => {
  
  const {t} = useTranslation("common");
  
  useEffect(() => {
    if(alert && alert.duration) {
        const timer = setTimeout(() => {
            onClose();
        }, alert.duration);

        return () => clearTimeout(timer);
    }
  }, [alert, onClose]);

  return(
    <>
        {alert && <Box>
           <Alert
            severity={alert.type}
            sx={{ mb : 2}}
            action={
                <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={onClose}
                >
                    <CloseIcon fontSize="inherit"/>
                </IconButton>
            }
           >
            <AlertTitle sx={{ fontSize:"24px" }}>
                {t(alert.type === EAlertType.success ? t("Success!") : t("Error!"))}
            </AlertTitle>
            {alert.content}
           </Alert>
        </Box>}
    </>
  )
};
