import { PropsWithChildren, useState } from 'react';
import { Grid, Popover, Tooltip, useTheme } from '@mui/material';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import MuiDrawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Toolbar from '@mui/material/Toolbar';
import { styled, Theme, CSSObject } from '@mui/material/styles';
import { Logout, Menu as MenuIcon } from '@mui/icons-material';
import { useLocation, useNavigate } from 'react-router';
import { useAuth } from '../../contexts/Auth.context';
import { SidebarItems } from './SidebarItems';
import { DatabossImg } from '../../assets/databossimg';
import {useTranslation} from "react-i18next";

import DatabossLogo_White from "../../assets/DatabossLogo_White.png"
import backgroundImageURL from '../../assets/background.jpg';
import sidebarBgURL from '../../assets/sidebarbg5.jpg';
import headerBgURL from '../../assets/headerBg.png';
// import dblogo from '../../assets/DatabossLogo_Black.png';
// import kaanlogoURL from '../../assets/kaanlogo1.gif';
// import kaanlogoURL from '../../assets/kaanLogo1-text.gif';
// import kaanlogoURL from '../../assets/kaanLogo1-text.svg';
// import kaanlogoURL from '../../assets/logonewtext.svg';
// import kaanlogoURL from '../../assets/logosvg2.svg';
import kaanlogoURL from '../../assets/logosvg3.svg';

const drawerWidth = 240;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
  backgroundColor: theme.palette.secondary.main,
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  backgroundColor: theme.palette.secondary.main,
  width: `calc(${theme.spacing(8)} )`,
});

const DrawerHeader = styled('div')(({ theme }) => ({
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}));

export const Sidebar = ({ children }: PropsWithChildren) => {
  const location = useLocation();
  const navigate = useNavigate();
  const theme = useTheme();
  const auth = useAuth();
  const {t} = useTranslation("common");
  const [open, setOpen] = useState(false);
  const [isSupportDialog,setIsSupportDialog]=useState(false);
  const [anchorEl, setAnchorEl] = useState<(EventTarget & HTMLDivElement)| null>(null);

  const handleDrawerOpen = () => {
    setOpen(!open);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOpen = Boolean(anchorEl);
  const id = handleOpen ? 'simple-popover' : undefined;

  return (
    <Box sx={{ display: 'flex', height: '100%', width: '100%', background: `url(${backgroundImageURL})` }}>
      <CssBaseline />
      <AppBar position="fixed" open={open}>
        <Toolbar
          sx={{
            // backgroundColor: theme.palette.primary.main,
            display: 'flex',
            color: theme.palette.primary.contrastText,
            background: `url(${sidebarBgURL})`,
            backgroundSize: 'cover'
          }}
        >
          <IconButton
            color={'inherit'}
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
          >
            <Tooltip title={t("Menu")}>
              <MenuIcon />
            </Tooltip>
          </IconButton>
          {/* <div>KAAN</div> */}
          {/* <div style={{
            margin: '0 auto',
            // height: '47px',
            display: 'flex',
            width: '462px',
            minWidth: '462px',
            background: `url(${headerBgURL})`,
            backgroundSize: 'contain',
          }}> */}
            <img style={{
              height: '56px',
              margin: '0 auto 8px auto'
            }} src={kaanlogoURL} />
          {/* </div> */}
          {/* <Grid container>
            <Grid
              xs={6}
              item
              sx={{
                display: 'flex',
                justifyContent: 'end',
              }}
            >
              <h4>KAAN</h4>
              <div style={{
                background: `url(${headerBgURL})`,
                backgroundSize: 'cover'
              }}>

              </div>
            </Grid>
            <Grid
              xs={6}
              item
              sx={{
                display: 'flex',
                justifyContent: 'end',
                alignItems: 'center',
              }}
            >

            </Grid>
          </Grid> */}
          {/* <LanguageSwitcher /> */}
          <IconButton
            color="inherit"
            onClick={() => auth.setAuthState(auth.logout())}
            sx={{ marginLeft: "5px" }}
          >
            <Tooltip title={t("Logout")}>
              <Logout />
            </Tooltip>
          </IconButton>
        </Toolbar>
      </AppBar>
      <Drawer variant="permanent" open={open} sx={{ height: '100vh' }}>
        <DrawerHeader sx={{ backgroundColor: "#2757ca" }}>
          <DatabossImg />
        </DrawerHeader>
        <Divider />
        <Box
          sx={{
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            background: `url(${sidebarBgURL})`,
            backgroundSize: 'cover'
          }}
        >
          {SidebarItems.map((itemGroup, i) => (
            <List key={`SidebarItemGroup_${itemGroup.id}`} sx={{ p: 0, height: i !== SidebarItems.length - 1 ? "100%" : "auto" }}>
              {itemGroup.components
                .map((item) => (
                  <ListItem
                    key={item.name}
                    disablePadding
                  >{ item.children ?
                      <>
                        <ListItemButton
                          sx={{
                            minHeight: "72px",
                            px: 2.5,
                            color: theme.palette.secondary.contrastText,
                          }}
                          onClick={(event)=>setAnchorEl(event?.currentTarget)} >
                          <ListItemIcon
                            sx={{
                              minWidth: 0,
                              mr: open ? 3 : 'auto',
                              justifyContent: 'center',
                              color: theme.palette.secondary.contrastText,
                            }}>
                            <item.icon/>
                          </ListItemIcon>
                          <ListItemText
                            primary={t(item.name)}
                            sx={{ opacity: open ? 1 : 0 }}
                          />
                        </ListItemButton>
                        <Popover
                          sx={{ '& .MuiPaper-root': {
                            bgcolor:theme.palette.secondary.main
                          }, }}
                          id={id}
                          open={handleOpen}
                          onClose={handleClose}
                          anchorEl={anchorEl}
                          anchorOrigin={{
                            vertical: 'center',
                            horizontal: 'right',
                          }}
                          transformOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                          }}
                        >
                          {item.children.map(child => (
                              <ListItemButton
                                key={child.name}
                                sx={{
                                  pl:3,
                                  pr:5,
                                  py:2,
                                  color: theme.palette.secondary.contrastText,
                                  backgroundColor: location.pathname === child.url ? theme.palette.secondary.dark : undefined,
                                }}
                                onClick={() =>child.url?  navigate(child.url):setIsSupportDialog(true)}
                              >
                                <ListItemIcon
                                  sx={{
                                    color: theme.palette.secondary.contrastText,
                                  }}>
                                  <child.icon/>
                                </ListItemIcon>
                                <ListItemText
                                  primary={t(child.name)}
                                />
                              </ListItemButton>
                            ))}
                        </Popover>
                      </> :
                      <ListItemButton
                        sx={{
                          minHeight: "72px",
                          px: 2.5,
                          color: theme.palette.secondary.contrastText,
                          backgroundColor: location.pathname === item.url ? theme.palette.secondary.dark : undefined,
                        }}
                        onClick={() =>item.url?  navigate(item.url):setIsSupportDialog(true)}
                      >
                        <ListItemIcon
                          sx={{
                            minWidth: 0,
                            mr: open ? 3 : 'auto',
                            justifyContent: 'center',
                            color: theme.palette.secondary.contrastText,
                          }}
                        >
                          <Tooltip placement="right" title={t(item.tooltip)}><item.icon /></Tooltip>
                        </ListItemIcon>
                        <ListItemText
                          primary={t(item.name)}
                          sx={{ opacity: open ? 1 : 0 }}
                        />
                      </ListItemButton>
                    }
                  </ListItem>
                ))
              }
            </List>
          ))}
          <img
            style={{
              padding: "8px",
              height: "30px",
              objectFit: "contain",
              bottom: "0",
              opacity: ".8"
            }}
            src={DatabossLogo_White}
          />
        </Box>
      </Drawer>
      <Box
        component="main"
        sx={{
          display: 'flex', flexDirection: 'column', height: '100%', width: '100%', padding: '24px', paddingTop: '84px'
        }}
      >
        {/* <DrawerHeader /> */}
        {children}
      </Box>
    </Box>
  );
};
