import React, { FC, useCallback } from 'react'

import { Box, FormControl, OutlinedInput, MenuItem, Select, SelectChangeEvent } from '@mui/material'
import { AxiosResponse } from 'axios';
import { useQuery } from 'react-query';

import{ api } from "../../utils/api";

export type Selectable = {
  "id": string;
  "name":string;
};

type SelectWithFetchProps = {
  label: string;
  urlPrefix: string;
  multiple: boolean;
  disabled?: boolean;
  selectedValues: string[],
  setSelectedValues?: (values: string[]) => void,
  setCanWatch?: (values: number) => void,
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
export const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

type responseType = {  
    allowed: 
      {
        name: string,
        pipeline_id: number,
        status: string
      }[]
;
  can_watch: number  
}


export const SelectWithFetch: FC<SelectWithFetchProps> = ({
  label,
  urlPrefix,
  multiple,
  disabled,
  selectedValues,
  setSelectedValues, 
  setCanWatch
}) => {
  const handleChangeMap = useCallback(({ target: { value }, }: SelectChangeEvent<string[]>) => {
    if (setSelectedValues) {
      setSelectedValues(typeof value === "string" ? [value] : value);
      //setCanWatch(value.can_watch)
      
    }
  }, [setSelectedValues]);
  const { isLoading, error, data } = useQuery<responseType, Error>(
    `/${urlPrefix}/getAll`,
    () => api.base.get<never, AxiosResponse<responseType>>(`/${urlPrefix}`).then(res => {
      console.log("Data: ", res.data)
      return res.data 
    }),
    {
      refetchOnWindowFocus: false
    }
  )

  if (isLoading) return <Box>Loading...</Box>

  if (error) return <Box>An error has occurred: {error.message}</Box>
  if (!data) return <Box>An error has occurred .  </Box>

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        mb:2
      }}
    >
      <FormControl sx={{ width: '100%' }}>
        <Select
          multiple={true}
          displayEmpty
          value={selectedValues}
          onChange={handleChangeMap}
          input={<OutlinedInput />}
          MenuProps={MenuProps}
          inputProps={{ 'aria-label': 'Without label' }}
          disabled={disabled}
          sx={{fontSize:"small"}}
        >
          <MenuItem disabled value="{}" sx={{fontSize:"small"}}>
            <label>{label}</label>
          </MenuItem>

          {data.allowed.map((iha) => (            
          <MenuItem
              key={iha.pipeline_id}
              disabled={iha.status !== "True"}
              value={iha.pipeline_id.toString()}
              sx={{fontSize:"small"}}>
              {iha.pipeline_id}: {iha.name} / <span style={{ color: iha.status === "True" ? "#52c41a" : "#f5222d"}}>{iha.status === "True" ? "Aktif" : "Pasif"}</span>
            </MenuItem>
))}     
        </Select>
      </FormControl>
    </Box>
  )
}

