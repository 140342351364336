import { createContext, Dispatch, FC, PropsWithChildren, SetStateAction, useContext, useEffect, useState } from "react";

import { createTheme, PaletteMode, ThemeOptions, ThemeProvider } from "@mui/material";

import { Theme } from "@mui/material/styles";

type SettingsState = {
  mainColor: string,
  setMainColor: Dispatch<SetStateAction<string>>,
  isDarkModeOpened: boolean,
  setIsDarkModeOpened: Dispatch<SetStateAction<boolean>>,
}

const initialSettingsState = {
  mainColor: 'hsl(210, 79%, 46%)',
  setMainColor: (value: SetStateAction<string>) => console.log,
  isDarkModeOpened: false,
  setIsDarkModeOpened: (value: SetStateAction<boolean>) => console.log,
}

export const SettingsContext = createContext<SettingsState>(initialSettingsState);

const createThemeOptions = (isDarkModeOpened: boolean, mainColor: string) => {
  const hue = parseInt(mainColor.split(',')[0].slice(4));
  return {
    palette: {
      mode: isDarkModeOpened ? 'dark' : 'light' as PaletteMode,
      text: {
        primary: `hsl(${hue}, 50%, ${isDarkModeOpened ? 97 : 10}%)`,
        // secondary: '#0f0',
        // disabled: '#00f',
      },
      background: {
        default: `hsl(${hue}, ${isDarkModeOpened ? 20 : 50}%, ${isDarkModeOpened ? 20 : 97}%)`,
        paper: `hsl(${hue}, ${isDarkModeOpened ? 20 : 50}%, ${isDarkModeOpened ? 30 : 94}%)`,
      },
      primary: {
        main: `hsl(${hue}, 50%, ${isDarkModeOpened ? 80 : 20}%)`
      },
      secondary: {
        main: mainColor,
        // 50: `hsl(${hue}, ${isDarkModeOpened ? 20 : 50}%, ${isDarkModeOpened ? 20 : 97}%)`,
        // 100: `hsl(${hue}, ${isDarkModeOpened ? 20 : 50}%, ${isDarkModeOpened ? 30 : 94}%)`,
        // 200: `hsl(${hue}, 50%, 80%)`,
        // 300: `hsl(${hue}, 50%, 70%)`,
        // 400: `hsl(${hue}, 50%, 60%)`,
        // 500: `hsl(${hue}, 50%, 50%)`,
        // 600: `hsl(${hue}, 50%, 40%)`,
        // 700: `hsl(${hue}, 50%, 30%)`,
        // 800: `hsl(${hue}, 50%, 20%)`,
        // 900: `hsl(${hue}, 50%, 10%)`,
        // A100: `hsl(${hue}, 10%, 50%)`,
        // A200: `hsl(${hue}, 20%, 50%)`,
        // A400: `hsl(${hue}, 40%, 50%)`,
        // A700: `hsl(${hue}, 70%, 50%)`,
      }
    }
  } as ThemeOptions;
}

export const ProvideSettings: FC<PropsWithChildren> = ({ children }) => {
  const [isDarkModeOpened, setIsDarkModeOpened] = useState(
    localStorage.getItem('isDarkModeOpened') ? localStorage.getItem('isDarkModeOpened')==='true' : initialSettingsState.isDarkModeOpened
  );
  const [mainColor, setMainColor] = useState(localStorage.getItem('mainColor') || initialSettingsState.mainColor);

  const [theme, setTheme] = useState<Theme>(createTheme(createThemeOptions(isDarkModeOpened, mainColor)));

  useEffect(() => {
    setTheme(createTheme(createThemeOptions(isDarkModeOpened, mainColor)))
  }, [isDarkModeOpened, mainColor])

  return (
    <SettingsContext.Provider value={{ isDarkModeOpened, setIsDarkModeOpened, mainColor, setMainColor }}>
      <ThemeProvider theme={theme}>
        {children}
      </ThemeProvider>
    </SettingsContext.Provider>
  )
}

export const useSettings = () => useContext(SettingsContext);
