import { FC, useState, useEffect } from 'react';
import Box from "@mui/material/Box";
import { Autocomplete, Button, CircularProgress, MenuItem, Select, TextField, useTheme } from "@mui/material";
import { DetectionLogTable } from './DetectionLogTable';
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { DateTimePicker } from '@mui/x-date-pickers';
import { styled, Typography } from '@mui/material';
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { useTranslation } from 'react-i18next';
import { boundingBox, classObj, yki } from "../../types/Types";
import dayjs, { Dayjs } from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import {toast} from "react-toastify";

import { api } from "../../utils/api";

const LabelTypography = styled(Typography)({
  fontSize: "16px",
  textAlign: "left",
});

export const DetectionLog: FC = () => {

  const getUnixTimestamp = (date: Dayjs | null) => {
    return date ? date.tz(dayjs.tz.guess()).valueOf() : null;
  };
  dayjs.extend(utc);
  dayjs.extend(timezone);

  const { t } = useTranslation("common");
  const theme = useTheme();

  const [ykis, setYkis] = useState<yki[]>([]);
  const [classes, setClasses] = useState<classObj[]>([]);
  const [yki, setYki] = useState<string | null>(null);
  const [classObject, setClassObject] = useState<number | null>(null);
  const [thresholdValue, setThresholdValue] = useState<number | null>(null);
  const [startDate, setStartDate] = useState<Dayjs | null>(dayjs.tz('2024-05-13T13:05', dayjs.tz.guess()));
  const [endDate, setEndDate] = useState<Dayjs | null>(dayjs.tz('2024-05-15T13:05', dayjs.tz.guess()));
  const [formattedDateS, setFormattedDateS] = useState(getUnixTimestamp(dayjs.tz('2024-05-13T13:05', dayjs.tz.guess())));
  const [formattedDateE, setFormattedDateE] = useState(getUnixTimestamp(dayjs.tz('2024-05-15T13:05', dayjs.tz.guess())));
  const [boundingBoxes, setBoundingBoxes] = useState<boundingBox[]>([]);
  const [submitted, setSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getYKIs();
    getClasses();
  }, []);

  const submit = () => {
    if (!yki)
    {
      toast.error(t("Please select a stream!"))
      return
    }
    setLoading(true);
    api.base.get("/bbox/get_bbox", {
      params: {
        yki: yki,
        class_id: classObject,
        conf: thresholdValue,
        start_date: formattedDateS,
        end_date: formattedDateE
      }
    }).then((response: any) => {
      const responseBboxes = response.data;
      setBoundingBoxes(responseBboxes);
      setSubmitted(true);
    })
    .finally(() => {
      setLoading(false);
    });
  };
  
  const getYKIs = () => {
    api.base.get("/drone/get_yki")
      .then((response) => {
        setYkis(response.data);
      })
  }

  const getClasses = () => {
    api.base.get("/ai_class/get_classes")
      .then((response: any) => {
        const classes = response.data;
        setClasses(classes);
      })
  }

  const options = ykis.map(yki => yki.url);

  return (
    <div style={{overflowY: "auto"}}>
        <Box display="flex" flexDirection="column" gap={2}>
          <Box marginTop={2}>
            <LabelTypography>{t("Stream")}</LabelTypography>
            <Autocomplete
              disableClearable
              options={options}
              getOptionLabel={(option) => {
                const matchOption = ykis.find(yki => yki.url === option);
                return `${matchOption?.name}${matchOption?.url}`;
              }}
              onChange={(event: any, newValue) => {
                setYki(newValue);
              }}
              renderInput={(params) => <TextField
                id={params.id}
                inputProps={params.inputProps}
                InputProps={{
                  ...params.InputProps,
                }}
                fullWidth
              />}
              renderOption={(props, option) => {
                const matchOption = ykis.find(yki => yki.url === option);
                return (
                  (<li {...props}
                    key={option}

                  >
                    {`${matchOption?.name}${matchOption?.url}`}
                  </li>)
                )
              }} />
          </Box>
          <Box>
            <LabelTypography>{t("Model Class")}</LabelTypography>
            <Select
              id="type"
              fullWidth
              required
              onChange={(e) => { setClassObject(Number(e.target.value)) }}
              value={(classObject === undefined || classObject === null) ? '' : classObject}
            >
              {classes.map((cl) => (
                <MenuItem
                  key={cl.id}
                  value={cl.id}
                >
                  {cl.name}
                </MenuItem>
              ))}
            </Select>
          </Box>
          <Box>
            <LabelTypography>{t("Threshold Value")}</LabelTypography>
            <TextField
              type="number"
              fullWidth
              InputProps={{
                inputProps: {
                  max: 1, min: 0, step: 0.01, inputMode: 'numeric', pattern: '[0-9]*' 
                }
              }}
              value={thresholdValue}
              onChange={(e) => { var value = parseFloat(e.target.value);
                if (value > 1) value = 1;
                else if (value < 0) value = 0;
                setThresholdValue(value); 
              }}
            />
          </Box>
          <Box>
            <LabelTypography>{t("Start Date")}</LabelTypography>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer components={["DatePicker"]}>
                <DateTimePicker
                  sx={{ fontSize: "small" }}
                  slotProps={{ textField: { fullWidth: true } }}
                  value={startDate}
                  onChange={(newValue) => { setStartDate(newValue); setFormattedDateS(getUnixTimestamp(newValue)); }}
                />
              </DemoContainer>
            </LocalizationProvider>
          </Box>
          <Box>
            <LabelTypography>{t("End Date")}</LabelTypography>
            <LocalizationProvider dateAdapter={AdapterDayjs} >
              <DemoContainer components={["DatePicker"]}>
                <DateTimePicker
                  sx={{ fontSize: "small" }}
                  slotProps={{ textField: { fullWidth: true } }}
                  value={endDate}
                  onChange={(newValue) => { setEndDate(newValue); setFormattedDateE(getUnixTimestamp(newValue)) }}
                />
              </DemoContainer>
            </LocalizationProvider>
          </Box>
          <Button
            sx={{
              display: "inline-block",
              width: "120px",
              textTransform: "none",
              backgroundColor: theme.palette.primary.main,
              '&:hover': {
                backgroundColor: theme.palette.primary.main,
              }
            }}
            variant="contained"
            onClick={() => submit()}
          >
            {t("Apply")}
          </Button>
        </Box>
        <Box>
          {loading ?  (<Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "10px"
          }}
        >
          <CircularProgress color="primary" />
        </Box> ) : (<div style={{marginTop: '20px'}}><DetectionLogTable boundingBoxes={boundingBoxes} submitted={submitted}/></div>)}
        </Box>
    </div>
  )
};
