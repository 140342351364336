import styled from '@emotion/styled'
import { useTranslation } from 'react-i18next'

const ScEmptySlotOverlay = styled.div`
  background: white;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;


  @media all and (display-mode: fullscreen) {
    color: white;
    background: black;
  }
`

const EmptySlotOverlay = () => {
  const { t } = useTranslation('common')

  return (
    <ScEmptySlotOverlay>
      {t('No Stream')}
    </ScEmptySlotOverlay>
  )
}

export default EmptySlotOverlay
