import { FC } from "react";
import { Box, styled, Typography } from "@mui/material";
import { SxProps } from "@mui/system";
import { Route, Routes } from "react-router-dom";
import { unAuthorizedRoutes } from "./unAuthorized/unAuthorized.routes";
import { AUTH_STATUS, useAuth } from "../contexts/Auth.context";
import { authorizedRoutes } from "./authorized/authorized.routes";
import { Login } from "../pages/Login/Login";
import { Sidebar } from "../components/Sidebar/Sidebar";
import { useTranslation } from "react-i18next";
import CircularProgress from "@mui/material/CircularProgress";

import backgroundImageURL from '../assets/background.jpg';

const WarningTypography = styled(Typography)({
  position: "absolute",
  bottom: 0,
  fontSize: "13px",
  fontWeight: "bold",
})

export const RouteByAuth: FC = () => {
  const { status } = useAuth();

  const { t } = useTranslation("common");

  if (status === AUTH_STATUS.INITIAL) {
    return <CircularProgress />;
  } else if (status === AUTH_STATUS.AUTHORIZED) {
    return (
      <Routes>
        {authorizedRoutes.map((ar) => (
          <Route
            key={`authorizedRoutes_${ar.path}`}
            path={ar.path}
            element={
              <Sidebar>
                {ar.element}
                <WarningTypography>
                  {t("* The system in the development stage.")}
                </WarningTypography>
              </Sidebar>
            }
          />
        ))}
      </Routes>
    );
  }
  if (status === AUTH_STATUS.UNAUTHORIZED) {
    return (
      <Routes>
        {unAuthorizedRoutes.map((r) => (
          <Route
            key={`authorizedRoutes_${r.path}`}
            path={r.path}
            element={
              <Box
                sx={
                  {
                    height: "100%",
                    background: `url(${backgroundImageURL})`
                  } as SxProps
                }
              >
                {r.element}
              </Box>
            }
          />
        ))}
      </Routes>
    );
  }
  return <Login />;
};
