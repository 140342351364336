const categories = [
	{
		"id": 1,
		"color": "#960A95",
		"label": "person"
	},
	{
		"id": 2,
		"color": "#BC4800",
		"label": "bicycle"
	},
	{
		"id": 3,
		"color": "#11AA23",
		"label": "car"
	},
	{
		"id": 4,
		"color": "#52FF00",
		"label": "motorcycle"
	},
	{
		"id": 5,
		"color": "#0027B5",
		"label": "airplane"
	},
	{
		"id": 6,
		"color": "#F700FF",
		"label": "bus"
	},
	{
		"id": 7,
		"color": "#00CD1E",
		"label": "train"
	},
	{
		"id": 8,
		"color": "#CFD300",
		"label": "truck"
	},
	{
		"id": 9,
		"color": "#00C4A5",
		"label": "boat"
	},
	{
		"id": 10,
		"color": "#83AD00",
		"label": "traffic light"
	},
	{
		"id": 11,
		"color": "#8900E5",
		"label": "fire hydrant"
	},
	{
		"id": 12,
		"color": "#4000D6",
		"label": "stop sign"
	},
	{
		"id": 13,
		"color": "#A30020",
		"label": "parking meter"
	},
	{
		"id": 14,
		"color": "#0074B9",
		"label": "bench"
	},
	{
		"id": 15,
		"color": "#E08200",
		"label": "bird"
	},
	{
		"id": 16,
		"color": "#00AE62",
		"label": "cat"
	},
	{
		"id": 17,
		"color": "#D400C9",
		"label": "dog"
	},
	{
		"id": 18,
		"color": "#A80064",
		"label": "horse"
	},
	{
		"id": 19,
		"color": "#F60083",
		"label": "sheep"
	},
	{
		"id": 20,
		"color": "#A0C609",
		"label": "cow"
	},
	{
		"id": 21,
		"color": "#4DBA00",
		"label": "elephant"
	},
	{
		"id": 22,
		"color": "#BA003E",
		"label": "bear"
	},
	{
		"id": 23,
		"color": "#0B9900",
		"label": "zebra"
	},
	{
		"id": 24,
		"color": "#5200B0",
		"label": "giraffe"
	},
	{
		"id": 25,
		"color": "#1C00A5",
		"label": "backpack"
	},
	{
		"id": 26,
		"color": "#C20085",
		"label": "umbrella"
	},
	{
		"id": 27,
		"color": "#C9B109",
		"label": "handbag"
	},
	{
		"id": 28,
		"color": "#0AAFD5",
		"label": "tie"
	},
	{
		"id": 29,
		"color": "#C29200",
		"label": "suitcase"
	},
	{
		"id": 30,
		"color": "#00F14F",
		"label": "frisbee"
	},
	{
		"id": 31,
		"color": "#DB0004",
		"label": "skis"
	},
	{
		"id": 32,
		"color": "#0A00E6",
		"label": "snowboard"
	},
	{
		"id": 33,
		"color": "#A400B2",
		"label": "sports ball"
	},
	{
		"id": 34,
		"color": "#AE7600",
		"label": "kite"
	},
	{
		"id": 35,
		"color": "#CB2900",
		"label": "baseball bat"
	},
	{
		"id": 36,
		"color": "#009D3A",
		"label": "baseball glove"
	},
	{
		"id": 37,
		"color": "#00B687",
		"label": "skateboard"
	},
	{
		"id": 38,
		"color": "#E4F30E",
		"label": "surfboard"
	},
	{
		"id": 39,
		"color": "#0AFFA3",
		"label": "tennis racket"
	},
	{
		"id": 40,
		"color": "#003AD6",
		"label": "bottle"
	},
	{
		"id": 41,
		"color": "#5D8900",
		"label": "wine glass"
	},
	{
		"id": 42,
		"color": "#AE0300",
		"label": "cup"
	},
	{
		"id": 43,
		"color": "#AC08DD",
		"label": "fork"
	},
	{
		"id": 44,
		"color": "#0043AC",
		"label": "knife"
	},
	{
		"id": 45,
		"color": "#F80EC7",
		"label": "spoon"
	},
	{
		"id": 46,
		"color": "#0EC0CC",
		"label": "bowl"
	},
	{
		"id": 47,
		"color": "#BCFF0C",
		"label": "banana"
	},
	{
		"id": 48,
		"color": "#0CDAE8",
		"label": "apple"
	},
	{
		"id": 49,
		"color": "#CDFF0A",
		"label": "sandwich"
	},
	{
		"id": 50,
		"color": "#E40DFA",
		"label": "orange"
	},
	{
		"id": 51,
		"color": "#12D5FF",
		"label": "broccoli"
	},
	{
		"id": 52,
		"color": "#0AFFDC",
		"label": "carrot"
	},
	{
		"id": 53,
		"color": "#AF0AFF",
		"label": "hot dog"
	},
	{
		"id": 54,
		"color": "#A20BE2",
		"label": "pizza"
	},
	{
		"id": 55,
		"color": "#F4C910",
		"label": "donut"
	},
	{
		"id": 56,
		"color": "#B4A60A",
		"label": "cake"
	},
	{
		"id": 57,
		"color": "#FF0FCD",
		"label": "chair"
	},
	{
		"id": 58,
		"color": "#0CD0D5",
		"label": "couch"
	},
	{
		"id": 59,
		"color": "#FF0AAE",
		"label": "potted plant"
	},
	{
		"id": 60,
		"color": "#0AC8AC",
		"label": "bed"
	},
	{
		"id": 61,
		"color": "#FF9B0A",
		"label": "dining table"
	},
	{
		"id": 62,
		"color": "#D70CB4",
		"label": "toilet"
	},
	{
		"id": 63,
		"color": "#0DCDD9",
		"label": "tv"
	},
	{
		"id": 64,
		"color": "#BFE00B",
		"label": "laptop"
	},
	{
		"id": 65,
		"color": "#0EE5E8",
		"label": "mouse"
	},
	{
		"id": 66,
		"color": "#C50DE0",
		"label": "remote"
	},
	{
		"id": 67,
		"color": "#0B8AC7",
		"label": "keyboard"
	},
	{
		"id": 68,
		"color": "#08A999",
		"label": "cell phone"
	},
	{
		"id": 69,
		"color": "#B108A7",
		"label": "microwave"
	},
	{
		"id": 70,
		"color": "#DEB80A",
		"label": "oven"
	},
	{
		"id": 71,
		"color": "#0C8FDE",
		"label": "toaster"
	},
	{
		"id": 72,
		"color": "#0AACCB",
		"label": "sink"
	},
	{
		"id": 73,
		"color": "#9B0FB6",
		"label": "refrigerator"
	},
	{
		"id": 74,
		"color": "#94A208",
		"label": "book"
	},
	{
		"id": 75,
		"color": "#0C91B3",
		"label": "clock"
	},
	{
		"id": 76,
		"color": "#AA8708",
		"label": "vase"
	},
	{
		"id": 77,
		"color": "#C2E80F",
		"label": "scissors"
	},
	{
		"id": 78,
		"color": "#B70EE3",
		"label": "teddy bear"
	},
	{
		"id": 79,
		"color": "#B70BC8",
		"label": "hair drier"
	},
	{
		"id": 80,
		"color": "#AACF0D",
		"label": "toothbrush"
	}
];

export default categories;