import { Card, CardActionArea, CardContent, CardMedia, Typography } from '@mui/material';
import { useDrag, useDragDropManager } from 'react-dnd';
import placeHolderImageURL from '../../../assets/video-placeholder.png'
import styled from '@emotion/styled';
import Box from "@mui/material/Box";

const ScDisabledOverlay = styled.div`
  width: 100%;
  height: 100%;
  background: #bfbfbf;
  top: 0;
  left: 0;
  position: absolute;
  opacity: 0.3;
  margin-top: 0 !important; // handles gap propery
`

export type TStreamSourceProps = {
  id: number;
  is_active: number;
  on_live: number;
  name: string;
  thumbnail: string;
  url: string;
  onDoubleClick: (props: { id: number, url: string, name: string }) => void
}

const StreamSource = ({ id, is_active, on_live, name, thumbnail, url, onDoubleClick }: TStreamSourceProps) => {
  const dragDropManager = useDragDropManager()

  const [{ isDragging }, drag] = useDrag(() => ({
    type: 'listItem',
    item: { type: 'listItem', id, url, name },
    // item: { id, is_active, name, thumbnail, url },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
      // handlerId: monitor.getHandlerId(),
    }),
  }))

  const thumbnailUrl = is_active ? `data:image/png;base64, ${thumbnail}` : placeHolderImageURL
  return (
    <Card
      {...is_active === 1 && { ref: drag }}
      key={id}
      elevation={3}
      sx={{ position: 'relative', height: '200px' }}
      onDoubleClick={() => {is_active && onDoubleClick({ id, url, name})}}
    >
      <CardActionArea disableRipple>
        <CardMedia
          component="img"
          src={thumbnailUrl}
          alt="tmp"
        />
        <CardContent>
          <Box
            sx={{
              display: 'flex',
            }}
          >
            <Typography
              display="inline"
              variant="body2"
              color="text.secondary"
            >
              {id + ":"}
            </Typography>
            <Typography
              variant="body2"
              color="text.secondary"
              overflow="hidden"
              whiteSpace="nowrap"
              textOverflow="ellipsis"
              textAlign="left"
              sx={{
                direction: "rtl",
              }}
            >
              {name}
            </Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <span style={{color: is_active === 1 ? "#52c41a" : "#f5222d"}}>
              {is_active === 1 ? "Aktif" : "Pasif"}
            </span>
              <span style={{color: on_live === 1 ? "#52c41a" : "#f5222d"}}>
              {["İzlenmiyor", "İzleniyor"][on_live] || "Bilinmiyor"}
            </span>
          </Box>
        </CardContent>
      </CardActionArea>
      {is_active !== 1 && <ScDisabledOverlay/>}
    </Card>
  );
}

export default StreamSource