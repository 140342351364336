import { AxiosResponse } from 'axios'
import { useMutation, useQuery } from 'react-query'
import { RefObject, useEffect, useRef, useState } from 'react'
import Janus from '../../plugins/Janus'
import Player from 'video.js/dist/types/player'

import { api } from "../../utils/api";
import { janusUrl } from "../../utils/config";

export type TStreamData =  { ID: number, IS_ACTIVE: number, ON_LIVE: number, NAME: string, THUMBNAIL: string, URL: string }
export type TStreamListResponse = TStreamData[]

export const useStreamListQuery = () => {
  const queryData = useQuery<TStreamListResponse, Error>(
    `streams`,
    () => api.base.get<never, AxiosResponse<TStreamListResponse>>('drone/get_yki_thumbnails').then(res => {
      return res.data
    }),
    {
      refetchInterval: 3000,
      refetchOnWindowFocus: false
    }
  )
  return queryData
}

export type TVideoPipelineResponse = {
  data: { pipeline_id: string }
}

export const useVideoPipelineQuery = () => {
  const queryData = useMutation({
    mutationFn: (liveStreamURL: string) => api.base.post<never, TVideoPipelineResponse>('drone/find_available_pipeline', { live_stream_url: liveStreamURL})
  })
  return queryData
}

type TWatchStream = (pipeline_id: string) => void
type THandle = {
  send: (params: { message: { request: 'watch', id: number }}) => void
}
type TuseJanusParams = {
  player: Player | null
}
export const useJanus = ({ player }: TuseJanusParams) => {
  const janusRef = useRef<Janus | null>(null)
  const [watchStreamMethod, setWatchStreamMethod] = useState<TWatchStream | null>(null)

  const handleRemoteStream = () => {
    // (stream) => {
    //   debugger
    //   player.src = stream
    // }
    console.log('remotestream')
  }

  const handleMessage = () => {
    console.log('message')
  }

  useEffect(() => {
    janusRef.current = new Janus({
      server: (janusUrl),
      success: () => {
        janusRef.current?.attach!({
          plugin: 'janus.plugin.streaming',
          success: (handle: THandle) => {
            console.log(`Success!`,handle)
            setWatchStreamMethod(
              (pipeline_id: string) => {
                // debugger
                if (pipeline_id) {
                  handle.send({ message: { request: 'watch', id: Number.parseInt(pipeline_id)}})
                }
              }
            )
          },
          // @ts-ignore
          onremotestream: handleRemoteStream,
          // @ts-ignore
          onmessage: handleMessage
        })
      }
    })

    return () => {
      if (janusRef.current && janusRef.current.destroy) {
        janusRef.current.destroy()
      }
    }
  }, [player])

  return [watchStreamMethod]
}

export const useOutsideClick = <T extends HTMLElement>(
  callback: () => void,
  ref: RefObject<T>,
) => {
  const listener = (e: MouseEvent) => {
    if (!ref.current) return
    if (e.target instanceof Node && !ref.current.contains(e.target)) {
      callback()
    }
  }

  useEffect(() => {
    if (!ref) return
    document.addEventListener('click', listener)
    return () => document.removeEventListener('click', listener)
  }, [ref.current, callback])
};
