import React, { createContext, useState, ReactNode, useContext } from "react";

type TStream = {
  id: number;
  order: number;
  isSelected: boolean;
  url: string | null;
  name: string | null;
}

type TStreamContainers = {
  [K: number]: TStream;
};

type TStreamInfo = {
  containers: TStreamContainers;
  layoutMode: 1 | 2 | 3;
};

type TContainer = {
  id: number;
  order: number;
  isSelected: boolean;
}

interface StorageInfoContextProps {
  localStorageData: TStreamInfo;
  saveStreamToLocalStorage: ( stream : TStream ) => void;
  removeStreamFromLocalStorage: (id: number) => void;
  updateStreamLayoutMode: (newLayoutMode: 1 | 2 | 3) => void;
  updateStreamContainer: ( container: TContainer ) => void;
}

const initialVideos = {
  containers: {1: {id: 1, order: 1, isSelected: false, url: null, name: null}, 2: {id: 2, order: 2, isSelected: false, url: null, name: null}, 3: {id: 3, order: 3, isSelected: false, url: null, name: null}, 4: {id: 4, order: 4, isSelected: false, url: null, name: null}},
  layoutMode: 1,
};

const StorageInfoContext = createContext<StorageInfoContextProps | undefined>(
  undefined
);

export const StorageInfoProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [localStorageData, setLocalStorageData] = useState<TStreamInfo>(() => {
    const item = localStorage.getItem("stream_info");
    return item ? JSON.parse(item) : initialVideos;
  });

  const saveStreamToLocalStorage = ( stream : TStream ) => {
    const localStorageItem = localStorage.getItem("stream_info");
    let streamInfo: TStreamInfo = localStorageItem
      ? JSON.parse(localStorageItem)
      : initialVideos;

    streamInfo.containers[stream.id] = stream;
    localStorage.setItem("stream_info", JSON.stringify(streamInfo));
    setLocalStorageData(streamInfo);
  };

  const removeStreamFromLocalStorage = (id: number) => {
    const localStorageItem = localStorage.getItem("stream_info");
    let streamInfo: TStreamInfo = localStorageItem
      ? JSON.parse(localStorageItem)
      : initialVideos;

    streamInfo.containers[id].url = null
    streamInfo.containers[id].name = null
    streamInfo.containers[id].isSelected = false

    localStorage.setItem("stream_info", JSON.stringify(streamInfo));
    setLocalStorageData(streamInfo);
  };

  const updateStreamLayoutMode = (newLayoutMode: 1 | 2 | 3) => {
    const localStorageItem = localStorage.getItem("stream_info");
    let streamInfo: TStreamInfo = localStorageItem
      ? JSON.parse(localStorageItem)
      : initialVideos;
    streamInfo.layoutMode = newLayoutMode;
    localStorage.setItem("stream_info", JSON.stringify(streamInfo));
    setLocalStorageData(streamInfo);
  };

  const updateStreamContainer = ( container: TContainer ) =>
  {
    const localStorageItem = localStorage.getItem("stream_info");
    let streamInfo: TStreamInfo = localStorageItem
      ? JSON.parse(localStorageItem)
      : initialVideos;

    streamInfo.containers[container.id].order = container.order;
    streamInfo.containers[container.id].isSelected = container.isSelected;
    localStorage.setItem("stream_info", JSON.stringify(streamInfo));
    setLocalStorageData(streamInfo);
  }

  return (
    <StorageInfoContext.Provider
      value={{
        localStorageData,
        saveStreamToLocalStorage,
        removeStreamFromLocalStorage,
        updateStreamLayoutMode,
        updateStreamContainer
      }}
    >
      {children}
    </StorageInfoContext.Provider>
  );
};

export const useLocalStorage = () => {
  const context = useContext(StorageInfoContext);
  if (!context) {
    throw new Error(
      "useLocalStorage must be used within a StorageInfoProvider"
    );
  }
  return context;
};

export default StorageInfoContext;
