import React, { useState, useEffect, FC } from "react";
import {
  Dialog, DialogTitle, DialogContent, TextField, DialogActions, Button,
  IconButton, Grid, Typography, Paper, Autocomplete
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import { useTranslation } from "react-i18next";
import { AlertMessage, EAlertType, TAlert } from '../../../components/Alert/Alerttwo';

import{ api } from "../../../utils/api";

interface Class {
  id: number;
  name: string;
}
interface ClassThreshold {
  class_id: number;
  model_id: number;
  type: string;
  threshold: number;
  isNew?: boolean;
}
interface Model {
  id: number;
  model_name: string;
  model_url: string;
  classes: ClassThreshold[];
}
interface ModelDialogProps {
  open: boolean;
  model: Model | null;
  classes: Class[];
  onClose: () => void;
  onSubmit: () => void;
}

const ModelDialog: FC<ModelDialogProps> = ({ open, model, classes, onClose, onSubmit }) => {
  const { t } = useTranslation("common");

  const [model_name, setName] = useState('');
  const [model_url, setUrl] = useState('');
  const [classThresholds, setClassThresholds] = useState<ClassThreshold[]>([]);
  const [alert, setAlert] = useState<TAlert>();
  const [classOptions, setClassOptions] = useState<Class[]>([]);

  useEffect(() => {
    if (model) {
      setName(model.model_name);
      setUrl(model.model_url);
      setClassThresholds(model.classes);
    } else {
      setAlert(undefined);
    }
    fetchClasses();
  }, [model]);

  const fetchClasses = async () => {
    try {
      const response = await api.base.get("/ai_class/get_classes");
      setClassOptions(response.data);
    }
    catch (error) {
      console.error('Error fetching classes:', error);
    }
  };

  const handleClassChange = (index: number, field: keyof ClassThreshold, value: any) => {
    const updatedClasses = [...classThresholds];
    updatedClasses[index] = { ...updatedClasses[index], [field]: value };
    setClassThresholds(updatedClasses);
  };

  const addClassField = () => {
    setClassThresholds([...classThresholds, { model_id: model ? model.id : -1, class_id: classOptions[0].id, type: classOptions[0].name, threshold: 0.5, isNew: true }]);
  };

  const removeClassField = async (index: number) => {
    const classRemove = classThresholds[index];
    const data = {
      model_id: classThresholds[index].model_id,
      class_id: classThresholds[index].class_id,
    }
    if(!classRemove.isNew) {
      try {
        await api.base.delete("/ai_class_threshold/delete_model_threshold", {
          data,
        });
      } catch (error) {
        console.error('Error deleting threshold:', error);
      }
    }
    setClassThresholds(classThresholds.filter((_, i) => i !== index));
  };

  const hasDuplicateClassTypes = () => {
    const classIds = classThresholds.map(cls => cls.class_id);
    return new Set(classIds).size !== classIds.length;
  };

  const handleSubmit = async () => {
    if (!model_name.trim() || !model_url.trim() || classThresholds.some(cls => cls.threshold < 0 || cls.threshold > 1)) {
      setAlert({type : EAlertType.error, content : t('Please ensure all fields are correctly filled and thresholds are between 0 and 1.'), duration : 2000});
      return;
    }
    if (hasDuplicateClassTypes()) {
      setAlert({type : EAlertType.error, content : t('Duplicate class types are not allowed. Please select different class types.')});
      return;
    }
    try {
      if (model && model.id !== 0) {
        await api.base.put("/ai_model/update_model", {
          model_id: model.id,
          model_name: model_name,
          model_url: model_url  
        });
        for (const cls of classThresholds) {
          if(cls.isNew){
            await api.base.post("/ai_class_threshold/insert_model_threshold", {
              model_id: model.id,
              class_id: cls.class_id,
              threshold: cls.threshold
            });
          }else {
            await api.base.put("/ai_class_threshold/update_model_threshold", {
              model_id: model.id,
              class_id: cls.class_id,
              threshold: cls.threshold
            });
          }
        }
      } else {
        await api.base.post("/ai_model/insert_model", {
          model_name: model_name,
          model_url: model_url,
        });
        const { data: modelsData } = await api.base.get("/ai_model/get_models/");
        const newModelId = modelsData.find((d: { model_name: string; }) => d.model_name === model_name).id
        for (const cls of classThresholds) {
          await api.base.post("/ai_class_threshold/insert_model_threshold", {
            model_id: newModelId,
            class_id: cls.class_id,
            threshold: cls.threshold
          });
        }
      }
      onSubmit();
      onClose();
    } catch (error) {
      setAlert({type : EAlertType.error, content : t("The same class types cannot be selected more than once."), duration : 2000});
    }
  };

  return (
    <>
      <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
        <DialogTitle>{model?.id ? t('Update Model'): t('Add Model')}</DialogTitle>
        <DialogContent>
          <AlertMessage alert={alert} onClose={() => setAlert(undefined)}/>
          <TextField fullWidth label={t("Model Name")} value={model_name} onChange={e => setName(e.target.value)} margin="dense" />
          <TextField fullWidth label={t("Model URL")} value={model_url} onChange={e => setUrl(e.target.value)} margin="dense" />
            <Typography variant="subtitle1" gutterBottom>
              {t("Classes and Thresholds")}
            </Typography>
            {classThresholds.map((cls, index) => (
              <Paper key={index} variant="outlined" sx={{ p: 2, mb: 2 }}>
                <Grid container spacing={2} alignItems="center">
                  <Grid item xs={6}>
                    <Autocomplete
                    options={classOptions}
                    getOptionLabel={(option) => option.name}
                    value={classOptions.find(c => c.id === cls.class_id) || null}
                    onChange={(event, newValue) => { 
                    handleClassChange(index, 'class_id', newValue ? newValue.id : null);
                  }}
                  renderInput={(params) => {
                    let modifiedInputLabelProps = {
                      ...params.InputLabelProps,
                      children: React.isValidElement(params.InputLabelProps.children) ? params.InputLabelProps.children : undefined
                    };
                    return <TextField {...params} InputLabelProps={modifiedInputLabelProps} label="Tür" variant="outlined" />;
                  }}
                  />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                    fullWidth
                    label={t("Threshold")}
                    type="number"
                    value={cls.threshold}
                    onChange={e => handleClassChange(index, 'threshold', parseFloat(e.target.value))}
                    inputProps={{ step: 0.1, min: 0, max: 1 }}
                    />
                  </Grid>
                    <Grid item xs={2}>
                      <IconButton onClick={() => removeClassField(index)} color="error">
                        <DeleteIcon />
                        </IconButton>
                    </Grid>
                  </Grid>
              </Paper>
            ))}
            <Button startIcon={<AddIcon />} onClick={addClassField}>{t("Add Class")}</Button>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>{t("Cancel")}</Button>
          <Button onClick={handleSubmit} color="primary">{t("Save")}</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
export default ModelDialog; 

