export const clearInput = (input: string) => {
  return input.trim();
};

export const getSafe = (fn: any, default_value = null) => {
  try {
    return fn();
  } catch {
    return default_value;
  }
};

export const isArray = (input: any[]) => {
  return Array.isArray(input);
};

export const isBoolean = (input: any) => {
  return typeof input === "boolean";
};

export const isEmail = (input: string) => {
  // eslint-disable-next-line
  const pattern = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,})+$/;
  if (pattern.test(input) && isHigherThan(input, 6)) return true;
  return false;
};

export const isEmpty = (input: any) => {
  if (typeof input === "undefined") return true;
  if (input === null) return true;
  if (input === "") return true;
  if (String(input.length) === "0") return true;
  if (typeof input === "object" && Object.keys(input).length === 0) return true;
  return false;
};

export const isFullName = (input: string) => {
  const pattern =
    /^[a-z\u00C0-\u02AB'´`]+\.?\s([a-z\u00C0-\u02AB'´`]+\.?\s?)+$/i;
  return pattern.test(input);
};

export const isHigherThan = (input: any, limit: number) => {
  if (isString(input)) {
    if (input.length > limit) return true;
    return false;
  } else {
    if (input > limit) return true;
    return false;
  }
};

export const isInteger = (input: any) => {
  return Number.isInteger(input);
};

export const isLowerThan = (input: any, limit: number) => {
  if (isString(input)) {
    if (input.length < limit) return true;
    return false;
  } else {
    if (input < limit) return true;
    return false;
  }
};

export const isNumber = (input: any) => {
  return typeof input === "number" && isFinite(input) && !isNaN(input);
};

export const isString = (input: any) => {
  return typeof input === "string";
};
