import { FC } from "react";
import Box from "@mui/material/Box";
import { AlertTitle, Alert } from "@mui/material";
import { useTranslation } from "react-i18next";

type AlertComponentProps = {
    alertSuccess : boolean,
    alertError : boolean,
    alertcontent: string
};

export const AlertMessage: FC<AlertComponentProps> = ({
    alertSuccess,
    alertError,
    alertcontent
}) => {
  
  const {t} = useTranslation("common");

  return(
    <>
        <Box>
        {alertSuccess && 
            <Alert severity="success" sx={{mb: 2}}>
                <AlertTitle sx={{fontSize: "24px"}}>{t("Success!")}</AlertTitle>
                {alertcontent}
            </Alert>
        }

        {alertError && 
            <Alert severity="error" sx={{mb: 2}}>
                <AlertTitle sx={{fontSize: "24px"}}>{t("Error!")}</AlertTitle>
                {alertcontent}
            </Alert>
        }


        </Box>
    </>

  )
};
  

