import { SvgIconTypeMap } from '@mui/material';

import { OverridableComponent } from '@mui/material/OverridableComponent';

import {
  StreamOutlined,
  InsertPhotoOutlined,
  OndemandVideoOutlined,
  Settings,
  Help,
  PrivacyTip
} from '@mui/icons-material';

type tresto = {
  name: string,
  icon: OverridableComponent<SvgIconTypeMap<unknown, "svg">> & { muiName: string; },
  url?: string,
  tooltip: string,
  children?: tresto[],
}

type sidebarGroups = {
  id: number,
  components: tresto[]
}

export const SidebarItems: sidebarGroups[] = [
  {
    id: 1,
    components: [
      {
        name: 'Live Console',
        icon: StreamOutlined,
        url: '/stream',
        tooltip: 'Live Console',
      },
      {
        name: 'Image Detection',
        icon: InsertPhotoOutlined,
        url: '/image-detection',
        tooltip: 'Image Detection',
      },
      {
        name: 'Video Detection',
        icon: OndemandVideoOutlined,
        url: '/video-detection',
        tooltip: 'Video Detection',
      },
    ],
  },
  {
    id: 2,
    components: [
      {
        name: 'Settings',
        icon: Settings,
        url: '/settings',
        tooltip: 'Settings',
      },
      {
        name: 'Help',
        icon: Help,
        url: '/help',
        tooltip: 'Help',
      },
      {
        name: 'Privacy',
        icon: PrivacyTip,
        url: '/privacy',
        tooltip: 'Privacy',
      },
    ],
  },
];
