import { FC, useState } from "react";
import { UserSignUp } from "./UserSignUp/UserSignUp";
import { UserSettings } from "./UserSettings/UserSettings";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { useTranslation } from "react-i18next";

export const Users: FC = () => {
  const [value, setValue] = useState("one");
  const [userSignUpOpen, setUserSignUpOpen] = useState(true);
  const [userSettingsOpen, setUserSettingsOpen] = useState(false);
  const { t } = useTranslation("common");

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  return (
    <>
      <Box sx={{ width: "100%" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          textColor="primary"
          indicatorColor="primary"
          aria-label="primary tabs example"
        >
          <Tab
            value="one"
            sx={{ fontSize: "small" }}
            label={t("User Sign Up")}
            onClick={() => {
              setUserSettingsOpen(false);
              setUserSignUpOpen(true);
            }}
          />
          <Tab
            value="two"
            sx={{ fontSize: "small" }}
            label={t("User Settings")}
            onClick={() => {
              setUserSettingsOpen(true);
              setUserSignUpOpen(false);
            }}
          />
        </Tabs>
      </Box>
      {userSignUpOpen && <UserSignUp modalOpen={false} />}
      {userSettingsOpen && <UserSettings />}
    </>
  );
};
