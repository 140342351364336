import { FC, FormEvent, useState } from 'react';

import {
  Button,
  Card,
  CardContent,
  Grid,
  Hidden,
  IconButton,
  InputAdornment,
  Stack,
  styled,
  TextField,
  Typography,
  useTheme
} from '@mui/material';

import Box from '@mui/material/Box';

import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

import LoadingButton from '@mui/lab/LoadingButton';
import { useSnackbar } from 'notistack';

import DatabossLogo_Black from "../../assets/DatabossLogo_Black.png"
import kkk from "../../assets/kkk.png"
import logosvg4 from "../../assets/logosvg4.svg"

import { AUTH_STATUS, useAuth } from '../../contexts/Auth.context';

const GridFullscreen = styled(Grid)(({ theme }) => ({
  background: theme.palette.secondary.main,
  width: '100%',
  height: '100%',
}));

const GridLogoBackground = styled(Grid)({
  marginTop: '43vh',
  height: 'auto',
  // alignItems: 'center',
  justifyContent: 'center',
});

const GridLogo = styled(Box)(({ theme }) => ({
  width: '16vh',
  height: '16vh',
  color: '#fff',
  alignItems: 'center',
  justifyContent: 'center',
}));

const LogoTypography = styled(Typography)({
  fontSize: '15vh',
  marginTop: '-1vh',
  textAlign: 'center',
});

const GridCenter = styled(Grid)(({ theme }) => ({
  background: theme.palette.background.paper,
  borderTopLeftRadius: '100vh',
  borderBottomLeftRadius: '100vh',
  alignItems: 'center',
  justifyContent: 'center',
}));

const GridRight = styled(Grid)(({ theme }) => ({
  background: theme.palette.background.paper,
  alignItems: 'center',
  justifyContent: 'center',
}));

const HeaderTypography = styled(Typography)({
  fontSize: '24px',
});

export const Login: FC = () => {
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const auth = useAuth();

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);

  const login = () => {
    auth
      .login(username, password)
      .then((response) => {
        console.log("1111111111111111", response)
        if (response.status === AUTH_STATUS.AUTHORIZED) {
          setTimeout(() => {
            enqueueSnackbar('Giriş Başarılı ', {
              variant: 'success',
              anchorOrigin: { horizontal: 'center', vertical: 'top' },
              autoHideDuration: 2000,
            });
          }, 2150);
        } else if (response.status === AUTH_STATUS.UNAUTHORIZED) {
          setTimeout(() => {
            enqueueSnackbar('Giriş Başarısız ', {
              variant: 'error',
              anchorOrigin: { horizontal: 'center', vertical: 'top' },
              autoHideDuration: 2000,
            });
            setLoading(false);
          }, 2500);
        }

        setTimeout(() => auth.setAuthState(response), 2000);
      })
      .catch(() => {
        setTimeout(() => {
          enqueueSnackbar('Giriş Başarısız ', {
            variant: 'error',
            anchorOrigin: { horizontal: 'center', vertical: 'top' },
            autoHideDuration: 2000,
          });
          setLoading(false);
        }, 2500);

        setTimeout(
          () => auth.setAuthState({ status: AUTH_STATUS.UNAUTHORIZED, user: null }),
          500,
        );
      });
  };
  const loginLDAP = () => {
    auth
      .loginLDAP()
      .then((response) => {
        console.log("1111111111111111", response)
        if (response.status === AUTH_STATUS.AUTHORIZED) {
          setTimeout(() => {
            enqueueSnackbar('Giriş Başarılı ', {
              variant: 'success',
              anchorOrigin: { horizontal: 'center', vertical: 'top' },
              autoHideDuration: 2000,
            });
          }, 2150);
        } else if (response.status === AUTH_STATUS.UNAUTHORIZED) {
          setTimeout(() => {
            enqueueSnackbar('Giriş Başarısız ', {
              variant: 'error',
              anchorOrigin: { horizontal: 'center', vertical: 'top' },
              autoHideDuration: 2000,
            });
            setLoading(false);
          }, 2500);
        }

        setTimeout(() => auth.setAuthState(response), 2000);
      })
      .catch(() => {
        setTimeout(() => {
          enqueueSnackbar('Giriş Başarısız ', {
            variant: 'error',
            anchorOrigin: { horizontal: 'center', vertical: 'top' },
            autoHideDuration: 2000,
          });
          setLoading(false);
        }, 2500);

        setTimeout(
          () => auth.setAuthState({ status: AUTH_STATUS.UNAUTHORIZED, user: null }),
          500,
        );
      });
  };
  const handleClick = (event:FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    setLoading(true);
    setTimeout(() => {
      enqueueSnackbar('Bilgiler Kontrol Ediliyor', {
        variant: 'info',
        anchorOrigin: { horizontal: 'center', vertical: 'top' },
        autoHideDuration: 2000,
      });
    });
    login();
  };
  const handleLDAPClick = () => {
    setLoading(true);
    setTimeout(() => {
      enqueueSnackbar('Bilgiler Kontrol Ediliyor', {
        variant: 'info',
        anchorOrigin: { horizontal: 'center', vertical: 'top' },
        autoHideDuration: 2000,
      });
    });
    loginLDAP();
  };
  const [values,setValues]=useState({
    pass:"",
    showPass:false
  })
  const handlePassVisibility=()=>{
    setValues({
      ...values,
      showPass:!values.showPass
    })
  }
  return (
    <GridFullscreen container>
      <Hidden smDown>
        <Grid item sm={4}>
          <GridLogoBackground container>
            <GridLogo>
              <img src={kkk} style={{ width: "100%", height: "100%", objectFit: "contain" }}/>
            </GridLogo>
          </GridLogoBackground>
        </Grid>
        <GridCenter item sm={1} />
      </Hidden>
      <GridRight item xs={12} sm={7}>
        <Grid
          container
          sx={{
            height: '100%',
            alignItems: 'center',
          }}
        >
          <Hidden smUp>
            <Grid item xs={2} />
          </Hidden>
          <Hidden mdDown>
            <Grid item md={2} lg={2} />
          </Hidden>
          <Grid item xs={8} sm={11} md={7} lg={6} sx={{
            height: "100vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "relative",
            '& .MuiPaper-root': {
              background: theme.palette.background.paper,
            }
          }}>
            <Stack
              onSubmit={handleClick}
              component="form"
              sx={{
                width: "100%",
                '& > :not(style)': { m: '8px 0' },
              }}
              noValidate
              autoComplete="off"
            >
              {/* <HeaderTypography>OLAY TAHMİN SİSTEMİ</HeaderTypography> */}
              <img style={{ width: "100%" }} src={logosvg4} />
              <TextField
                required
                fullWidth
                variant="outlined"
                label="Kullanıcı Adı"
                value={username}
                onChange={({ target: { value } }) => setUsername(value)}
              />
                
              <TextField
                type={values.showPass?"text":"password"}
                required
                fullWidth
                variant="outlined"
                label="Şifre"
                value={password}
                onChange={({ target: { value } }) => setPassword(value)}
                InputProps={{
                  endAdornment:(
                    <InputAdornment position='end'>
                      <IconButton onClick={handlePassVisibility}>
                        {values.showPass?(
                          <VisibilityOffIcon/>
                        ):(
                          <VisibilityIcon/>
                        )}
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />
              <LoadingButton
                fullWidth
                variant="contained"
                loading={loading}
                type="submit"
              >
                GİRİŞ YAP
              </LoadingButton>
              <Button fullWidth variant="outlined" onClick={handleLDAPClick}>
                KARA AĞI HESABIYLA GİRİŞ YAP
              </Button>
              {/*<iframe src="http://192.168.2.115:4222" width={200} height={200}/>*/}
            </Stack>
            <img
              style={{
                padding: "8px",
                position: "absolute",
                height: "46px",
                objectFit: "contain",
                bottom: "0",
                opacity: ".7"
              }}
              src={DatabossLogo_Black}
            />
          </Grid>
        </Grid>
      </GridRight>
    </GridFullscreen>
  );
};

Login.defaultProps = {
  initialAlertProps: undefined,
};
