import { FC } from 'react';
import { Typography, styled, useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import {useTranslation} from "react-i18next";

const TitleTypography = styled(Typography)({
  fontSize: '18px',
  margin: '2vh',
  textAlign: 'left',
});

const DescriptionTypography = styled(Typography)({
  fontSize: '12px',
  margin: '2vh',
  textAlign: 'left',
});

type PrivacyProps={
}

export const Privacy: FC<PrivacyProps> = ({

}) => {
  const {t} = useTranslation("common");
  const theme = useTheme();

  return(
    <Box sx={{ width: '100%'}}>
      <TitleTypography>{t("Privacy")}</TitleTypography>
      <DescriptionTypography>{t("Privacy Policy")}</DescriptionTypography>
    </Box>

  )
};


