import { FC } from 'react';
import Box from "@mui/material/Box";
import { useNavigate } from "react-router";
import { CardAction } from '../../components/CardAction/CardAction';
import {useTranslation} from "react-i18next";

export const Settings: FC = () => {
    const navigate = useNavigate();
    const {t} = useTranslation("common");

  return(
    <>
      <Box>
          <h4>{t("Stream/ UAV Settings")}</h4>
          <CardAction
          title={t("Stream/ UAV Settings")}
          description={t("Manage broadcast and UAV settings.")}
          onClick={() => navigate("/broadcast-settings")}
        />

          <h4>{t("User Management")}</h4>
          <CardAction
          title={t("User Sign Up / User Settings")}
          description={t("Creating new users and managing existing users.")}
          onClick={() => navigate("/users")}
        />
          <h4>{t("Authorization")}</h4>
          <CardAction
          title={t("Privileges Settings")}
          description={t("Authorization of UAVs on a user basis.")}
          onClick={() => navigate("/privileges")}
        />
          <h4>{t("Model Management")}</h4>
          <CardAction
          title={t("Save/ Update Model")}
          description={t("Add and update model")}
          onClick={() => navigate("/modelmanagement")}
        />
         <h4>{t("Detection Log")}</h4>
          <CardAction
          title={t("Detection Log")}
          description={t("View detections.")}
          onClick={() => navigate("/detection-log")}
        /> 
          {/* <h4>{t("Settings")}</h4>
          <CardAction
          title={t("Object Detection Sensitivity")}
          description={t("Adjusting Object Detection Sensitivity settings.")}
          onClick={() => navigate("/precision-setting")}
        /> */}
      </Box>
    </>
)
};
