import { Dispatch, FC, PropsWithChildren, SetStateAction } from "react";
import { Box, Card, CardActionArea, CardContent, Modal, Switch, Typography } from "@mui/material";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';


type CardActionProps = PropsWithChildren<{
  title : string;
  description : string ;
  onClick?: () => void;
  switchProps?: {
    checked: boolean;
    setChecked: Dispatch<SetStateAction<boolean>>;
  };
  modalProps?: {
    open: boolean;
    onClose: Dispatch<SetStateAction<boolean>>;
  };
}>;

export const CardAction: FC<CardActionProps> = ({
  children,
  title,
  description,
  onClick,
  switchProps,
  modalProps,
}) => {
  return <Box sx={{
    display:"flex",
    flex: 1,
    alignItems:"center",
    width:"95%",
    mt:1,
    borderRadius:"20px",
    background: '#f9f9f9'
  }}>
    <CardActionArea
      sx={{
        height:"100%",
        display:"flex",
        alignItems:"center",
        justifyContent:"space-between",
        border: "3px",
        borderRadius:"10px",
        borderStyle: "solid",
        borderColor:"#E5E4E2",
        flex:1 ,
        padding: 2
      }}
      onClick={onClick} >
      <CardContent sx={{ p:0,ml:3 }}>
        <h4>{title}</h4>
        <Typography sx={{fontSize:"small"}}>{description}</Typography>
      </CardContent>
      { switchProps && <Switch checked={switchProps.checked}/> }
      <ArrowForwardIosIcon sx={{width:"12px", height:"12px"}}/>

    </CardActionArea>

    { modalProps && <Modal
      open={modalProps.open}
      onClose={modalProps.onClose}
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
    >
      <Card sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 300,
        stroke: 'none',
        padding: 3,
      }}>
        {children}
      </Card>
    </Modal> }
  </Box>
};

CardAction.defaultProps = {};
