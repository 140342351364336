import styled from '@emotion/styled'
import { CircularProgress } from '@mui/material'
import { useTranslation } from 'react-i18next'

const ScLoadingOverlay = styled.div`
  background: white;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 101;
`

const ScInner = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 12px;
`

type TLoadingOverlayProps = {
  resourceName?: string
}
const LoadingOverlay = ({ resourceName }: TLoadingOverlayProps) => {
  const { t } = useTranslation('common')

  return (
    <ScLoadingOverlay>
      <ScInner>
        <CircularProgress color="primary" />
        { resourceName && <span>{`${t('Loading Stream:')} ${resourceName}`}</span> }
      </ScInner>
    </ScLoadingOverlay>
  )
}

export default LoadingOverlay
