import styled from '@emotion/styled'
import VideoItem, { TVideoItemHandle } from './VideoItem'
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react'
import Janus from '../../../plugins/Janus'
import { Button, css } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useLocalStorage } from '../StorageInfoContext'

const ScVideoWrapper = styled.div<{ $layoutMode: 1 | 2 | 3 }>`
  display: grid;

  ${props => {
    if (props.$layoutMode === 1) {
      return css`
        grid-template-columns: 1fr ;
        grid-template-rows: 1fr ;

        & > div {
          justify-content: center;
        }
      `
    }

    if (props.$layoutMode === 2) {
      return css`
        grid-template-columns: 1fr 1fr ;
        grid-template-rows: auto ;

        & > div {
          justify-content: center;
        }
      `
    }

    if (props.$layoutMode === 3) {
      return css`
        grid-template-columns: 1fr 1fr ;
        grid-template-rows: 1fr 1fr ;
        & > div {
          justify-content: center;
        }
      `
    }
  }}
  grid-gap: 4px;
  width: 100%;
  height: 100%;
`

const ScVideoGrid = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  flex: 1;
  padding-right: 20px;
  height: 100%;
  max-width: 1300px;
`

const ScBottomBar = styled.div`
  height: 50px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2px 4px;
  border: 1px solid #1976d2;
  background: white;
`

export type TContainers = {
  [K: number]: {
    id: number;
    order: number;
    isSelected: boolean
  }
}

export type TVideoGridHandle = {
  startStreamFromAvailableSlot: (args: { url: string, name: string }) => void
}

const initialContainersState = {
  1: { id: 1, order: 1, isSelected: false },
  2: { id: 2, order: 2, isSelected: false  },
  3: { id: 3, order: 3, isSelected: false  },
  4: { id: 4, order: 4, isSelected: false  },
} 


const VideoGrid = forwardRef<TVideoGridHandle>((_, ref) => {
  const isJanusInitRef = useRef(false)
  const fullscreenContainerRef = useRef<HTMLDivElement>(null)
  const [titlesVisible, setTitlesVisible] = useState(true)
  const {updateStreamLayoutMode, localStorageData, removeStreamFromLocalStorage, updateStreamContainer} = useLocalStorage()
  const [layoutMode, setLayoutMode] = useState<1 | 2 | 3>(localStorageData.layoutMode || 1)
  const [initialized, setInitialized] = useState(false)
  const cont: TContainers = initialContainersState

  for (const key in localStorageData.containers) {
    if (localStorageData.containers.hasOwnProperty(key)) {
      const stream = localStorageData.containers[key]
      if(stream)
      {
        cont[key] = {
        id: stream.id,
        order: stream.order,
        isSelected: stream.isSelected
      }
      }

    } 
  }

  const [containers, setContainers] = useState<TContainers>(cont)
  const { t } = useTranslation('common')

  const videoRefs = useRef<{ [K: number]: TVideoItemHandle | null }>({
    1: null,
    2: null,
    3: null,
    4: null
  })

  const startStreamFromAvailableSlot = (args: {url: string, name: string }): void => {
    const emptySlot = Object.values(videoRefs.current).find((ref, index) => {
      return ref && ref.isSelected()
    })

    if (emptySlot) {
      return emptySlot.startFromRef(args)
    }

  }

 const startStreamInitially = (args: {url: string, name: string }, index: number): void => {
    const emptySlot = Object.values(videoRefs.current).find((ref, i) => {
      return (index - 1) === i
    })

    if (emptySlot) {
      return emptySlot.startFromRef(args)
    }
  }

  useEffect(() => {
    if (ref !== null && localStorageData && localStorageData.containers && !initialized) {
      Object.keys(localStorageData.containers).forEach(key => {
        let videoKey = Number(key)
        let video = localStorageData.containers[videoKey]
      
        if (video.url && video.name) {
          startStreamInitially({url: video.url, name: video.name}, video.id)
        }
      })
      setInitialized(true)
    }
  }, [])

  useImperativeHandle(ref, () => {
    return ({
      startStreamFromAvailableSlot,
    })
  }, [layoutMode])


  const handleToggleTitleVisibility = () => {
    setTitlesVisible(!titlesVisible)
  }

  const handleLayoutChange = (layout : 1 | 2 | 3) => {
    setLayoutMode(layout)
    updateStreamLayoutMode(layout)
    if(layout === 1 || layout === 2)
    {
      for (let i = 1; i <= 4; i++) {
        if (containers[i].order > layout) {
          removeStreamFromLocalStorage(containers[i].id)
        }
      }
    }
  }

  useEffect(() => {
    if (!isJanusInitRef.current) {
      Janus.init({ debug: false })
    }
    isJanusInitRef.current = true
  }, [])

  const handleFullscreen = () => {
    fullscreenContainerRef.current?.requestFullscreen()
  }

  const handleOrderChange = (fromId: number, toId: number) => {
    setContainers({
      ...containers,
      [fromId]: {
        ...containers[fromId],
        order: containers[toId].order
      },
      [toId]: {
        ...containers[toId],
        order: containers[fromId].order
      }
    })
    updateStreamContainer({ id: fromId, order: containers[toId].order, isSelected: containers[toId].isSelected})
    updateStreamContainer({ id: toId, order: containers[fromId].order, isSelected: containers[fromId].isSelected})
  }

  const handleVideoItemClick = (id: number) => {
    setContainers({
      ...containers,
      [id]: {
        ...containers[id],
        isSelected: !containers[id].isSelected
      }
    })
    updateStreamContainer({ id: id, order: containers[id].order, isSelected: !containers[id].isSelected})
  }

  return (
    <ScVideoGrid>
      <ScVideoWrapper ref={fullscreenContainerRef} $layoutMode={layoutMode} >
        <VideoItem id={containers[1].id} order={containers[1].order} onOrderChange={handleOrderChange} titlesVisible={titlesVisible} onToggleTitleVisibility={handleToggleTitleVisibility} ref={item => videoRefs.current[1] = item} selected={containers[1].isSelected} onClick={() => handleVideoItemClick(1)}/>
        {layoutMode !== 1 && (
          <VideoItem id={containers[2].id} order={containers[2].order} onOrderChange={handleOrderChange} titlesVisible={titlesVisible} onToggleTitleVisibility={handleToggleTitleVisibility} ref={item => videoRefs.current[2] = item} selected={containers[2].isSelected} onClick={() => handleVideoItemClick(2)}/>
        )}
        {layoutMode === 3 && (
          <>
            <VideoItem id={containers[3].id} order={containers[3].order} onOrderChange={handleOrderChange} titlesVisible={titlesVisible} onToggleTitleVisibility={handleToggleTitleVisibility} ref={item => videoRefs.current[3] = item} selected={containers[3].isSelected} onClick={() => handleVideoItemClick(3)}/>
            <VideoItem id={containers[4].id} order={containers[4].order} onOrderChange={handleOrderChange} titlesVisible={titlesVisible} onToggleTitleVisibility={handleToggleTitleVisibility} ref={item => videoRefs.current[4] = item} selected={containers[4].isSelected} onClick={() => handleVideoItemClick(4)}/>
          </>
        )}
      </ScVideoWrapper>
      <ScBottomBar>
        <div style={{ display: 'flex', gap: '4px'}}>
        <Button variant="contained" size="medium" onClick={() => {handleLayoutChange(1)}}>{'1x1'}</Button>
        <Button variant="contained" size="medium" onClick={() => {handleLayoutChange(2)}}>{'1x2'}</Button>
        <Button variant="contained" size="medium" onClick={() => {handleLayoutChange(3)}}>{'2x2'}</Button>
        </div>
        <div style={{ display: 'flex', gap: '4px'}}>
          <Button variant="contained" size="medium" onClick={handleToggleTitleVisibility}>{titlesVisible ? t('Hide Titles') : t('Show Titles')}</Button>
          <Button variant="contained" size="medium" onClick={handleFullscreen}>{t('Fullscreen')}</Button>
        </div>
      </ScBottomBar>
    </ScVideoGrid>
  )
})

export default VideoGrid
