import {useState, useContext, createContext, PropsWithChildren, useEffect} from 'react'

import { AxiosResponse } from 'axios';
import { useLocation, useParams } from "react-router";

import { api } from "../utils/api";

export enum AUTH_STATUS {
  INITIAL,
  UNAUTHORIZED,
  AUTHORIZED,
}

type User = ({
  samaccountname: string,
  id:string,
  token: string,
  type: string,
  default_password: string
}) | null;

export type Settings = {
    name:string,
    value: string,
  }[] | null;

type AuthState = {
  status: AUTH_STATUS,
  user: (User),
};

const AuthContext = createContext({
  status: AUTH_STATUS.INITIAL,
  user: null as User,
  settings: null as Settings,
  setAuthState: (authState: AuthState) => {
    if (authState) {
      // return;
    }
  },
//   reloadUsersAllInfo: () => { return },
//   whoAmI: async () => Promise.resolve({ status: AUTH_STATUS.UNAUTHORIZED, user: null as User }),
  login: (username: string, password: string) => {
    if (username && password) {
      return Promise.resolve({ status: AUTH_STATUS.UNAUTHORIZED, user: null as User });
    }
    return Promise.resolve({ status: AUTH_STATUS.UNAUTHORIZED, user: null as User });
  },
  loginLDAP: () => {
    return Promise.resolve({ status: AUTH_STATUS.UNAUTHORIZED, user: null as User });
  },
  logout: () => ({ status: AUTH_STATUS.UNAUTHORIZED, user: null as User }),
});



// Provider hook that creates auth object and handles state
function useProvideAuth() {
  const params = useParams();
  const location = useLocation();

  const [auth, setAuth] = useState({
    status: AUTH_STATUS.INITIAL,
    user: null,
  } as AuthState);

  const [settings, setSettings] = useState(null as Settings);

  const setAuthState = (authState: AuthState) => {
    setAuth((prev) => {
      if (prev.status === AUTH_STATUS.UNAUTHORIZED && authState.status === AUTH_STATUS.AUTHORIZED) {
        let path = location.pathname;
        for(const e of Object.entries(params)){
          path = path.split(e[1] as string).join(`:${e[0]}`)
        }
      }
      return authState
    });
  };

  useEffect(() => {
    if (auth.status === AUTH_STATUS.INITIAL) {
      whoAmI().then((data) => setAuthState(data)).catch(() => { /**/ });
    }
  }, [auth]);

//   useEffect(() => {
//     reloadUsersAllInfo();
//   }, [auth.status])

//   const reloadUsersAllInfo = () => {
//     if (auth.status === AUTH_STATUS.AUTHORIZED) {
//       api.base.get<never, AxiosResponse<{
//       }>>('/user/whatAreMyDeputations').then(response => {
//         console.log(response.data)
//       }).catch(() => ({
//         status: AUTH_STATUS.UNAUTHORIZED,
//         user: null,
//       }))
//       api.base.get<never, AxiosResponse<User>>('/user/whatAreMyPermissions').then(response => {
//         console.log(response.data)
//         if (response.data) {
//           setAuthState({ status: AUTH_STATUS.AUTHORIZED, user: response.data })
//         } else {
//           console.log("WTF")
//         }
//       }).catch(() => ({
//         status: AUTH_STATUS.UNAUTHORIZED,
//         user: null,
//       }))
//       api.base.get<never, AxiosResponse<{ userSettings: Settings }>>('/user/whatAreMySettings').then(response => {
//         console.log(response.data)
//         if (response.data) {
//           // console.log(response);
//           setSettings(response.data.userSettings)
//         } else {
//           setSettings(null)
//         }
//       }).catch(() => ({
//         status: AUTH_STATUS.UNAUTHORIZED,
//         user: null,
//       }))
//     }
//   }

  const whoAmI = async () => {
    return api.base.get<never, AxiosResponse<User>>('/account/check_token').then(response => {
      if (response.status === 200) {
        // console.log(response);
        const user = {
          samaccountname:localStorage.getItem('samaccountname') || "samaccountname", 
          id: "-1",
          token: localStorage.getItem('token') || "token",
          username: localStorage.getItem('samaccountname') || "samaccountname",
          type: localStorage.getItem('type') || "type",
          default_password: "-1"
        }

        return {
          status: AUTH_STATUS.AUTHORIZED,
          user,
        };
      } else {
        return {
          status: AUTH_STATUS.UNAUTHORIZED,
          user: null,
        };
      }
    }).catch(() => {
      return ({
        status: AUTH_STATUS.UNAUTHORIZED,
        user: null,
      })
    })
  };

  const login = async (username: string, password: string) => {
    const response = await api.base.post<{ username: string, password: string }, AxiosResponse<User>>('/account/sign_in', {
        samaccountname: username,
        password: password
    });
    if (response.data) {
      localStorage.setItem('token', response.data.token);
      localStorage.setItem('samaccountname', response.data.samaccountname);
      localStorage.setItem('type', response.data.type);

      return {
        status: AUTH_STATUS.AUTHORIZED,
        user: response.data,
      };
    }
    return {
      status: AUTH_STATUS.UNAUTHORIZED,
      user: null,
    };
  };

  const loginLDAP = async () => {
    return new Promise<AuthState>((res, rej) => {
      fetch(`${api.baseUrl}/account/ldap`, {
        method: 'GET',
        credentials: "include"
      }).then(r => {
        console.log(r);
        return r.body ? r.json().then((d: User) => {
          if(!d){
            rej({
              status: AUTH_STATUS.UNAUTHORIZED,
              user: null,
            })
          } else {
            localStorage.setItem('token', d.token);
            res({
              status: AUTH_STATUS.AUTHORIZED,
              user: d,
            })
          }
        }).catch(() => rej({
          status: AUTH_STATUS.UNAUTHORIZED,
          user: null,
        })) : rej({
          status: AUTH_STATUS.UNAUTHORIZED,
          user: null,
        })
      }).catch(() => rej({
        status: AUTH_STATUS.UNAUTHORIZED,
        user: null,
      }))
    });
  };

  const logout = () => {
    let path = location.pathname;
    for(const e of Object.entries(params)){
      path = path.split(e[1] as string).join(`:${e[0]}`)
    }
    localStorage.removeItem('token');
    localStorage.removeItem('SelectIha');
    localStorage.removeItem('config');
    localStorage.removeItem('color');
    localStorage.removeItem('style');
    localStorage.removeItem('width');
    return {
      status: AUTH_STATUS.UNAUTHORIZED,
      user: null,
    };
  };


  // Return the user object and auth methods
  return {
    status: auth.status,
    user: auth.user,
    settings,
    setAuthState,
    //whoAmI,
    login,
    loginLDAP,
    logout,
    //createLog,
    //reloadUsersAllInfo,
  };
}

// Provider component that wraps your app and makes auth object ...
// ... available to any child component that calls useAuth().
export const ProvideAuth = ({ children }: PropsWithChildren) => {
  const auth = useProvideAuth();
  return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>;
};

// Hook for child components to get the auth object ...
// ... and re-render when it changes.
export const useAuth = () => useContext(AuthContext);
