import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { forwardRef, MouseEventHandler, useEffect, useImperativeHandle, useRef, useState } from 'react'
import { useDrag, useDrop } from 'react-dnd'
import { useVideoPipelineQuery } from '../hooks'
import "video.js/dist/video-js.css";
import Janus from '../../../plugins/Janus'
import LoadingOverlay from './LoadingOverlay'
import EmptySlotOverlay from './EmptySlotOverlay'
import ControlsOverlay from './ControlsOverlay'
import RightClickContextMenu, { TRightClickContextMenuHandle } from './RightClickContextMenu'
import { Box, Button, Card, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, Modal } from '@mui/material'
import { useTranslation } from 'react-i18next'
import CloseIcon from '@mui/icons-material/Close';

import{ api } from "../../../utils/api";
import { useLocalStorage } from '../StorageInfoContext'

import { janusUrl } from "../../../utils/config";
import SRModal, { TSRModalHandle } from './SRModal'

const ScTitleBar = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  height: 20px;

  @media (display-mode: fullscreen) {
    color: white
  }
`

const ScVideoItem = styled.div<{ $isOver: boolean, $order: number }>`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;

  order: ${props => props.$order};

  ${props => props.$isOver && css`
    background: lavender;
  `}

  .detection-container__frame {
    width: 100%;
  }
`
const ScInnerVideoWrapper = styled.div<{ $selected: boolean }>`
  display: flex;
  position: relative;
  border: 4px solid #1976d2;
  aspect-ratio: 16 / 9;
  background: white;
  ${props => props.$selected && css`
  border-color: #f5dd27;
`}

`
export type TVideoItem = {
  titlesVisible: boolean
  onToggleTitleVisibility: () => void
  onOrderChange: (fromOrder: number, toOrder: number) => void
  id: number
  order: number
  onClick: () => void
  selected: boolean
}

type TJanusHandle = {
  send: (params: { message: { request: 'watch', id: number }}) => void
}

export type TVideoItemHandle = {
  startFromRef: (args: { url: string, name: string }) => void
  hasActiveVideo: () => boolean
  isSelected: () => boolean
}

const VideoItem = forwardRef<TVideoItemHandle, TVideoItem>(({
  titlesVisible,
  onToggleTitleVisibility,
  onOrderChange,
  id,
  order,
  onClick,
  selected
}, ref) => {
  const { mutateAsync: getVideoPipeline } = useVideoPipelineQuery()
  const videoRef = useRef<HTMLVideoElement>(null)
  const janusRef = useRef<Janus | null>(null)
  const pluginHandleRef = useRef<TJanusHandle | null>(null)
  const fullscreenTargetRef = useRef<HTMLDivElement | null>(null)
  const RightClickContextMenuHandleRef = useRef<TRightClickContextMenuHandle | null>(null)

  const [pipelineId, setPipelineId] = useState<number | null>(null)
  const srmodalref = useRef<TSRModalHandle>(null)
  const [isLoading, setIsLoading] = useState(false)
  const [isVideoInit, setIsVideoInit] = useState(false)
  const [isVideoPaused, setIsVideoPaused] = useState(false)
  const [videoTitle, setVideoTitle] = useState('')
  const isAliveIntervalRef = useRef<ReturnType<typeof setInterval>>()
  const { saveStreamToLocalStorage, removeStreamFromLocalStorage } = useLocalStorage()


  const {t} = useTranslation('common')

  const initVideojs = (pipeline_id: number) => {
      janusRef.current = new Janus({
            server: (janusUrl),
            success: () => {
              janusRef.current?.attach!({
                plugin: 'janus.plugin.streaming',
                success: (handle: TJanusHandle) => {
                  console.log(`Success!`,handle)
                  pluginHandleRef.current = handle
                  const message = { request: 'watch', id: pipeline_id}
                  // @ts-expect-error
                  pluginHandleRef.current.send({ message })
                },
                // @ts-expect-error
                onremotetrack: (mediaTrack) => {
                  const stream = new MediaStream()
                  stream.addTrack(mediaTrack)
                  // @ts-expect-error
                  Janus.attachMediaStream(videoRef.current, stream);
                  if (videoRef.current) {
                    videoRef.current.addEventListener('canplay', () => {
                      // TODO: set loading false
                      videoRef.current!.play()
                      setIsLoading(false)
                      setIsVideoInit(true)
                    })
                  }
                },
                // @ts-ignore
                onmessage: (message, jsep) => {
                  if (jsep) {
                    // @ts-expect-error
                    pluginHandleRef.current.createAnswer({
                      jsep,
                      media: { audioSend: false, videoSend: false, data: true },
                      // @ts-expect-error
                      success: (jsep) => {
                        const message = { request: "start" };
                        // @ts-expect-error
                        pluginHandleRef.current.send({ message, jsep });
                      }
                    });
                  }
                }
              })
            }
          })
      // videoRef.current.srcObject()
  }


  const startVideo = async (sourceURL: string, title: string) => {
    handleRemoveVideo()
    setVideoTitle(title)
    setIsLoading(true)
    const { data: { pipeline_id } } = await getVideoPipeline(sourceURL)
    api.base.post(`/drone/screen_is_alive/${pipeline_id}`)
    initVideojs(Number(pipeline_id))
    setPipelineId(Number(pipeline_id))
    isAliveIntervalRef.current = setInterval(() => {
      api.base.post(`/drone/screen_is_alive/${pipeline_id}`)
    }, 10000)
    let stream = { id: id, order: order, isSelected: selected, url: sourceURL, name: title}
    saveStreamToLocalStorage(stream)
  }

  const [{ isOver }, drop] = useDrop(
    () => ({
      accept: ['listItem', 'container'],
      drop: (draggedItem: { type: 'listItem', url: string, name: string }) => {
        // @ts-expect-error
        if (draggedItem.type === 'container' && draggedItem.id === id) return
        // @ts-expect-error
        if (draggedItem.type === 'container') {
          // @ts-expect-error
          onOrderChange(draggedItem.id, id)
          return
        }
        const dragged = draggedItem;

        // TODO: handle same item drop
        startVideo(dragged.url, dragged.name)
      },
      collect: (monitor) => ({
        isOver: monitor.isOver(),
        canDrop: monitor.canDrop(),
        dropResult: monitor.getDropResult(),
        itemType: monitor.getItemType()
      }),
    }),
    [id, order, onOrderChange]
  )

  const [, drag] = useDrag(() => {
    return ({
      type: 'container',
      item: { type: 'container', id }
    })
  }, [id, order, onOrderChange])

  drag(fullscreenTargetRef)

  const handlePlayPauseButtonClick = () => {
    if (isVideoPaused) {
      videoRef.current?.play()
    } else {
      videoRef.current?.pause()
    }
    setIsVideoPaused(!isVideoPaused)
  }

  const handleFullscreenButtonClick = () => {
    videoRef.current?.requestFullscreen()
  }

//   const handleSettingsButtonClick = () => {
//
//   }

  // const handleRightClick: MouseEventHandler<HTMLDivElement> = (e) => {
  //   e.preventDefault()
  //   const x = e.clientX
  //   const y = e.clientY
  //   RightClickContextMenuHandleRef.current?.showContextMenu({ x, y })
  // }

  const resetState = () => {
    setIsVideoInit(false)
    setVideoTitle('')
    setIsVideoPaused(false)
    setIsLoading(false)
    setPipelineId(null)
  }

  const handleRemoveVideo = () => {
    janusRef.current?.destroy!()
    resetState()
    clearInterval(isAliveIntervalRef.current)
    removeStreamFromLocalStorage(id)
    setPipelineId(null)
  }

  const hasActiveVideo = () => {
    return isVideoInit || isLoading
  }

  const isSelected = () => {
    return selected
  }

  useImperativeHandle(ref, () => {
    return {
      startFromRef: async ({ url, name }) => {
        startVideo(url, name)
      },
      hasActiveVideo,
      isSelected
    }
  }, [isVideoInit, isLoading])

  useEffect(() => {
    return () => {
      handleRemoveVideo()
    }
  },[])

  const handleSuperResolutionClick = async () => {
    if (!srmodalref.current) return
    srmodalref.current.open(pipelineId!)
  }

  const renderTitleBar = () => {
    if (!titlesVisible) return null

    if (isVideoInit) {
      return (
        <ScTitleBar>
          <span><b>{videoTitle}</b></span>
          <Button onClick={handleRemoveVideo}>{t('Remove Stream')}</Button>
        </ScTitleBar>
      )
    }

    if (isLoading) {
      return (
        <ScTitleBar>
          <span style={{ visibility: 'hidden' }}><b>.</b></span>
          <Button onClick={handleRemoveVideo}>{t('Cancel')}</Button>
        </ScTitleBar>
      )
    }

    return (
      <ScTitleBar>
        <span style={{ visibility: 'hidden' }}><b>.</b></span>
        <Button style={{ visibility: 'hidden' }} >{t('.')}</Button>
      </ScTitleBar>
    )
  }

  return (
    <ScVideoItem
      ref={drop}
      $isOver={isOver}
      $order={order}
      // onContextMenu={handleRightClick}
    >
      {renderTitleBar()}
      <ScInnerVideoWrapper ref={fullscreenTargetRef} $selected={selected} onClick={onClick}>
        <video
          className="detection-container__frame"
          ref={videoRef}
          muted
          controlsList='disablepictureinpicture'
        />
        {!isVideoInit && <EmptySlotOverlay />}
        {isLoading && <LoadingOverlay {...videoTitle && { resourceName: videoTitle }} />}
        {isVideoInit && (
          <ControlsOverlay
            isPaused={isVideoPaused}
            onPlayPauseButtonClick={handlePlayPauseButtonClick}
            onFullscreenButtonClick={handleFullscreenButtonClick}
            onSuperResolutionClick={handleSuperResolutionClick}
            // onSettingsButtonClick={handleSettingsButtonClick}
          />
        )}
      </ScInnerVideoWrapper>
      {/* {isVideoInit && <RightClickContextMenu onToggleTitleVisibility={onToggleTitleVisibility} titlesVisible={titlesVisible} onRemoveVideo={handleRemoveVideo} ref={RightClickContextMenuHandleRef} />} */}
      <SRModal ref={srmodalref} />
    </ScVideoItem>
  )
})

export default VideoItem