import { FC } from 'react';
import { Typography, styled, useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import {useTranslation} from "react-i18next";

const TitleTypography = styled(Typography)({
  fontSize: '18px',
  margin: '2vh',
  textAlign: 'left',
});

const DescriptionTypography = styled(Typography)({
  fontSize: '12px',
  margin: '2vh',
  textAlign: 'left',
});

type HelpProps={
}

export const Help: FC<HelpProps> = ({

}) => {
  const {t} = useTranslation("common");
  const theme = useTheme();

  return(
    <Box sx={{ width: '100%'}}>
      <TitleTypography>{t("Help")}</TitleTypography>
      <DescriptionTypography>{t("The help documentation will be displayed once ready.")}</DescriptionTypography>
    </Box>

  )


};


