import { FC, useState } from "react";
import {
  Grid,
  Divider,
  styled,
  Typography,
  FormControl,
  Select,
  MenuItem,
  OutlinedInput,
  Box,
  useTheme,
  Alert,
  AlertTitle,
} from "@mui/material";
import List from "@mui/material/List";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs, { Dayjs } from "dayjs";
import { MenuProps } from "../../../components/SelectWithFetch/SelectWithFetch";
import { useTranslation } from "react-i18next";
import { Iha, SearchUser } from "../../../types/Types";

import{ api } from "../../../utils/api";

type SavePrivelegesRequestType = {
  end_date: string | number | Date | dayjs.Dayjs | null | undefined;
  id: number;
  start_date: string | number | Date | dayjs.Dayjs | null | undefined;
};

const DescriptionTypography = styled(Typography)({
  fontSize: "16px",
  marginTop: "2vh",
  marginLeft: "2vh",
  textAlign: "left",
});

const can_watch_options = [
  {
    value: 1,
    label: "1",
  },
  {
    value: 2,
    label: "2",
  },
];

function not(a: Iha[], b: Iha[]) {
  return a.filter((value) => b.indexOf(value) === -1);
}
function intersection(a: Iha[], b: Iha[]) {
  return a.filter((value) => b.indexOf(value) !== -1);
}
function union(a: Iha[], b: Iha[]) {
  return [...a, ...not(b, a)];
}

type TransferListProps = {
  ihas: Iha[];
  user: SearchUser;
};
export const TransferList: FC<TransferListProps> = ({ ihas, user }) => {
  const { t } = useTranslation("common");
  const theme = useTheme();

  const [checked, setChecked] = useState<Iha[]>([]);
  const [left, setLeft] = useState<Iha[]>(
    ihas.filter((iha) => iha.authorized === 0)
  );
  const [right, setRight] = useState<Iha[]>(
    ihas.filter((iha) => iha.authorized === 1)
  );
  const [newCanWatch, setNewCanWatch] = useState(user.can_watch);
  const [loading, setLoading] = useState(false);
  const [startDate, setStartDate] = useState<Dayjs | null>(dayjs("2022-04-17"));
  const [endDate, setEndDate] = useState<Dayjs | null>(dayjs("2022-04-17"));
  const [alertSuccess, setAlertSuccess] = useState(false);
  const [alertError, setAlertError] = useState(false);
  const [alertcontent, setalertcontent] = useState("");
  const leftChecked = intersection(checked, left);
  const rightChecked = intersection(checked, right);

  const handleToggle = (value: Iha) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];
    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setChecked(newChecked);
  };

  const numberOfChecked = (items: Iha[]) => intersection(checked, items).length;

  const handleToggleAll = (items: Iha[]) => () => {
    if (numberOfChecked(items) === items.length) {
      setChecked(not(checked, items));
    } else {
      setChecked(union(checked, items));
    }
  };

  const handleCheckedRight = () => {
    setRight(right.concat(leftChecked));
    setLeft(not(left, leftChecked));
    setChecked(not(checked, leftChecked));
  };

  const handleCheckedLeft = () => {
    setLeft(left.concat(rightChecked));
    setRight(not(right, rightChecked));
    setChecked(not(checked, rightChecked));
  };

  const savePrivileges = () => {
    // Prepare data for back-end, only authorized ykis should go
    const ihaArr = [...ihas];
    const authorized_ihas: SavePrivelegesRequestType[] = [];
    for (const iha of right) {
      const start = new Date(iha.start_date);
      const formattedDateS = `${start.getDate()}/${
        start.getMonth() + 1
      }/${start.getFullYear()}`;
      const end = new Date(iha.end_date);
      const formattedDateE = `${end.getDate()}/${
        end.getMonth() + 1
      }/${end.getFullYear()}`;
      authorized_ihas.push({
        id: iha.id,
        start_date: formattedDateS,
        end_date: formattedDateE,
      });
    }
    setLoading(true);
    api.base.put("/privileges/set_privileges", {
      user_id: user.id,
      can_watch: newCanWatch,
      ihas: authorized_ihas,
    })
      .then(() => {
        setalertcontent(t("Authorization completed successfully!"));
        setAlertSuccess(true);
        const timer = setTimeout(() => {
          setAlertSuccess(false);
        }, 2000);
        return () => clearTimeout(timer);
      })
      .catch((error) => {
        setalertcontent(
          t("Authorization failed, failed to connect to server!")
        );
        setAlertError(true);
        const timer = setTimeout(() => {
          setAlertError(false);
        }, 2000);
        return () => clearTimeout(timer);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const customList = (title: React.ReactNode, items: Iha[]) => (
    <Card>
      <CardHeader
        sx={{ px: 2, py: 1 }}
        avatar={
          <Checkbox
            onClick={handleToggleAll(items)}
            checked={
              numberOfChecked(items) === items.length && items.length !== 0
            }
            indeterminate={
              numberOfChecked(items) !== items.length &&
              numberOfChecked(items) !== 0
            }
            disabled={items.length === 0}
            inputProps={{
              "aria-label": "all items selected",
            }}
          />
        }
        title={title}
        subheader={`${numberOfChecked(items)}/${items.length} ${t("selected")}`}
      />
      <Divider />
      <List
        sx={{
          width: "100%",
          height: 230,
          bgcolor: "background.paper",
          overflow: "auto",
        }}
        dense
        component="div"
        role="list"
      >
        {items.map((value: Iha) => {
          const labelId = `transfer-list-all-item-${value}-label`;
          return (
            <ListItem
              key={value.name}
              role="listitem"
              onClick={handleToggle(value)}
            >
              <ListItemIcon>
                <Checkbox
                  checked={checked.indexOf(value) !== -1}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{
                    "aria-labelledby": labelId,
                  }}
                />
              </ListItemIcon>
              <ListItemText
                id={labelId}
                primary={`${value.name}${value.url} `}
              />
              <Grid item xs={12} sm={6}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoContainer components={["DatePicker"]}>
                    <DatePicker
                      sx={{ fontSize: "small" }}
                      value={dayjs(value.start_date)}
                      onChange={(newValue) => setStartDate(newValue)}
                    />
                  </DemoContainer>
                </LocalizationProvider>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoContainer components={["DatePicker"]}>
                    <DatePicker
                      sx={{ fontSize: "small" }}
                      value={dayjs(value.end_date)}
                      onChange={(newValue) => setEndDate(newValue)}
                    />
                  </DemoContainer>
                </LocalizationProvider>
              </Grid>
            </ListItem>
          );
        })}
      </List>
    </Card>
  );

  return (
    <>
      <Box
        sx={{
          m: "2vh",
          p: "2vh",
          width: "50%",
          display: "flex",
          flexDirection: "column",
          border: "1px dashed grey",
        }}
      >
        {alertSuccess && (
          <Alert severity="success" sx={{ mb: 2 }}>
            <AlertTitle sx={{ fontSize: "24px" }}>Success!</AlertTitle>
            {alertcontent}
          </Alert>
        )}

        {alertError && (
          <Alert severity="error" sx={{ mb: 2 }}>
            <AlertTitle sx={{ fontSize: "24px" }}>{t("Error!")}</AlertTitle>
            {alertcontent}
          </Alert>
        )}

        <Typography sx={{ fontSize: "22px" }}>{t("Privileges")}</Typography>
        <Box
          sx={{
            display: "flex",
          }}
        >
          <DescriptionTypography>
            {t("Multiple Screen Monitoring Authority")}:
          </DescriptionTypography>
          <FormControl>
            <Select
              displayEmpty
              onChange={(e) => {
                setNewCanWatch(e.target.value as number);
              }}
              input={<OutlinedInput />}
              value={newCanWatch}
              MenuProps={MenuProps}
              inputProps={{ "aria-label": "Without label" }}
              sx={{
                ml: 2,
                fontSize: "small",
              }}
            >
              <MenuItem disabled>
                <em> Can Watch</em>
              </MenuItem>
              {can_watch_options.map((option) => (
                <MenuItem key={option.label} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>

        <Grid
          container
          spacing={2}
          justifyContent="left"
          alignItems="left"
          marginTop="2vh"
        >
          <Grid item>{customList(t("Not Privileged UAV List"), left)}</Grid>
          <Grid item>
            <Grid container direction="column" alignItems="center">
              <Button
                sx={{ my: 0.5 }}
                variant="outlined"
                size="small"
                onClick={handleCheckedRight}
                disabled={leftChecked.length === 0}
                aria-label="move selected right"
              >
                &gt;
              </Button>
              <Button
                sx={{ my: 0.5 }}
                variant="outlined"
                size="small"
                onClick={handleCheckedLeft}
                disabled={rightChecked.length === 0}
                aria-label="move selected left"
              >
                &lt;
              </Button>
            </Grid>
          </Grid>
          <Grid item>{customList(t("Privileged UAV List"), right)}</Grid>
        </Grid>
        <Box>
          <Button
            sx={{
              // width: "180px",
              display: "inline-block",
              width: "auto",
              fontSize: "small",
              m: 2,
              textTransform: "none",
              backgroundColor: theme.palette.primary.main,
              "&:hover": {
                backgroundColor: theme.palette.primary.main,
              },
            }}
            variant="contained"
            fullWidth
            onClick={savePrivileges}
          >
            {t("Save")}
          </Button>
        </Box>
      </Box>
    </>
  );
};
