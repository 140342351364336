import styled from "@emotion/styled";
import { StreamRightPanel } from './StreamRightPanel';
import { useRef} from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { VideoGrid } from './VideoGrid';
import { useStreamListQuery } from './hooks';
import { CircularProgress } from '@mui/material';
import { TVideoGridHandle } from './VideoGrid/VideoGrid';

const ScStreamPOCWrapper = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
`;

const StreamPOC = () => {
  const videoGridRef = useRef<TVideoGridHandle>(null)

  const { isLoading, error, data: streamSources } = useStreamListQuery()


  const handleSourceDoubleClick = (args: { id: number, url: string, name: string }) => {
    videoGridRef.current?.startStreamFromAvailableSlot(args)
  }

  return (
    <ScStreamPOCWrapper>
      { !isLoading && streamSources ? (
        <DndProvider backend={HTML5Backend}>
          <VideoGrid ref={videoGridRef}/>
          <StreamRightPanel onDoubleClick={handleSourceDoubleClick} streams={streamSources} />
        </DndProvider>
      ) : ( <div style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}><CircularProgress /></div> )}
    </ScStreamPOCWrapper>
  )
}

export default StreamPOC
