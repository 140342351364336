import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import {
  forwardRef,
  MouseEvent,
  MouseEventHandler,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { api } from "../../../utils/api";
import { toast } from "react-toastify";

type TBbox = {
  X1: number;
  Y1: number;
  X2: number;
  Y2: number;
  id: number;
  isSelected: boolean;
  isCustom: boolean;
};

export type TSRModalHandle = {
  open: (pipelineId: number) => void;
};

const initialCustomBboxesState = {
  lastId: 1,
  boxes: {},
};

type TResult = {
  key: string;
  image: string;
};
const SRModal = forwardRef<TSRModalHandle>((_, ref) => {
  const { t } = useTranslation("common");
  const [bboxList, setBboxList] = useState<TBbox[]>([]);
  const srImageRef = useRef<HTMLImageElement>(null);
  const frameBlobRef = useRef<Blob>();
  const [isOpen, setIsOpen] = useState(false);
  const [results, setResults] = useState<TResult[]>([]);
  const [allFrameResults, setAllFrameResults] = useState<string[]>([]);
  const [drawMode, setDrawMode] = useState<boolean>(false);
  const [drawPositions, setDrawPositions] = useState<null | {
    x1: number;
    y1: number;
    x2: number;
    y2: number;
    rectX: number;
    rectY: number;
    rectWidth: number;
    rectHeight: number;
  }>(null);
  const [customBboxes, setCustomBboxes] = useState<{
    lastId: number;
    boxes: {
      [K: number]: TBbox;
    };
  }>(initialCustomBboxesState);
  const [isInProgress, setInProgress] = useState(false);
  const selectedBoxes = [
    ...bboxList,
    ...Object.values(customBboxes.boxes),
  ].filter((el) => el.isSelected);

  const open: TSRModalHandle["open"] = async (pipelineId) => {
    setIsOpen(true);
    const res = await api.base.post<{
      frame: { Frame: { data: number[] } };
      bboxList: Omit<TBbox, "isSelected">[];
    }>("/grpc/getFrameWithBbox", { pipelineId });

    const { bboxList, frame } = res.data;
    setBboxList(
      bboxList.map((bbox, i) => ({
        ...bbox,
        isSelected: false,
        id: i,
        isCustom: false,
      }))
    );
    const uint8Array = new Uint8Array(frame.Frame.data);
    const frameBlob = new Blob([uint8Array], { type: "image/jpeg" });
    frameBlobRef.current = frameBlob;
    const url = window.URL.createObjectURL(frameBlob);
    // @ts-ignore
    srImageRef.current.src = url;
  };

  const close = () => {
    setResults([]);
    frameBlobRef.current = undefined;
    setBboxList([]);
    setIsOpen(false);
    setCustomBboxes(initialCustomBboxesState);
    setDrawMode(false);
    setDrawPositions(null);
    setResults([]);
    setAllFrameResults([]);
  };

  useImperativeHandle(ref, () => {
    return {
      open,
    };
  });

  const handleBboxClick = (id: number) => {
    setBboxList(
      bboxList.map((bbox) =>
        bbox.id === id ? { ...bbox, isSelected: !bbox.isSelected } : bbox
      )
    );
  };

  const renderBboxes = () => {
    if (!isOpen) return;
    const customBboxList = Object.values(customBboxes.boxes);
    return [...bboxList, ...customBboxList].map(
      ({ X1, Y1, X2, Y2, isSelected, id, isCustom }) => {
        const topPercent = (Y1 / 1080) * 100;
        const leftPercent = (X1 / 1920) * 100;
        const height = ((Y2 - Y1) / 1080) * 100 + "%";
        const width = ((X2 - X1) / 1920) * 100 + "%";

        return (
          <div
            key={`${X1}${Y2}${id}`}
            onClick={() => handleBboxClick(id)}
            style={{
              boxSizing: "content-box",
              border: `2px solid ${isSelected ? "green" : "red"}`,
              position: "absolute",
              top: `${topPercent}%`,
              left: `${leftPercent}%`,
              height,
              width,
            }}
          >
            <div
              style={{
                position: "absolute",
                top: "-14px",
                right: "-2px",
                background: `${isSelected ? "green" : "red"}`,
                fontSize: "12px",
                lineHeight: "12px",
              }}
            >{`${isCustom ? "ozel-" : ""}#${id}`}</div>
          </div>
        );
      }
    );
  };

  const handleSendSelectedBboxes = async () => {
    const formData = new FormData();
    formData.append("image", frameBlobRef.current!, "image.jpeg");
    formData.append(
      "bbox_list",
      JSON.stringify(
        selectedBoxes.map((el) => [
          Math.floor(el["X1"]),
          Math.floor(el["Y1"]),
          Math.ceil(el["X2"]),
          Math.ceil(el["Y2"]),
        ])
      )
    );

    setInProgress(true);
    try {
      const {
        data,
      } = await api.base.post("/drone/super_resolution", formData);
      if (data.isError) {
        toast.error(data.message);
        setInProgress(false);
        return
      }
      const res = JSON.parse(data.data) as {
        bbox_list: string;
        image: { data: string }[];
      };
      const images = res.image.map((el, i) => {
        const key = `${selectedBoxes[i].isCustom ? "ozel-" : ""}#${
          selectedBoxes[i].id
        }`;
        return {
          key,
          image: el.data,
        };
      });
      setResults(images);
      toast.success(t("Success!"));
    } catch {
      toast.error(t("Error!"));
    } finally {
      setInProgress(false);
    }
  };

  const handleSendFrame = async () => {
    const formData = new FormData();
    formData.append("image", frameBlobRef.current!, "image.jpeg");
    formData.append("bbox_list", "[]");

    setInProgress(true);
    try {
      const {
        data,
      } = await api.base.post("/drone/super_resolution", formData);
      if (data.isError) {
        toast.error(data.message);
        setInProgress(false);
        return
      }
      const res = JSON.parse(data.data) as {
        bbox_list: string;
        image: { data: string }[];
      };
      const images = res.image.map((el) => el.data);
      setAllFrameResults(images);
      toast.success(t("Success!"));
    } catch {
      toast.error(t("Error!"));
    } finally {
      setInProgress(false);
    }
  };

  const handleSRAreaKeydown: MouseEventHandler<HTMLDivElement> = (e) => {
    // @ts-expect-error
    const rect: { x: number; y: number; width: number; height: number } = e.target!.getBoundingClientRect();
    const rectX = rect.x;
    const rectY = rect.y;
    const x1 = e.clientX - rectX;
    const y1 = e.clientY - rectY;
    setDrawPositions({
      x1,
      y1,
      x2: x1,
      y2: y1,
      rectX,
      rectY,
      rectWidth: rect.width,
      rectHeight: rect.height,
    });
  };

  const handleSRAreaKeyup = () => {
    const { x1, y1, x2, y2, rectWidth, rectHeight } = drawPositions!;

    const X1 = (x1 / rectWidth) * 1920;
    const Y1 = (y1 / rectHeight) * 1080;

    const X2 = (x2 / rectWidth) * 1920;
    const Y2 = (y2 / rectHeight) * 1080;

    setCustomBboxes({
      lastId: customBboxes.lastId + 1,
      boxes: {
        ...customBboxes.boxes,
        [customBboxes.lastId + 1]: {
          id: customBboxes.lastId,
          X1,
          Y1,
          X2,
          Y2,
          isSelected: true,
          isCustom: true,
        },
      },
    });
    setDrawPositions(null);
  };

  const handleSRAreaMouseMove = (e: { clientX: number; clientY: number }) => {
    if (!drawPositions) return;
    setDrawPositions({
      ...drawPositions,
      x2: e.clientX - drawPositions.rectX,
      y2: e.clientY - drawPositions.rectY,
    });
  };

  const renderCustomArea = () => {
    if (!drawPositions) return null;
    const { x1, y1, x2, y2 } = drawPositions;
    return (
      <div
        style={{
          border: "4px solid yellow",
          position: "absolute",
          top: `${y1}px`,
          left: `${x1}px`,
          height: `${y2 - y1}px`,
          width: `${x2 - x1}px`,
          zIndex: 1000,
        }}
      ></div>
    );
  };

  const handleToggleDrawMode = () => {
    setDrawMode(!drawMode);
  };

  const handleResetDrawings = () => setCustomBboxes(initialCustomBboxesState);

  return (
    <Dialog
      keepMounted={false}
      fullWidth={true}
      maxWidth={"lg"}
      open={isOpen}
      onClose={close}
    >
      <DialogTitle
        sx={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Typography
          sx={{
            fontSize: "18px",
            fontWeight: "bold",
            margin: 0,
            display: "flex",
            alignItems: "center",
          }}
        >
          Super Resolution
        </Typography>{" "}
        <Button onClick={close}>{t("Close")}</Button>
      </DialogTitle>
      <DialogContent sx={{ position: "relative" }}>
        {isInProgress && (
          <div
            style={{
              position: "absolute",
              height: "100%",
              width: "100%",
              background: "#ffffff55",
              zIndex: "9999",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                height: "100%",
              }}
            >
              <CircularProgress />
            </div>
          </div>
        )}
        {/* <DialogContentText>
            You can set my maximum width and whether to adapt or not.
          </DialogContentText> */}
        <Box
          component="div"
          sx={{
            display: "flex",
            justifyContent: "center",
            width: "96%",
            margin: "0 auto",
            position: "relative",
          }}
        >
          <img width="100%" ref={srImageRef} />
          {renderBboxes()}
          {drawMode && (
            <div
              className="selectionBox"
              style={{
                cursor: "crosshair",
                position: "absolute",
                width: "100%",
                height: "100%",
                border: "1px solid yellow",
                zIndex: "999",
              }}
              onMouseDown={handleSRAreaKeydown}
              onMouseUp={handleSRAreaKeyup}
              onMouseMove={handleSRAreaMouseMove}
            >
              {renderCustomArea()}
            </div>
          )}
        </Box>
        {allFrameResults.map((res, i) => {
          return (
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
              >
                Frame SR
              </AccordionSummary>
              <AccordionDetails>
                <img width="96%" src={`data:${res}`} />
              </AccordionDetails>
            </Accordion>
          );
        })}
        {results.map((res, i) => {
          return (
            <Accordion key={res.key}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
              >
                {`${t("Result")} ${res.key}`}
              </AccordionSummary>
              <AccordionDetails>
                <img width="96%" src={`data:${res.image}`} />
              </AccordionDetails>
            </Accordion>
          );
        })}
      </DialogContent>
      <DialogActions
        sx={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <div>
          <Button disabled={isInProgress} onClick={handleToggleDrawMode}>
            {drawMode ? t("End Drawing") : t("Draw Area")}
          </Button>
          <Button disabled={isInProgress} onClick={handleResetDrawings}>
            {t("Reset")}
          </Button>
        </div>
        <div>
          <Button
            disabled={!selectedBoxes.length || isInProgress}
            onClick={handleSendSelectedBboxes}
          >
            {t("Send Selected")}
          </Button>
          {/* <Button disabled={isInProgress} onClick={handleSendFrame}>
            {t("Send Frame")}
          </Button> */}
        </div>
      </DialogActions>
    </Dialog>
  );
});

export default SRModal;
