import { Alert, Box, Card, IconButton, Modal } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import CopyToClipboardButton from "../ClipboardButton/CopyToClipboardButton";

type SavedUserInfoModalProps = {
  signUpModalOpen: boolean;
  user_created: boolean;
  samaccountname: string;
  password: string;
  setSignUpModalOpen: (value: boolean) => void;
};

const SavedUserInfoModal: FC<SavedUserInfoModalProps> = ({
  signUpModalOpen,
  user_created,
  samaccountname,
  password,
  setSignUpModalOpen,
}) => {
  const { t } = useTranslation("common");

  return (
    <Modal
      open={signUpModalOpen && user_created}
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
    >
      <Card
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 600,
          stroke: "none",
          padding: 3,
        }}
      >
        <IconButton onClick={() => setSignUpModalOpen(false)}>
          <CloseIcon />
        </IconButton>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignContent: "center",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <strong>{t("Username")}: </strong>
          {samaccountname}
          <strong>{t("Password")}: </strong>
          {password}
          <CopyToClipboardButton text={password} />
        </Box>
        <Alert severity="warning">
          -{" "}
          {t(
            "The user has been created, do not forget to save this information!"
          )}
        </Alert>
      </Card>
    </Modal>
  );
};

export default SavedUserInfoModal;
