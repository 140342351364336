import { FC, useState } from "react";
import { isHigherThan, isLowerThan } from "../../../../plugins/InputHandler";
import { isEmpty } from "../../../../plugins/InputHandler";
import { Box, Modal, Card, useTheme, Alert, AlertTitle } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Button, Typography, styled } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import Input from "@mui/material/Input";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { useTranslation } from "react-i18next";
import { SetUser } from "../../../../types/Types";

import{ api } from "../../../../utils/api";

const TitleTypography = styled(Typography)({
  fontSize: "22px",
  textAlign: "center",
});

type PasswordModalProps = {
  user?: SetUser;
  setPassword_modal_visible: (value: boolean) => void;
};

export const PasswordModal: FC<PasswordModalProps> = ({
  user,
  setPassword_modal_visible,
}) => {
  const { t } = useTranslation("common");

  const [modal_loading, setModal_Loading] = useState(false);
  const [password, setPassword] = useState<string>();
  const [password_again, setPassword_again] = useState<string>();
  const [showPassword, setShowPassword] = useState(false);
  const theme = useTheme();
  const [alertSuccess, setAlertSuccess] = useState(false);
  const [alertError, setAlertError] = useState(false);
  const [alertcontent, setalertcontent] = useState("");

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const savePassword = () => {
    try {
      validatePassword();
    } catch (error) {
      setalertcontent(`${error}`);
      setAlertError(true);
      const timer = setTimeout(() => {
        setAlertError(false);
      }, 2000);
      console.log(error);
      return;
    }
    console.log(user)
    setModal_Loading(true);
    api.base.put("/account/change_password", {
      user_id: user?.ID,
      password: password,
    })
      .then(() => {
        setalertcontent(t("Password has been changed successfully!"));
        setAlertSuccess(true);
        const timer = setTimeout(() => {
          setAlertSuccess(false);
        }, 2000);
      })
      .catch((error) => {
        setalertcontent(`${error}`);
        setAlertError(true);
        const timer = setTimeout(() => {
          setAlertError(false);
        }, 2000);
        console.log(error);
      })
      .finally(() => {
        setModal_Loading(false);
      });
    //});
  };

  //   setPassword = (event) => { this.setState({ password: event.target.value }); }

  //   setPasswordAgain = (event) => { this.setState({ password_again: event.target.value }); }

  const validatePassword = () => {
    if (isEmpty(password) || isEmpty(password_again))
      throw String("Lütfen alanları doldurunuz!");
    if (password !== password_again) throw String("Parolalar uyuşmuyor!");
    if (isLowerThan(password, 6) || isHigherThan(password, 64))
      throw String("Parola minimum 6 maksimum 64 karakter olabilir!");
  };

  return (
    <>
      <Box>
        <Modal
          open={true}
          aria-labelledby="parent-modal-title"
          aria-describedby="parent-modal-description"
        >
          <Card
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              stroke: "none",
              padding: 3,
            }}
          >
            
            <IconButton onClick={() => setPassword_modal_visible(false)}>
              <CloseIcon />
            </IconButton>
                          {alertSuccess && (
              <Alert severity="success" sx={{ mb: 2 }}>
                <AlertTitle sx={{ fontSize: "24px" }}>Başarılı!</AlertTitle>
              {alertcontent}
              </Alert>
            )}

            {alertError && (
              <Alert severity="error" sx={{ mb: 2 }}>
                <AlertTitle sx={{ fontSize: "24px" }}>Hata!</AlertTitle>
            {alertcontent}
              </Alert>
        )}
            <TitleTypography>{t("Change Password")}</TitleTypography>
            <FormControl sx={{ m: 1, width: "25ch" }} variant="standard">
              <InputLabel htmlFor="standard-adornment-password">
                {t("Password")}
              </InputLabel>
              <Input
                id="standard-adornment-password"
                type={showPassword ? "text" : "password"}
                autoComplete="disabled"
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                onChange={(e) => setPassword(e.target.value)}
              />
            </FormControl>
            <FormControl sx={{ m: 1, width: "25ch" }} variant="standard">
              <InputLabel htmlFor="standard-adornment-password">
                {t("Password Again")}
              </InputLabel>
              <Input
                id="standard-adornment-password"
                type={showPassword ? "text" : "password"}
                autoComplete="disabled"
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                onChange={(e) => setPassword_again(e.target.value)}
              />
            </FormControl>
            <Button
              onClick={savePassword}
              variant="outlined"
              sx={{
                mt: 5,
                color: theme.palette.primary.main,
                borderColor: theme.palette.primary.main,
                "&:hover": {
                  borderColor: theme.palette.primary.main,
                },
                textTransform: "none",
                fontSize: "18px",
              }}
            >
              {t("Save")}
            </Button>
          </Card>
        </Modal>
      </Box>
    </>
  );
};
