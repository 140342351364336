import { Button, useTheme } from '@mui/material';

import { useNavigate } from 'react-router';

import turkKKK from './kkk.png';

export const DatabossImg = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  //console.log();
  return(
    <Button
      sx={{
        padding: "8px",
        width: "100%",
        height: "100%",
      }}
      onClick={()=>navigate("/")}
    >
      <img
        src={turkKKK}
        style={{
          maxWidth: '100%',
          maxHeight: '48px',
          objectFit: "contain",
        }}
        alt="databoss-logo"
      />
    </Button>
  )}
