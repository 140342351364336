import React, { FC, LegacyRef, useRef, useState } from "react";
import Box from "@mui/material/Box";
import { Button, useTheme,  Typography, styled } from '@mui/material';
import {useTranslation} from "react-i18next";

type FileUploadComponentProps = {
  accept: string,
  label: string,
  setUploadedFiles: (uploadFile: any) => void,
};

type file = {
  name: string,
};

type imageFile = file & MediaSource

const TitleTypography = styled(Typography)({
  fontSize: '18px',
  marginTop: '-1vh',
  textAlign: 'center',
  fontWeight: 'bold',
  margin: '1%'
});

const DescTypography = styled(Typography)({
  fontSize: '12px',
  marginTop: '-1vh',
  textAlign: 'center',
  margin: '1%'
});

export const FileUpload: FC<FileUploadComponentProps> = ({
  accept,
  label,
  setUploadedFiles
}) => {
  const [file, setFile] = useState<imageFile | null>(null);
  const file_input = useRef<HTMLInputElement>(null);
  const theme = useTheme();
  const {t} = useTranslation("common");

  const handleUploadClick = () => {

		
	}

  const handleUploadedFiles = (event : any) => {
    setFile(event.target.files[0]);
    setUploadedFiles(event.target.files[0]);
	}

  const removeFile = () => {
    setFile(null);
    setUploadedFiles(null);

	}
  const triggerFileInput = () => {
    file_input.current?.click();

  }

  return(
    <>
    <Box>
      <Box sx={{ 
        width: '100%', 
        height: '50%', 
        display:'grid',
        alignItems: 'center',
        justifyItems: 'center',
        alignContent: 'space-around',
        border: '1px dashed grey',
        marginTop: '2%',
        padding: '2%'
      }}>
        <TitleTypography >{t(`Upload ${label}`)}</TitleTypography>
        <DescTypography>{t("Drag and drop your files into this area or")}</DescTypography>
        <Button 
          variant="outlined" 
          onClick={triggerFileInput}
          sx={{
            color: theme.palette.primary.main,
            borderColor: theme.palette.primary.main,
            '&:hover': {
              borderColor: theme.palette.primary.main,
            },
            textTransform: 'none',
            fontSize:'small'
          }}
         >
        {t("Select a file")}
        </Button>
        <input
          style={{
            display: 'block',
            left: '0',
            opacity: '0',
            position: 'relative',
            top: '0',
            height: '100%',
            width: '100%'
          }}
          accept={accept}
          onChange={handleUploadedFiles}
          ref={file_input as LegacyRef<HTMLInputElement>}
          type="file"
          value=""/>  
      </Box>
      {file &&
        <Box
          sx={{
            width: '100%',
            height: '180px',
            display:'grid',
            alignItems: 'center',
            justifyItems: 'center',
            alignContent: 'space-around',
            marginTop: '5%',
            border: '1px black',
            boxShadow: theme.shadows[12],
        }}>
          <DescTypography>{t("File Name")}: {file.name}</DescTypography>
          <div>
          <a
            href={URL.createObjectURL(file)}
            rel="noreferrer"
            target="_blank"
            style={{
              color: theme.palette.primary.main,
              fontSize:'small'
            }}
            >
            {t("View File")}
          </a>
          </div>
          <Button 
            onClick={removeFile} 
            variant="outlined" 
            sx={{
              color: theme.palette.primary.main,
              borderColor: theme.palette.primary.main,
              '&:hover': {
                borderColor: theme.palette.primary.main,
              },
              textTransform: 'none',
              fontSize:'small',
              margin:'1%'
            }}>
            {t("Delete")}
          </Button>
        </Box>
      }
    </Box>
    </>
  )
};
  

