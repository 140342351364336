import {Dispatch, FC, SetStateAction} from "react";

import {TAktiflik, TFilters, TIzlenme} from "./StreamRightPanel";
import Box from "@mui/material/Box";
import {FormControl, InputLabel, MenuItem, Select, TextField} from "@mui/material";

type StreamFiltersProps = {
  filters: TFilters;
  setFilters: Dispatch<SetStateAction<TFilters>>;
}

export const StreamFilters: FC<StreamFiltersProps> = ({ filters, setFilters }) => {
  return <Box
    sx={{
      display: "flex",
      flexDirection: "row",
      flexFlow: "wrap",
      flexBasis: "100%",
    }}
  >
    <Box
      sx={{
        display: "flex",
        flex: 1,
        flexBasis: "40%",
        minWidth: "160px",
        padding: "10px",
      }}
    >
      <TextField
        fullWidth
        id="filter-name"
        label="İsim"
        size="small"
        value={filters.name}
        onChange={e => setFilters({ ...filters, name: e.target.value })}
      />
    </Box>
    <Box
      sx={{
        display: "flex",
        flex: 1,
        flexBasis: "30%",
        minWidth: "120px",
        padding: "10px",
      }}
    >
      <FormControl fullWidth size="small" >
        <InputLabel id="filter-isActive-label">Aktiflik</InputLabel>
        <Select
          labelId="filter-isActive-label"
          id="filter-isActive"
          label="Aktiflik"
          value={filters.isActive}
          onChange={(e) => setFilters({ ...filters, isActive: e.target.value as TAktiflik })}
        >
          <MenuItem value={"-"} >Tümü</MenuItem>
          <MenuItem value={"aktif"} >Aktif</MenuItem>
          <MenuItem value={"pasif"} >Pasif</MenuItem>
        </Select>
      </FormControl>
    </Box>
    <Box
      sx={{
        display: "flex",
        flex: 1,
        flexBasis: "30%",
        minWidth: "120px",
        padding: "10px",
      }}
    >
      <FormControl fullWidth size="small" >
        <InputLabel id="filter-onLive-label">İzlenme</InputLabel>
        <Select
          labelId="filter-onLive-label"
          id="filter-onLive"
          label="İzlenme"
          value={filters.onLive}
          onChange={(e) => setFilters({ ...filters, onLive: e.target.value as TIzlenme })}
        >
          <MenuItem value={"-"} >Tümü</MenuItem>
          <MenuItem value={"izleniyor"} >İzleniyor</MenuItem>
          <MenuItem value={"izlenmiyor"} >İzlenmiyor</MenuItem>
        </Select>
      </FormControl>
    </Box>
  </Box>
}