import { FC, useEffect, useRef, useState } from 'react';
import Box from "@mui/material/Box";
import {Button, Modal, Card, IconButton, useTheme} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import {useTranslation} from "react-i18next";
import { AlertMessage } from '../../../components/Alert/Alert';
import { YKIListTable } from './YKIListTable';
import { yki } from '../../../types/Types';

import { api } from "../../../utils/api";

type YKIListProps = {
    ykis : yki[];
    modalOpen: boolean;
    setYkis : (value : yki[]) => void;
    setModalOpen : (value: boolean) => void;
    onYKIDelete: (yki_id: number) => void
}

export const YKIList: FC<YKIListProps> = ({
    ykis,
    modalOpen,
    setYkis,
    setModalOpen,
    onYKIDelete
  }) => {
  const {t} = useTranslation("common");
  const theme = useTheme();

  const [ykisWithKey, setYkisWithKey] = useState<yki[]>([])
  const [count, setCount] = useState(0);
  const [flag, setFlag] = useState(false);
  const [initialYkiLength, setInitialYkiLength] = useState(0);
  const [alertSuccess, setAlertSuccess] = useState(false);
  const [alertError, setAlertError] = useState(false);
  const [alertcontent, setalertcontent] = useState('');
  const [loading, setLoading] = useState(false);
  var ykisUpdateList: yki[] = [...ykisWithKey]

  const hasEditRef = useRef(false)

  useEffect(() => {
    if(!flag){
    // if(!hasEditRef.current){
      setYkisWithKey(ykis.map((yki,index) => { return { key: index, ...yki } }))
      setInitialYkiLength(ykis.length)
    }
  },[ykis])

  function generateRows(){
    setFlag(true)
    if (hasEditRef.current) return
    hasEditRef.current = true
      setYkis([...ykis,{
        key: count + initialYkiLength,
        name:"",
        url: ""

      }])
      setYkisWithKey([...ykisWithKey,{
        key: count + initialYkiLength,
        name:"",
        url: ""
      }])
      setCount(count+1)
    }

  const deleteRows = (rowId: number) =>{
    if (rowId === -1) {
      const newYkis = ykis.slice(0, ykis.length - 1)
      const newYkisWithKeys = ykisWithKey.slice(0, ykis.length - 1)
      setYkis(newYkis)
      setYkisWithKey(newYkisWithKeys)
      setCount(count - 1)
      hasEditRef.current = false
    } else {
      onYKIDelete(rowId)
    }
  }

  const setIndexName = (index: number, value: string) => {
      ykisUpdateList[index].name = value;
      setYkisWithKey(ykisUpdateList);
  }

  const setIndexUrl = (index: number, value: string) => {
      ykisUpdateList[index].url = value;
      setYkisWithKey(ykisUpdateList);
  }

  const saveYKIs = () => {
    setLoading(true)
		api.base.put("/drone/set_yki", {
			ykis: ykisWithKey
		})
		.then((response) => {
      setYkis(response.data);
      setalertcontent(t("UAVs have been updated!"));
      setAlertSuccess(true);
      const timer = setTimeout(() => {
          setAlertSuccess(false)
      }, 2000);
      hasEditRef.current = false
      return () => clearTimeout(timer);

		})
		.catch((error) => {
      setalertcontent(t("An error was occur while editing UAVs!"));
      setAlertError(true);
      const timer = setTimeout(() => {
          setAlertError(false)
      }, 2000);
      return () => clearTimeout(timer);

		})
		.finally(() => {
      setLoading(false)
		});
}
  const handleModalClose = () => {
    if (hasEditRef.current) {
      const newYkis = ykis.slice(0, ykis.length - 1)
      const newYkisWithKeys = ykisWithKey.slice(0, ykis.length - 1)
      setYkis(newYkis)
      setYkisWithKey(newYkisWithKeys)
      setCount(count - 1)
      hasEditRef.current = false
    }
    setModalOpen(false)
  }
  return(
    <>
      <Box>
        <Modal
        open={modalOpen}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description">
          <Card sx={{
            height:'600px',
            display: 'flex',
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            stroke: 'none',
            padding: 3,
          }}>
            <Box sx={{
              display:'flex',
              flexDirection:'column',
              justifyContent:'space-between',
              alignItems:'flex-end',
              width:'100%',
              opacity: loading ? 0.7 : 1,
            }}>
              <IconButton sx={{ mb: 5}} onClick={handleModalClose}>  <CloseIcon /> </IconButton>
              <AlertMessage alertSuccess={alertSuccess} alertError={alertError} alertcontent={alertcontent} />
              <YKIListTable ykis={ykis} setIndexName={setIndexName} setIndexUrl={setIndexUrl} deleteRows={deleteRows} />
              <Box sx={{m:2}}>
                <Button
                  sx={{
                    display: "inline-block",
                    width: "auto",
                    fontSize: "small",
                    textTransform: "none",
                    m:2,
                    backgroundColor: theme.palette.primary.main,
                    '&:hover': {
                      backgroundColor: theme.palette.primary.main,
                    }}}
                  variant="contained"
                  fullWidth
                  onClick={() => generateRows()}>
                  {t("Add New UAV")}
                </Button>
                <Button
                  sx={{
                    display: "inline-block",
                    width: "auto",
                    fontSize: "small",
                    textTransform: "none",
                    m:2,
                    backgroundColor: theme.palette.primary.main,
                    '&:hover': {
                      backgroundColor: theme.palette.primary.main,
                    },
                    }}
                  variant="contained"
                  fullWidth
                  onClick={()=> saveYKIs()}>
                  {t("Save")}
                </Button>
              </Box>
            </Box>
          </Card>
        </Modal>
      </Box>
    </>
)
};
