import axios, { AxiosHeaders, InternalAxiosRequestConfig } from "axios";

import { baseURL } from "./config";
import {toast} from "react-toastify";

export class Api {
  baseUrl = baseURL;
  base = axios.create({
    baseURL: this.baseUrl,
  });

  constructor() {
    this.base.interceptors.request.use((config: InternalAxiosRequestConfig) => {
      //AxiosRequestConfig
      return {
        ...config,
        // headers: {
        //   Authorization:  `Bearer ${(localStorage && localStorage.getItem('token')) || 'noToken'}`
        // }
        headers: new AxiosHeaders({
          Authorization: `Bearer ${
            (localStorage && localStorage.getItem("token")) || "noToken"
          }`,
        }),
      } as InternalAxiosRequestConfig; //AxiosRequestConfig
    });
    this.base.interceptors.response.use(
      (r) => r,
      (e) => {
        const errorMessage = (
          e.response?.data?.error?.data?.error?.message ||
          e.response?.data?.error?.data?.error ||
          e.response?.data?.error?.message ||
          e.response?.data?.error ||
          e.response?.data ||
          e.message
        );
        if (e.config.disableToast) {
          throw Error(errorMessage);
        }
        toast.error(errorMessage)
        throw Error(errorMessage);
      }
    );
  }
}

export const api = new Api()
