import { FC, useEffect, useState } from 'react';
import { Box, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import Grid from "@mui/material/Grid";
import { Button, useTheme,  Typography, styled } from '@mui/material';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import {useTranslation} from "react-i18next";
import categories1  from '../../categories/CategoriesKKK';
import  categories2 from '../../categories/Categories';
import { colorObj } from '../../types/Types';

import{ api } from "../../utils/api";


const TitleTypography = styled(Typography)({
  fontSize: '24px',
  marginTop: '-1vh',
  textAlign: 'left',
});

const SubTitleTypography = styled(Typography)({
  fontSize: '24px',
  margin: '2vh 0 2vh 0',
  textAlign: 'left',
});

type PrecisionSettingProps={
}
  
export const PrecisionSetting: FC<PrecisionSettingProps> = ({
}) => {
  const { t } = useTranslation("common");
  const theme = useTheme();
  
  const [min, setMin] = useState<string>();
  const [normal, setNormal] = useState<string>();
  const [max, setMax] = useState<string>();
  const [loading, setLoading] = useState(false);
  const [alertSuccess, setAlertSuccess] = useState(false);
  const [alertError, setAlertError] = useState(false);
  const [alertcontent, setalertcontent] = useState('');
  const [valid, setValid] = useState(true);
  const [value, setValue] = useState('#0000FF');
  const [model, setModel] = useState('');
  const [currentClasses, setCurrentClasses] = useState<colorObj[]>([]);
  var tmpCurrentClasses : colorObj[] = [];
  var localStorageItem = localStorage.getItem("color");

  const [age, setAge] = useState('');
  const [width, setWidth] = useState('');


  const handleChangeAge = (event: SelectChangeEvent) => {
    setAge(event.target.value as string);
    localStorage.setItem("style", event.target.value)
  };

  const handleWidthAge = (event: SelectChangeEvent) => {
    setWidth(event.target.value as string);
    localStorage.setItem("width", event.target.value)
  };

  function getPosition(string: string, subString : string, index: number) {
    return string.split(subString, index).join(subString).length;
  }

  function getColor( index: number) {

    if(localStorageItem){
      if(localStorageItem?.substring(getPosition(localStorageItem, ',r', index),getPosition(localStorageItem, ',r', index+1)).indexOf(",") === 0 )
        return localStorageItem?.substring(getPosition(localStorageItem, ',r', index),getPosition(localStorageItem, ',r', index+1)).substring(1);
      else 
        return localStorageItem?.substring(getPosition(localStorageItem, ',r', index),getPosition(localStorageItem, ',r', index+1));
    }
    return "rgb(156, 39, 176)"
  }
  const handleChangeColor = (newValue : any, label: string) => {
    setValue(newValue);
    const newState = currentClasses.map(obj => {
      if (obj.class === label ) {
        return {...obj, color: newValue};
      }
      return obj;
    });
    setCurrentClasses(newState);
    localStorage.setItem("color", currentClasses.map(({ color }) => color).toString())
  }

  useEffect(() => {
    getSettings();
  }, []); 

  const getSettings = () => {
    setLoading(true);
      api.base.get("/settings/get_settings")
      .then((response) => {
          setMin(response.data.bbox_min);
          setNormal(response.data.bbox_normal);
          setMax(response.data.bbox_max);
      })
      .catch((error) => {
          setalertcontent(t("Unable to retrieve settings, failed to connect to server!"));
          setAlertError(true);
          const timer = setTimeout(() => {
              setAlertError(false)
          }, 2000);
          return () => clearTimeout(timer);
    })
        .finally(() => {
            setLoading(false);
        });
    }

    const handleChange = (event: SelectChangeEvent) => {
      setModel(event.target.value as string);
      if(event.target.value as string === "1"){
        for(var i = 0 ; i<4 ; i++){
            const colorObj = {class : `${categories2[i].label}`, color: "rgb(156, 39, 176)"}
            tmpCurrentClasses.push(colorObj);
        }
        setCurrentClasses(tmpCurrentClasses);
        tmpCurrentClasses = [];
      }
      else{
          for(var i = 0 ; i<8 ; i++){
            const colorObj = {class : `${categories2[i].label}`, color: "rgb(156, 39, 176)"}
            tmpCurrentClasses.push(colorObj);
        }
        setCurrentClasses(tmpCurrentClasses);
        tmpCurrentClasses = [];
      }
    };

    const saveSettings = () => {
        try { verifySettings(); }
        catch(error) {
            setalertcontent(error as string);
            setAlertError(true);
            const timer = setTimeout(() => {
                setAlertError(false)
            }, 2000);
            return () => clearTimeout(timer);
        }
        setLoading(true);
            api.base.put("/settings/set_settings", {
                bbox_min: min,
                bbox_normal: normal,
                bbox_max: max,
            })
            .then(() => {
                setalertcontent(t("Settings updated successfully!"));
                setAlertSuccess(true);
                const timer = setTimeout(() => {
                    setAlertSuccess(false)
                }, 2000);
                return () => clearTimeout(timer);
            })
            .catch((error) => {
                setalertcontent(t("Unable to save settings, server error!"));
                setAlertError(true);
                const timer = setTimeout(() => {
                    setAlertError(false)
                }, 2000);
                return () => clearTimeout(timer);
    

            })
            .finally(() => {
                setLoading(false)
            });
    }

    const verifySettings = () => {
        if(min === "") throw String("Minimum precision value cannot be left blank!");
        if(normal === "") throw String("Normal precision value cannot be left blank!");
        if(max === "") throw String("Maximum precision value cannot be left blank!");
    }
    
  return(
    <>
    <Box 
      sx={{
        display:"content",
        flex: 1,
        alignItems:"center",
        width:"95%",
        mt:1,
        borderRadius:"20px",
        padding: 2
      }}>
        {alertSuccess && 
            <Alert severity="success" sx={{mb: 2}}>
                <AlertTitle sx={{fontSize: "24px"}}>{t("Success!")}</AlertTitle>
                {alertcontent}
            </Alert>
        }

        {alertError && 
            <Alert severity="error" sx={{mb: 2}}>
                <AlertTitle sx={{fontSize: "24px"}}>{t("Error!")}</AlertTitle>
                {alertcontent}
            </Alert>
        }

        <TitleTypography>{t("Model Based Object Detection Sensitivity")}</TitleTypography>        
          <Grid container spacing={2} sx={{ mt: 1 }}>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Model</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={model}
                label="Model"
                onChange={handleChange}
                sx={{ fontWeight: "bold" }}
              >
                <MenuItem value={"1"} sx={{ fontWeight: "bold" }}>Model-1</MenuItem>
                <MenuItem value={"2"} sx={{ fontWeight: "bold" }}>Model-2</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          </Grid>
        {model &&
        <>
          <SubTitleTypography>Sınıflar</SubTitleTypography>
           <hr style={{
            background: 'black',
            color: 'black',
            borderColor: 'black',
            height: '3px',
          }}/> 
        </> 
        }
        <Grid container spacing={4} sx={{ m: 1, display: "flex", gap: "1rem"  }}>
        
        {model === "1" && categories2.slice(0,1).map((category : any, index: number ) => (
        <Grid item xs={12}>
          <Typography >{t(category.label)}:</Typography>
          {/* <MuiColorInput value={getColor(index)} onChange={(e: any) => handleChangeColor(e ,category.label)}/> */}
            <Box sx={{width:"267px"}}>
            <FormControl sx={{width:"267px", marginTop:"5px"}}>
              <InputLabel id="demo-simple-select-label">Border Width</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={width}
                label="Age"
                onChange={handleWidthAge}
              >
                <MenuItem value={"2px"}>2 px</MenuItem>
                <MenuItem value={"3px"}>3 px</MenuItem>
                <MenuItem value={"4px"}>4 px</MenuItem>
              </Select>
            </FormControl>
            </Box>
            <Box sx={{width:"267px"}}>
            <FormControl sx={{width:"267px", marginTop:"5px"}}>
              <InputLabel id="demo-simple-select-label">Border Type</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={age}
                label="Age"
                onChange={handleChangeAge}
              >
                <MenuItem value={"Solid"}>Solid</MenuItem>
                <MenuItem value={"Dotted"}>Dotted</MenuItem>
                <MenuItem value={"Dashed"}>Dashed</MenuItem>
                <MenuItem value={"Double Border"}>Double Border</MenuItem>
              </Select>
            </FormControl>
            </Box>
        </Grid>
        ))}

        {model === "2" && categories2.slice(0,2).map((category : any, index: number) => (
        <Grid item xs={12}>
          <Typography>{t(category.label)}:</Typography>
          {/* <MuiColorInput value={ getColor(index)} onChange={(e: any) => handleChangeColor(e, category.label)}/> */}
          <Box sx={{width:"267px"}}>
            <FormControl sx={{width:"267px", marginTop:"5px"}}>
              <InputLabel id="demo-simple-select-label">Border Width</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={width}
                label="Age"
                onChange={handleWidthAge}
              >
                <MenuItem value={"2px"}>2 px</MenuItem>
                <MenuItem value={"3px"}>3 px</MenuItem>
                <MenuItem value={"4px"}>4 px</MenuItem>
              </Select>
            </FormControl>
            </Box>
            <Box sx={{width:"267px"}}>
            <FormControl sx={{width:"267px", marginTop:"5px"}}>
              <InputLabel id="demo-simple-select-label">Border Type</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={age}
                label="Age"
                onChange={handleChangeAge}
              >
                <MenuItem value={"Solid"}>Solid</MenuItem>
                <MenuItem value={"Dotted"}>Dotted</MenuItem>
                <MenuItem value={"Dashed"}>Dashed</MenuItem>
                <MenuItem value={"Double Border"}>Double Border</MenuItem>
              </Select>
            </FormControl>
            </Box>

        </Grid>
        ))}

        </Grid>
          <Button 
            onClick={saveSettings} 
            variant="outlined"
            disabled={!valid} 
            sx={{
                mt: 5,
                color: theme.palette.primary.main,
                borderColor: theme.palette.primary.main,
                '&:hover': {
                borderColor: theme.palette.primary.main,
                },
                textTransform: 'none',
                fontSize:'small'
            }}>
            {t("Save")}
          </Button>    
    </Box>
    </>
  )
};
