import { FC, useEffect, useState } from 'react';
import Box from "@mui/material/Box";
import {Button, useTheme, Modal, Card, IconButton, CircularProgress } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { YKIList } from './YKIList/YKIList';
import { cloneDeep } from 'lodash';
import {useTranslation} from "react-i18next";
import { BroadcastSettingsTable } from './BroadcastSettingsTable';
import { AlertMessage } from '../../components/Alert/Alert';
import { model, modelMap, pipeline, user, userMap, yki, ykiMap } from '../../types/Types';

import { api } from "../../utils/api";

export const BroadcastSettings: FC = () => {

  const { t } = useTranslation("common");
  const theme = useTheme();

  const [yki, setYki] = useState<string | number>();
  const [model, setModel] = useState<string | number>();
  const [pipelines, setPipelines] = useState<pipeline[]>([]);
  const [clonePipelines, setClonePipelines] = useState<pipeline[]>([]);
  const [users, setUsers] = useState<user[]>([]);
  const [ykis, setYkis] = useState<yki[]>([]);
  const [models, setModels] = useState<model[]>([]);
  const [pipelinesLoading, setPipelinesLoading] = useState(false);
  const [usersLoading, setUsersLoading] = useState(false);
  const [ykisLoading, setYkisLoading] = useState(false);
  const [modelLoading, setModelLoading] = useState(false);
  const [refreshLoading, setRefreshLoading] = useState([false,false]);
  const [changeOnMap, setChangeOnMap] = useState(false);
  const [userType, setUserType] = useState(0);
  const [userMap, setUserMap] = useState<userMap[]>([]);
  const [ykiMap, setYkiMap] = useState<ykiMap[]>([]);
  const [modelMap, setModelMap] = useState<modelMap[]>([]);
  const [modalOpen, setModalOpen] =useState(false);
  const [alertSuccess, setAlertSuccess] = useState(false);
  const [alertError, setAlertError] = useState(false);
  const [alertcontent, setalertcontent] = useState('');

  useEffect(() => {
    getPipelines();
    getUsers();
    getYKIs();
    getModels();
  }, []);

  useEffect(() => {
    if(pipelines.length !== 0) setClonePipelines(pipelines);
  }, [pipelines])

  const getPipelines = () => {
    setPipelinesLoading(true);
    const ykiMapArr : ykiMap[] = [];
    const userMapArr: userMap[] = [];
    const modelMapArr: modelMap[] = [];

    api.base("/drone/get_pipelines")
    .then((response: any) => {
      const responsePipeline = response.data.map((pipeline: pipeline, index: number) => {
        ykiMapArr.push({pipeline_id: pipeline.id, yki_id: pipeline.yki_id, model_id: pipeline.model_id });
        userMapArr.push({pipeline_id: pipeline.id, managers: [...pipeline.managers]});
        modelMapArr.push({pipeline_id: pipeline.id, model_id: pipeline.model_id});
			return { key: index, ...pipeline };
		});

    setYkiMap(ykiMapArr)
    setUserMap(userMapArr)
    setModelMap(modelMapArr)
    setPipelines(responsePipeline)
	  })
	  .catch((error) => {
      setalertcontent(t("Failed to get users broadcasts, error connecting to server!"));
      setAlertError(true);
      const timer = setTimeout(() => {
          setAlertError(false)
      }, 2000);
      return () => clearTimeout(timer);
	  })
	  .finally(() => {
	  	setPipelinesLoading(false);
	  });
	}

	const getUsers = () => {
    setUsersLoading(true);
    api.base.get("/drone/get_users")
    .then((response: any) => {
        const responseUsers = response.data.map((user: user, index: number) => {
        return { key: index, ...user };
      });
      setUsers(responseUsers);
    })
    .catch((error) => {
      setalertcontent(t("Failed to get users, error connecting to server!"));
      setAlertError(true);
      const timer = setTimeout(() => {
          setAlertError(false)
      }, 2000);
      return () => clearTimeout(timer);
    })
    .finally(() => {
      setUsersLoading(false);
    });
	}

	const getYKIs = () => {
    setYkisLoading(true);
			api.base.get("/drone/get_yki")
			.then((response) => {
				setYkis(response.data);
			})
			.catch((error) => {
        setalertcontent(t("Failed to get UAVs, error connecting to server!)"));
        setAlertError(true);
        const timer = setTimeout(() => {
            setAlertError(false)
        }, 2000);
        return () => clearTimeout(timer);

			})
			.finally(() => {
				setYkisLoading(false);
			});
	}

  const handleDeleteYKI = async (yki_id: number) => {
    await api.base.delete('/drone/delete_yki', { data: { yki_id } })
    getYKIs()
  }

	const refreshPipeline = (pipeline_id: number, analysis_model_uri: string) => () => {
		const refresh_loading = [...refreshLoading];
	 	refresh_loading[pipeline_id - 1] = true;
    setRefreshLoading(refreshLoading);
  	api.base.post("/drone/refresh_pipeline", { pipeline_id, analysis_model_uri })
		.then(() => {
      setalertcontent(t(`Stream has been refreshed:`) + pipeline_id);
      setAlertSuccess(true);
      const timer = setTimeout(() => {
        setAlertSuccess(false)
      }, 2000);
      return () => clearTimeout(timer);

		})
		.catch((error) => {
      setalertcontent(t(`Failed to refresh Stream:`)+ pipeline_id + `!`);
      setAlertError(true);
      const timer = setTimeout(() => {
          setAlertError(false)
      }, 2000);
      return () => clearTimeout(timer);

		})
		.finally(() => {
			const refresh_loading = [...refreshLoading];
			refresh_loading[pipeline_id - 1] = false;
			setRefreshLoading(refreshLoading);
		});
	}

  const updateUserMap = (pipeline_id: number,managers: string[]) => {
    setChangeOnMap(true);
    const cloneUserMap = cloneDeep(userMap);
      var index= -1;
      for(let i = 0; i<cloneUserMap.length ; i++) {
        if(cloneUserMap[i].pipeline_id === pipeline_id) index = i;
        else continue;
      }
      if(index !== -1){
        cloneUserMap[index].managers = [...managers]
      }
      setUserMap(cloneUserMap);
  }
  const updateYkiMap = (pipeline_id: number,yki_id: number) => {
    setChangeOnMap(true);
    const cloneYkiMap = cloneDeep(ykiMap);
    var index= -1;
    for(let i = 0; i<cloneYkiMap.length ; i++) {
      if(cloneYkiMap[i].pipeline_id === pipeline_id) index = i;
      else continue;
    }
    if(index !== -1){
      cloneYkiMap[index].yki_id = yki_id;
    }
    setYkiMap(cloneYkiMap);
  }

  const updateModelMap = (pipeline_id: number,model_id: number) => {
    setChangeOnMap(true);
    const cloneYkiMap = cloneDeep(ykiMap);
    var index= -1;
    for(let i = 0; i<cloneYkiMap.length ; i++) {
      if(cloneYkiMap[i].pipeline_id === pipeline_id) index = i;
      else continue;
    }
    if(index !== -1){
      cloneYkiMap[index].model_id = model_id;

    }
    setYkiMap(cloneYkiMap);
  }


	const updatePipelines = () => {
    setPipelinesLoading(true);
			api.base.put("/drone/set_pipeline", {
				// user_map only available for admins
				user_map: userType === 0 ? userMap : undefined,
				yki_map: ykiMap,
			})
			.then(() => {
        setChangeOnMap(false)
        setalertcontent(t("Streams are updated!"));
        setAlertSuccess(true);
        getPipelines();
        const timer = setTimeout(() => {
          setAlertSuccess(false)
        }, 2000);
        return () => clearTimeout(timer);
			})
			.catch((error) => {
        setalertcontent(t("Failed to update streams!"));
        setAlertError(true);
        const timer = setTimeout(() => {
            setAlertError(false)
        }, 2000);
        return () => clearTimeout(timer);

			})
			.finally(() => {
        setPipelinesLoading(false)
			});
	}

  const getModels = () => {
    setYkisLoading(true);
			api.base.get("/ai_model/get_models")
			.then((response) => {
				setModels(response.data);
			})
			.catch((error) => {
        setalertcontent(t("Failed to get models, error connecting to server!)"));
        setAlertError(true);
        const timer = setTimeout(() => {
            setAlertError(false)
        }, 2000);
        return () => clearTimeout(timer);

			})
			.finally(() => {
				setModelLoading(false);
			});
	}

  const updateSelectedManagers = (value : string[] , id: number) => {
    const clonePipeline = cloneDeep(clonePipelines);
    var index= -1;
    for(let i = 0; i<clonePipeline.length ; i++) {
      if(clonePipelines[i].id === id) index = i;
      else continue;
    }
    if(index !== -1){
      clonePipeline[index].managers = value
    }
    setClonePipelines(clonePipeline);
	}

  const updateSelectedConnectedYKI = (value :  number, id: number) => {
    const clonePipeline = cloneDeep(clonePipelines);
    var index= -1;
    for(let i = 0; i<clonePipeline.length ; i++) {
      if(clonePipelines[i].id === id) index = i;
      else continue;
    }
    if(index !== -1){
      clonePipeline[index].yki_id = value
    }
    setClonePipelines(clonePipeline);
	}

  const updateSelectedModels = (value : number , id: number) => {
    const clonePipeline = cloneDeep(clonePipelines);
    var index= -1;
    for(let i = 0; i<clonePipeline.length ; i++) {
      if(clonePipelines[i].id === id) index = i;
      else continue;
    }
    if(index !== -1){
      clonePipeline[index].model_id = value
    }
    setClonePipelines(clonePipeline.map((e) => {
      if(e.model_id === value && e.id !== id) return {...e, model_id : -1}
      else return e;
    }));
	}


  return(
    <>
      <Box>
        <Modal
          open={modalOpen}
          aria-labelledby="parent-modal-title"
          aria-describedby="parent-modal-description">
          <Card sx={{
            display: 'flex',
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            stroke: 'none',
            padding: 3,
          }}>
            <Box sx={{ mt:3 }}> <YKIList ykis={ykis} setYkis={setYkis} modalOpen={modalOpen} setModalOpen={setModalOpen} onYKIDelete={handleDeleteYKI}/></Box>
            <IconButton sx={{ m: 1 }} onClick={() => setModalOpen(false)}> <CloseIcon /> </IconButton>
          </Card>
        </Modal>
        <Box sx={{ mt: 3}}>
          <AlertMessage alertSuccess={alertSuccess} alertError={alertError} alertcontent={alertcontent} />
           <BroadcastSettingsTable
            clonePipelines={clonePipelines}
            users={users}
            ykis={ykis}
            models={models}
            updateYkiMap={updateYkiMap}
            updateUserMap={updateUserMap}
            updateModelMap={updateModelMap}
            updateSelectedManagers={updateSelectedManagers}
            updateSelectedConnectedYKI={updateSelectedConnectedYKI}
            updateSelectedModels={updateSelectedModels}
            setYki={setYki}
            setModels={setModel}
            refreshPipeline={refreshPipeline}/>
        </Box>
          <Box sx={{m: 5, display:'flex' }}>
            <Button
              sx={{
                display: "inline-block",
                width: "auto",
                textTransform: "none",
                fontSize: "small",
                m:2,
                backgroundColor: theme.palette.primary.main,
                '&:hover': {
                  backgroundColor: theme.palette.primary.main,
                }}}
                variant="contained"
                fullWidth
                onClick={() => setModalOpen(true)}>
                {t("UAV List")}
            </Button>
            <Button
              sx={{
                display: "inline-block",
                width: "auto",
                fontSize: "small",
                m:2,
                textTransform: "none",
                backgroundColor: `${pipelinesLoading}` === 'true' ? '#d3d3d3': theme.palette.primary.main,
                '&:hover': {
                  backgroundColor: theme.palette.primary.main,
                }}}
                variant="contained"
                fullWidth
                disabled={clonePipelines.filter((e) => e.model_id === -1).length > 0}
                onClick={updatePipelines}>
                {pipelinesLoading ? <CircularProgress sx={{ color: 'white'}} size={20}/> : t("Save")}
            </Button>
          </Box>
      </Box>
    </>
)
};
