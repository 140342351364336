import { css } from '@emotion/react'
import styled from '@emotion/styled/macro'
import { useState } from 'react'
import StreamSource from './StreamSource'
import { TStreamData } from '../hooks'
import Box from "@mui/material/Box";
import {StreamFilters} from "./StreamFilters";

const ScStreamRightPanelContent = styled.div`
    background: white;
    overflow: auto;
    padding: 10px 10px 10px 10px;
    border: 1px solid #1976d2;
    border-radius: 4px;
    opacity: 1;
    transition: opacity 0.2s;
    box-shadow: -4px 2px 3px -2px rgba(0, 0, 0, 0.2);
    //width: 490px;
    height: 100%;
    align-items: flex-start;
    align-content: flex-start;
    justify-content: flex-start;
    display: flex;
    flex-direction: row;
    flex-flow: wrap;

    //& div:not(:first-child) {
    //  margin-top: 10px;
    //}
`

const ScIncreaseColumnNumberButton = styled.button`
    background: #1976d2;
    border: 1px solid #1976d2;
    border-radius: 4px;
    color: white;

    position: absolute;
    //top: -19px;
    padding: 1px 4px 3px 4px;
    top: 2px;
    left: -20px;
    
    cursor: pointer;
`
const ScDecreaseColumnNumberButton = styled.button`
    background: #1976d2;
    border: 1px solid #1976d2;
    border-radius: 4px;
    color: white;

    position: absolute;
    //top: -19px;
    padding: 1px 4px 3px 4px;
    top: 2px;
    left: 2px;

    cursor: pointer;
`
const ScClearColumnNumberButton = styled.button`
    background: #1976d2;
    border: 1px solid #1976d2;
    border-radius: 4px;
    color: white;

    position: absolute;
    //top: -19px;
    padding: 1px 4px 3px 4px;
    top: 2px;
    right: 2px;

    cursor: pointer;
`

const ScStreamRightPanel = styled.div<{ $columnNumber: number }>`
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  transition: width 0.4s;
  //margin-top: 20px;
  /* padding-bottom: 80px; */

  ${props => css`
    ${ScStreamRightPanelContent} {
      opacity: ${props.$columnNumber === 0 ? 0 : 1};
    }
    width: ${props.$columnNumber * 250}px;
  `}
`

type TStreamRightPanelProps = {
  streams: TStreamData[];
  onDoubleClick: (props: { id: number, url: string, name: string }) => void
}

export type TAktiflik = "-" | "aktif" | "pasif";
export type TIzlenme = "-" | "izleniyor" | "izlenmiyor";
export type TFilters = {
  name: string;
  isActive: TAktiflik;
  onLive: TIzlenme;
}

const StreamRightPanel = ({ streams, onDoubleClick }: TStreamRightPanelProps) => {
  const [columnNumber, setColumnNumber] = useState(2)
  const [filters, setFilters] = useState<TFilters>({
    name: "",
    isActive: "-",
    onLive: "-",
  })

  return (
    <ScStreamRightPanel $columnNumber={columnNumber}>
      <ScStreamRightPanelContent>
        <StreamFilters filters={filters} setFilters={setFilters} />
        {streams.filter(s => {
          let r = true;

          r = r && `${s.ID}: ${s.NAME}`.includes(filters.name);
          r = r && (
            filters.isActive === '-' ||
            (filters.isActive === 'aktif' && s.IS_ACTIVE === 1) ||
            (filters.isActive === 'pasif' && s.IS_ACTIVE === 0)
          )
          r = r && (
            filters.onLive === '-' ||
            (filters.onLive === 'izleniyor' && s.ON_LIVE === 1) ||
            (filters.onLive === 'izlenmiyor' && s.ON_LIVE === 0)
          )

          return r;
        }).map(({ ID, IS_ACTIVE, ON_LIVE, NAME, THUMBNAIL, URL }) => (
          <Box sx={{
            display: "flex",
            flexBasis: `${100 / columnNumber}%`,
            maxWidth: `${100 / columnNumber}%`,
            padding: "10px"
          }}>
            <StreamSource
              key={ID}
              id={ID}
              is_active={IS_ACTIVE}
              on_live={ON_LIVE}
              name={NAME}
              thumbnail={THUMBNAIL}
              url={URL}
              onDoubleClick={onDoubleClick}
            />
          </Box>
        ))}
      </ScStreamRightPanelContent>
      {columnNumber < 3 && <ScIncreaseColumnNumberButton onClick={() => setColumnNumber(columnNumber + 1)}>{'<'}</ScIncreaseColumnNumberButton>}
      {columnNumber > 0 &&<ScDecreaseColumnNumberButton onClick={() => setColumnNumber(columnNumber - 1)}>{'>'}</ScDecreaseColumnNumberButton>}
      {columnNumber !== 0 &&<ScClearColumnNumberButton onClick={() => setColumnNumber(0)}>{'x'}</ScClearColumnNumberButton>}
    </ScStreamRightPanel>
  )
}

export default StreamRightPanel