import { FC } from "react";
import Box from "@mui/material/Box";
import { TableContainer, Paper, Table, TableHead, TableRow, TableCell, tableCellClasses, styled, TableBody, TextField, IconButton } from "@mui/material";
import { useTranslation } from "react-i18next";
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import { yki } from "../../../types/Types";

type YKIListTableComponentProps = {
    ykis : yki[],
    setIndexName : (index: number, value: string) => void,
    setIndexUrl: (index: number, value: string) => void,
    deleteRows: (value: number) => void,
};

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      fontSize: 16,
      fontWeight: 'bold'
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 12,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));


export const YKIListTable: FC<YKIListTableComponentProps> = ({
    ykis,
    setIndexName,
    setIndexUrl,
    deleteRows
}) => {
  const {t} = useTranslation("common");
  return(
    <>
      <Box sx={{
          display:'contents',
          flexDirection:'column',
          justifyContent:'space-between',
          alignItems:'flex-end',
          width:'100%'
        }}>
        <TableContainer component={Paper}>
            <Table sx={{  height: "100%" }} aria-label="customized table">
                <TableHead>
                <TableRow>
                    <StyledTableCell>{t("Order")}</StyledTableCell>
                    <StyledTableCell align="center">{t("Name")}</StyledTableCell>
                    <StyledTableCell align="center">{t("URL")}</StyledTableCell>
                    <StyledTableCell align="center">{t("Delete")}</StyledTableCell>
                </TableRow>
                </TableHead>
                <TableBody>
                {ykis.map((row,index) => (
                    <StyledTableRow key={row.id}>
                    <StyledTableCell component="th" scope="row">
                      {index + 1}
                    </StyledTableCell>
                    <StyledTableCell component="th" scope="row">
                      {row.name ? row.name : <TextField id="outlined-basic" variant="outlined" onChange={(e) => setIndexName(index,e.target.value)}/>}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {row.url ? row.url : <TextField id="outlined-basic" variant="outlined" onChange={(e) => setIndexUrl(index,e.target.value)}/>}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <IconButton onClick={() => {deleteRows(row.id ? row.id : -1)}}><DeleteRoundedIcon /></IconButton></StyledTableCell>
                    </StyledTableRow>
                ))}
                </TableBody>
            </Table>
        </TableContainer>
      </Box>
    </>

  )
};


