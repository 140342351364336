import styled from '@emotion/styled';
import { Alert, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';

const ScPlaceholderImageDetectionUpload = styled.div``

const ScUploadBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  padding: 12px 4px;
  margin-top: 40px;
  border: 2px solid #1976d2;
  background: white;

  h3, p { margin: 0 }

  p { font-size: 14px; }
`

const PlaceholderImageDetectionUpload = () => {
  const { t } = useTranslation("common");

  return (
    <ScPlaceholderImageDetectionUpload>
      <Alert severity="warning">{t("This page is under development.")}</Alert>
      <ScUploadBox>
        <h3>{t('Upload Image')}</h3>
        <p>{t('Drag and drop your files into this area or')}</p>
        <Button variant='contained' disabled>{t('Select a file')}</Button>
      </ScUploadBox>
    </ScPlaceholderImageDetectionUpload>
  )
}

export default PlaceholderImageDetectionUpload