import React, { FC, Fragment, useEffect, useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, List, ListItem, ListItemText, Divider } from '@mui/material';
import { useTranslation } from "react-i18next";

import{ api } from "../../../utils/api";

interface Class {
  id: number;
  name: string;
}
interface ClassThreshold {
  class_id: number;
  threshold: number;
}
interface Model {
  id: number;
  model_name: string;
  model_url: string;
  classes: ClassThreshold[];
}
interface ModelDetailDialogProps {
  open: boolean;
  model: Model;
  classes: Class[];
  onClose: () => void;
}

const ModelDetailDialog: FC<ModelDetailDialogProps> = ({ open, model, classes, onClose }) => {
  const { t } = useTranslation("common");

  const [classOptions, setClassOptions] = useState<Class[]>([]);

  useEffect(() => {
    fetchClasses();
  }, []);

  const fetchClasses = async () => {
    try {
      const response = await api.base.get("/ai_class/get_classes");
      setClassOptions(response.data);
    } catch (error) {
      console.error('Error fetching classes:', error);
    }
  };

  const getClassNameById = (class_id: number) => {
    const foundClass = classOptions.find(cls => cls.id === class_id);
    return foundClass ? foundClass.name : 'Unknown';
  };

  return (

    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>{t("Model Detayı")}</DialogTitle>
      <DialogContent>
        <List>
          <ListItem>
            <ListItemText primary={t("Model Name")} secondary={model.model_name} />
          </ListItem>
          <Divider component="li" />
          <ListItem>
            <ListItemText primary={t("Model URL")} secondary={model.model_url} />
          </ListItem>
          <Divider component="li" />
          {model.classes && model.classes.map((cls, index) => (
            <Fragment key={index}>
              <ListItem>
                <ListItemText primary={`${t("Class Type")} (${index + 1})`} secondary={getClassNameById(cls.class_id)} />
              </ListItem>
              <ListItem>
                <ListItemText primary={t("Threshold")} secondary={cls.threshold} />
              </ListItem>
              <Divider component="li" />
            </Fragment>
          ))}
        </List>
      </DialogContent>
          <DialogActions>
            <Button onClick={onClose}>{t("Close")}</Button>
          </DialogActions>
    </Dialog>
    
  );
};
export default ModelDetailDialog;

 