import { FC, useState } from 'react';
import { FileUpload } from '../../components/FileUpload/FileUpload';
import { Button, useTheme } from '@mui/material';
import Box from "@mui/material/Box";
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import {useTranslation} from "react-i18next";

import{ api } from "../../utils/api";

export const ImageDetectionUpload: FC = () => {
  const { t } = useTranslation("common");
  const theme = useTheme();

  const [uploaded_file, setUpload_file] = useState(null); 
  const [loading, setLoading] = useState(false);
  const [alertError, setAlertError] = useState(false);
  const [alertcontent, setalertcontent] = useState('');

  const onClick = () => {
    setLoading(true);
    const data = new FormData();
    if(uploaded_file) data.append("image", uploaded_file);

    api.base.post("/upload/image_detection", data)
    .then((response) => {
        if(response.data) {
            //message.success("Analiz başarıyla tamamlandı!");
            //history.push("/image-detection", { image: uploaded_file, bboxes: response.data });
        }
        //else message.info("Bu fotoğrafta herhangi bir nesne tespit edilemedi!");
    })
    .catch((error: any) => {
        setalertcontent("Unable to access Image service, possible server error!");
        setAlertError(true);
        const timer = setTimeout(() => {
            setAlertError(false)
            }, 4000);
            return () => clearTimeout(timer);
    })
    .finally(() => {
        setLoading(false)
    });
}

const setUploadedFiles = (file: any) => {
    setUpload_file(file)
}
  return(
    <>
    <Box>
        {alertError && 
                <Alert severity="error" sx={{mb: 2}}>
                    <AlertTitle sx={{fontSize: "24px"}}>{t("Error!")}</AlertTitle>
                    {alertcontent}
                </Alert>
        }

        <FileUpload
            accept="image/jpeg, image/png"
            label="Image"
            setUploadedFiles={setUploadedFiles}
        />
        <Box sx={{
            display: 'grid',
            marginTop: '2%'
        }}>
            {uploaded_file && 
                <Button 
                    onClick={onClick} 
                    sx={{
                        color: theme.palette.primary.main,
                        textTransform: "none",
                        fontSize:"small"
                    }}>
                {t("Bring Detections")} 
                </Button>}
        </Box>
    </Box>
</>  
)
};
