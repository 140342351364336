import styled from '@emotion/styled/macro'
import { Fullscreen, Pause, PlayArrow, Settings } from '@mui/icons-material'
import { Button } from '@mui/material'
import { useTranslation } from 'react-i18next'

const ScMenu = styled.div`
  display: flex;
  background: #f1f7ff;
  border-top: 1px solid rgba(25, 118, 210, 0.5);
  justify-content: space-between;
  width: 100%;
  transition: opacity ease-in-out 0.1s;
  opacity: 0;
`

const ScControlsOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-end;
  z-index: 101;

  &:hover {
    ${ScMenu} {
      opacity: 1;
    }
  }
`

const ScButtonsWrapper = styled.div`
  display: flex;
  align-items: stretch;
`

type TControlsOverlayProps = {
  isPaused: boolean;
  onFullscreenButtonClick: () => void;
  onPlayPauseButtonClick: () => void;
  onSuperResolutionClick: () => void;
  // onSettingsButtonClick: () => void;
}

const ControlsOverlay = ({
  isPaused,
  onFullscreenButtonClick,
  onPlayPauseButtonClick,
  // onSettingsButtonClick
  onSuperResolutionClick
}: TControlsOverlayProps) => {
  const {t} = useTranslation("common")

  return (
    <ScControlsOverlay>
      <ScMenu>
        <ScButtonsWrapper>
          <Button sx={{ borderRadius: 0, borderTop: "none", borderBottom: "none" }} variant='outlined' onClick={onPlayPauseButtonClick}>
            {isPaused ? <PlayArrow /> : <Pause />}
          </Button>
          <Button disabled>
            {t('LIVE')}
          </Button>
        </ScButtonsWrapper>
        <ScButtonsWrapper>
          <Button sx={{ borderRadius: 0, borderTop: "none", borderBottom: "none" }} variant='outlined'  onClick={onSuperResolutionClick}>
            SR
          </Button>
          <Button sx={{ borderRadius: 0, borderTop: "none", borderBottom: "none" }} variant='outlined'  onClick={onFullscreenButtonClick}>
            <Fullscreen />
          </Button>
          {/* <Button sx={{ borderRadius: 0, borderTop: "none", borderBottom: "none" }} variant='outlined'  onClick={onSettingsButtonClick}>
            <Settings />
          </Button> */}
        </ScButtonsWrapper>
      </ScMenu>
    </ScControlsOverlay>
  )
}

export default ControlsOverlay
