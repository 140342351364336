import { FC, useState } from "react";
import { Typography } from "@mui/material";
import Alert from "@mui/material/Alert";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useTranslation } from "react-i18next";
import SavedUserInfoModal from "./SavedUserInfoModal/SavedUserInfoModal";
import { SetUser } from "../../../types/Types";
import { AlertMessage, EAlertType, TAlert } from '../../../components/Alert/Alerttwo';

import{ api } from "../../../utils/api";

type UserSignUpProps = {
  modalOpen: boolean;
  user?: SetUser;
};

export const UserSignUp: FC<UserSignUpProps> = ({ modalOpen, user }) => {
  const { t } = useTranslation("common");

  const [samaccountname, setSamaccountname] = useState<string>(user ? user.SAMACCOUNTNAME : "" );
  const [pensionnumber, setPensionnumber] = useState<string>(user ? user.PENSIONNUMBER : "" );
  const [givenname, setGivenname] = useState<string>(user ? user.GIVENNAME : "" );
  const [mail, setMail] = useState<string>(user ? user.MAIL : "" );
  const [sn, setSn] = useState<string>(user ? user.SN : "" );
  const [dn, setDn] = useState<string>(user ? user.DN : "" );
  const [extensionAttribute9, setExtensionAttribute9] = useState<string>(user ? user.EXTENSIONATTRIBUTE9 : "" );
  const [telephonenumber, setTelephonenumber] = useState<string>(user ? user.TELEPHONENUMBER : "" );
  const [title, setTitle] = useState<string>(user ? user.TITLE : "" );
  const [displayname, setDisplayname] = useState<string>(user ? user.DISPLAYNAME : "" );
  const [description, setDescription] = useState<string>(user ? user.DESCRIPTION : "" );
  const [yki_start, setYki_start] = useState<string>("");
  const [yki_end, setYki_end] = useState<string>("");
  const [user_type, setUser_type] = useState<number>(user ? user.TYPE : 0);
  const [signUpModalOpen, setSignUpModalOpen] = useState(false);
  const [user_created, setUser_created] = useState(false);
  const [password, setPassword] = useState<string>(user ? user.PASSWORD : "" );
  const [alert, setAlert] = useState<TAlert>();

  const submitForm = () => {

    if(!samaccountname) {
      setAlert({type : EAlertType.error, content : t("ID Number cannot be left blank!"), duration : 2000});
      return;
    }
    if(!pensionnumber) {
      setAlert({type : EAlertType.error, content : t("Pension Number cannot be left blank!"), duration : 2000});
      return;
    }
    if(!givenname) {
      setAlert({type : EAlertType.error, content : t("Username cannot be left blank!"), duration : 2000});
      return;
    }
    if(!sn) {
      setAlert({type : EAlertType.error, content : t("User surname cannot be left blank!"), duration : 2000});
      return;
    }
    if(!title) {
      setAlert({type : EAlertType.error, content : t("User title cannot be left blank!"), duration : 2000});
      return;
    }
    if(!description) {
      setAlert({type : EAlertType.error, content : t("User description cannot be left blank!"), duration : 2000});
      return;
    }
    api.base.post("/account/sign_up", {
      samaccountname: samaccountname,
      pensionnumber: pensionnumber,
      givenname: givenname,
      sn: sn,
      mail: mail,
      dn: dn,
      extensionAttribute9: extensionAttribute9,
      telephonenumber: telephonenumber,
      title: title,
      displayname: displayname,
      yki_start: yki_start,
      yki_end: yki_end,
      user_type: user_type,
      description: description,
    })
      .then((response) => {
        setSamaccountname(response.data.samaccountname);
        setPassword(response.data.password);
        setUser_created(true);
        setAlert({type : EAlertType.success, content : t("User created successfully!"), duration : 2000});
        setSignUpModalOpen(true);
      })
      .catch((error) => {
        setAlert({
          type : EAlertType.error,
          content : error.message || t("Unable to create user, failed to connect to server!"),
          duration : 2000,
        });
      })
      .finally(() => {});
  };

  const saveUser = () => {
    const userr = { ...user };
    userr.TYPE = user?.TYPE;
    userr.USER_TYPE = user_type;
    console.log(user?.TYPE)
    console.log(user)


    api.base.post("/account/set_user", {
      ...userr,
      DESCRIPTION: description,
      DISPLAYNAME: displayname,
      DN: dn,
      EXTENSIONATTRIBUTE9: extensionAttribute9,
      GIVENNAME: givenname,
      MAIL: mail,
      PASSWORD: password,
      PENSIONNUMBER: pensionnumber,
      SAMACCOUNTNAME: samaccountname,
      SN: sn,
      TELEPHONENUMBER: telephonenumber,
      TITLE: title,
      yki_start: yki_start,
      yki_end: yki_end,
      TYPE : user_type    })
      .then(() => {
        if (userr.TYPE === 0)
          setAlert({type : EAlertType.success, content : t("The user was given 1-year YKI monitoring permission! User edited successfully!"), duration : 2000})
        else setAlert({type : EAlertType.success, content : t("User edited successfully!"), duration : 2000})
        setSignUpModalOpen(true);
        
      })
      .catch((error) => {
        setAlert({type : EAlertType.error, content : t("Unable to edit user, failed to connect to server!"), duration : 2000})
      })
      .finally(() => {});
  };

  return (
    <>
      {modalOpen &&
        user &&
        user.SAMACCOUNTNAME === localStorage.getItem("samaccountname") && (
          <Alert severity="warning">
            - This user is you, be careful when editing!
          </Alert>
        )}
      {/* {!modalOpen && (
        <Alert severity="warning" sx={{ fontSize: "small" }}>
          - This screen creates a user using the <u>ID number</u>, the account
          information (username, password) of the created user will be specified
          after this screen.
        </Alert>
      )} */}

      <Box
        sx={{
          marginTop: 2,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: "primary.main" }}>
          <LockOutlinedIcon />
        </Avatar>
        <AlertMessage alert={alert} onClose={() => setAlert(undefined)}/>
        <Typography sx={{ fontSize: "18px" }}>
          {" "}
          {modalOpen ? t("Edit User") : t("Create User")}{" "}
        </Typography>
        <Box component="form" noValidate onSubmit={console.log} sx={{ mt: 3 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                id="id"
                label={t("ID Number")}
                required
                fullWidth
                value={samaccountname}
                onChange={({ target: { value } }) => setSamaccountname(value)}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                id="pensionnumber"
                label={t("Pension Number")}
                required
                fullWidth
                value={pensionnumber}
                onChange={({ target: { value } }) => setPensionnumber(value)}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                id="firstName"
                label={t("First Name")}
                required
                fullWidth
                value={givenname}
                onChange={({ target: { value } }) => setGivenname(value)}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                id="lastName"
                label={t("Last Name")}
                required
                fullWidth
                value={sn}
                onChange={({ target: { value } }) => setSn(value)}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                id="mail"
                label={t("E-mail")}
                fullWidth
                value={mail}
                onChange={({ target: { value } }) => setMail(value)}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                id="dn"
                label={t("OU")}
                fullWidth
                value={dn}
                onChange={({ target: { value } }) => setDn(value)}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                id="extensionAttribute9"
                label={t("Number")}
                fullWidth
                value={extensionAttribute9}
                onChange={({ target: { value } }) =>
                  setExtensionAttribute9(value)
                }
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                id="telephonenumber"
                label={t("Phone Number")}
                fullWidth
                value={telephonenumber}
                onChange={({ target: { value } }) => setTelephonenumber(value)}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                id="title"
                label={t("Title")}
                fullWidth
                required
                value={title}
                onChange={({ target: { value } }) => setTitle(value)}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                id="displayName"
                label={t("First Name/ Last Name/ Title")}
                fullWidth
                value={displayname}
                onChange={({ target: { value } }) => setDisplayname(value)}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <Select
                id="type"
                fullWidth
                required
                value={user_type}
                onChange={({ target: { value } }) => {
                  setUser_type(value as number);
                }}
              >
                <MenuItem value={0}>Yönetici</MenuItem>
                <MenuItem value={2}>İzleyici</MenuItem>
              </Select>
            </Grid>

            <Grid item xs={12} sm={6} display="flex">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={["DatePicker"]}>
                  <DatePicker
                    sx={{ fontSize: "12px" }}
                    label={t("UAV Watch Time")}
                    onChange={(e: any) => {
                      const s = new Date(e);
                      const formattedDateS = `${s.getDate()}/${
                        s.getMonth() + 1
                      }/${s.getFullYear()}`;
                      setYki_start(formattedDateS);
                    }}
                  />
                </DemoContainer>
              </LocalizationProvider>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={["DatePicker"]}>
                  <DatePicker
                    sx={{ fontSize: "12px" }}
                    label={t("UAV Watch Time")}
                    onChange={(e: any) => {
                      const s = new Date(e);
                      const formattedDateS = `${s.getDate()}/${
                        s.getMonth() + 1
                      }/${s.getFullYear()}`;
                      setYki_end(formattedDateS);
                    }}
                  />
                </DemoContainer>
              </LocalizationProvider>
            </Grid>

            <Grid item xs={12}>
              <TextField
                id="duty"
                label={t("Duty")}
                fullWidth
                required
                value={description}
                onChange={({ target: { value } }) => setDescription(value)}
              />
            </Grid>
          </Grid>

          <Button
            fullWidth
            variant="contained"
            onClick={() => {
              if (modalOpen) saveUser();
              else submitForm();
            }}
          >
            {modalOpen ? t("Edit") : t("Create")}
          </Button>

          <SavedUserInfoModal
            signUpModalOpen={signUpModalOpen}
            user_created={user_created}
            samaccountname={samaccountname}
            password={password}
            setSignUpModalOpen={setSignUpModalOpen}
          />
        </Box>
      </Box>
    </>
  );
};
