import React, { FC, useEffect, useState } from 'react';
import { Container, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, IconButton, Button, Box } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import InfoIcon from '@mui/icons-material/Info';
import ModelDialog from './component/ModelDialog';
import ModelDetailDialog from './component/ModelDetailDialog';
import { useTranslation } from "react-i18next";
import { AlertMessage, EAlertType, TAlert } from '../../components/Alert/Alerttwo';

import{ api } from "../../utils/api";

interface Class {
  id: number;
  name: string;
}
interface ClassThreshold {
  model_id: number;
  class_id: number;
  type: string;
  threshold: number;
}
interface Model {
  id: number;
  model_name: string;
  model_url: string;
  classes: ClassThreshold[];
}

export const ModelManagement: FC = () => {
  const { t } = useTranslation("common");

  const [models, setModels] = useState<Model[]>([]);
  const [classes] = useState<Class[]>([]);
  const [currentModel, setCurrentModel] = useState<Model | null>(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [openDetailDialog, setOpenDetailDialog] = useState(false);
  const [alert, setAlert] = useState<TAlert>();

  useEffect(() => {
    fetchModels();
  }, []);
  
  const fetchModels = async () => {
    try {
      const response = await api.base.get("/ai_model/get_models/");
      const modelsData = response.data; 
      setModels(modelsData); 
    } catch (error) {
      console.error('Error fetching models:', error);
    }
  };

  const handleOpenAddDialog = () => {
    setCurrentModel({ id: 0, model_name: '', model_url: '', classes: [] });
    setOpenDialog(true);
  };

  const handleOpenDialog = async (model: Model) => {
    try {
      const thresholdsResponse = await api.base.get(`/ai_class_threshold/get_model_thresholds/${model.id}`);
      setCurrentModel({...model, classes: thresholdsResponse.data});
      setOpenDialog(true);
    } catch (error) {
      console.error('Error fetching models:', error);
    }
  };

  const handleOpenDetailDialog = async (model: Model) => {
    try {
      const thresholdsResponse = await api.base.get(`/ai_class_threshold/get_model_thresholds/${model.id}`);
      setCurrentModel({...model, classes: thresholdsResponse.data});
      setOpenDetailDialog(true);
    } catch (error) {
      console.error('Error fetching models:', error);
    }
  };

  const handleDeleteModel = async (id: number) => {
    console.log(id);
    try {
      const thresholdsResponse = await api.base.get(`/ai_class_threshold/get_model_thresholds/${id}`);
      const classThresholds: ClassThreshold[] = thresholdsResponse.data;
      for(const threshold of classThresholds) {
        const data = {
          model_id: threshold.model_id,
          class_id: threshold.class_id,
        }
        await api.base.delete("/ai_class_threshold/delete_model_threshold", {
          data,
        });
      }
      await api.base.delete("/ai_model/delete_model", {
        data: {
          model_id: id
        }
      });
      fetchModels();
    } catch (error) {
      setAlert({type: EAlertType.error  , content: "Ensure all classes are deleted.", duration : 2000});   
    }
      setAlert({type: EAlertType.success  , content: t("Model successfully deleted!")});
  };

  const handleSubmitDialog = () => {
    setOpenDialog(false);
    setOpenDetailDialog(false);
    fetchModels();
    setAlert({type : EAlertType.success , content : t("Settings updated successfully!"), duration : 1000});
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setOpenDetailDialog(false);
    fetchModels();
  };

  return (

    <Container sx={{ marginTop: '56px' }}>
      <AlertMessage alert={alert} onClose={() => setAlert(undefined)}/>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', my: 2 }}>
        <Button variant="contained" onClick={handleOpenAddDialog}>{t("Add Model")}</Button>
      </Box>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell sx={{textAlign: 'center'}}>Model Adı</TableCell>
              <TableCell sx={{textAlign: 'center'}}>Model URL</TableCell>
              <TableCell sx={{textAlign: 'center'}}>İşlemler</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {models.map((model) => (
              <TableRow key={model.id}>
                <TableCell sx={{textAlign: 'center'}}>{model.model_name}</TableCell>
                <TableCell sx={{textAlign: 'center'}}>{model.model_url}</TableCell>
                <TableCell sx={{textAlign: 'center'}}>
                  <IconButton onClick={() => handleOpenDialog(model)} color="primary">
                    <EditIcon />
                  </IconButton>
                  <IconButton onClick={() => { handleOpenDetailDialog(model)}} color="info">
                    <InfoIcon />
                  </IconButton>
                  <IconButton onClick={() => handleDeleteModel(model.id)} color="error" >
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {openDialog && currentModel && (
        <ModelDialog
          open={openDialog}
          model={currentModel}
          classes={classes}
          onClose={handleCloseDialog}
          onSubmit={handleSubmitDialog}
        />
      )}
      {openDetailDialog && currentModel && (
        <ModelDetailDialog
                open={openDetailDialog}
                model={currentModel}
                onClose={handleCloseDialog} 
                classes={classes}
        />
        )}
    </Container>

  );
};
export default ModelManagement;

