import { FC } from "react";
import Box from "@mui/material/Box";
import { TableContainer, styled, tableCellClasses, TableCell, TableRow, Table, TableHead, TableBody, Paper, Select, OutlinedInput, MenuItem, SelectChangeEvent, Autocomplete, Grow, PaperProps } from "@mui/material";
import { useTranslation } from "react-i18next";
import { model, pipeline, user, yki } from "../../types/Types";
import CircleIcon from '@mui/icons-material/Circle';
import TextField from '@mui/material/TextField';


type BroadcastSettingsTableProps = {
  clonePipelines: pipeline[],
  users: user[],
  ykis: yki[],
  models: model[],
  updateYkiMap: (pipeline_id: number, yki_id: number) => void,
  updateUserMap: (pipeline_id: number, managers: string[]) => void,
  updateModelMap: (pipeline_id: number, model_id: number) => void,
  updateSelectedManagers: (value: string[], id: number) => void,
  updateSelectedConnectedYKI: (value: number, id: number) => void,
  updateSelectedModels: (value: number, id: number) => void,
  setYki: (value: number | string) => void,
  setModels: (value: number | string) => void,
  refreshPipeline: (pipeline_id: number, model_url: string) => () => void
};

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    fontSize: 16,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 12,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export const BroadcastSettingsTable: FC<BroadcastSettingsTableProps> = ({
  clonePipelines,
  users,
  ykis,
  models,
  updateYkiMap,
  updateUserMap,
  updateModelMap,
  updateSelectedManagers,
  updateSelectedConnectedYKI,
  updateSelectedModels,
  setYki,
  setModels
}) => {
  const { t } = useTranslation("common");
  const options = [-1, ...ykis.map(yki => yki.id)];
  
  return (
    <>
      <Box>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 700, height: "100%" }} aria-label="customized table">
            <TableHead sx={{ backgroundColor: 'white', color: 'black' }}>
              <TableRow>
                <StyledTableCell>{t("Stream")}</StyledTableCell>
                <StyledTableCell align="center" >{t("Service Status")}</StyledTableCell>
                <StyledTableCell align="center" >{t("Stream Status")}</StyledTableCell>
                <StyledTableCell align="center" >{t("Managers")}</StyledTableCell>
                <StyledTableCell align="center" >{t("Connected UAV")}</StyledTableCell>
                <StyledTableCell align="center" >{t("Model")}</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {clonePipelines.map((row) => (
                <StyledTableRow key={row.id}>
                  <StyledTableCell component="th" scope="row">{row.id}</StyledTableCell>
                  <StyledTableCell align="center"> {row.status === "True" ? <CircleIcon sx={{ color: 'green' }} /> : <CircleIcon sx={{ color: 'red' }} />}</StyledTableCell>
                  <StyledTableCell align="center">{row.broadcast_status ? <CircleIcon sx={{ color: 'green' }} /> : <CircleIcon sx={{ color: 'red' }} />}</StyledTableCell>
                  <StyledTableCell align="center">
                    <Select
                      displayEmpty
                      multiple
                      onChange={(e: SelectChangeEvent<string[]>) => { updateUserMap(row.id, e.target.value as string[]); updateSelectedManagers(e.target.value as string[], row.id) }}
                      input={<OutlinedInput />}
                      value={row.managers}
                      MenuProps={MenuProps}
                      inputProps={{ 'aria-label': 'Without label' }}
                      sx={{
                        width: '150px',
                        fontSize: "small"
                      }}
                    >
                      <MenuItem disabled sx={{ fontSize: "small" }} >
                        <em> {t("Select Manager")}</em>
                      </MenuItem>
                      {users.map((user) => (
                        <MenuItem
                          key={user.id}
                          value={user.id}
                          sx={{
                            fontSize: "small"
                          }}
                        >
                          {user.givenname}
                        </MenuItem>
                      ))}
                    </Select>
                  </StyledTableCell>

                  <StyledTableCell align="center">
                    <Autocomplete
                      sx={{
                        width: "450px",
                        margin: "auto",
                      }}
                      disableClearable
                      options={options}
                      defaultValue={row.yki_id}
                      getOptionDisabled={(option) => option === -1}
                      getOptionLabel={(option) => {
                        const matchOption = ykis.find(yki => yki.id === option);
                        if (option === -1) {
                          return t("Select Connected UAV");
                        } else {
                          return `${matchOption?.name}`;
                          // return `${matchOption?.name}${matchOption?.url}`;
                        }
                      }}
                      onChange={(event: any, newValue) => {
                        setYki(newValue); 
                        updateYkiMap(row.id, newValue as number); 
                        updateSelectedConnectedYKI(newValue as number, row.id);
                      }}
                      componentsProps={{
                        paper: {
                          sx: {
                            minWidth: 450,
                            maxHeight: '224px',
                          }
                        }
                      }}
                      renderInput={(params) => <TextField
                        id={params.id}
                        inputProps={params.inputProps}
                        InputProps={{
                          ...params.InputProps,
                          style: {
                            width: '450px',
                            fontSize: 'small'
                          }
                        }}
                      />}
                      renderOption={(props, option) => {
                        const matchOption = ykis.find(yki => yki.id === option);
                        return (
                          (option === options[0]) ?
                            (<li {...props}
                              style={{
                                fontSize: "small",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap"
                              }}>
                              <em> {t("Select Connected UAV")}</em>
                            </li>) :
                            (<li {...props}
                              key={option}
                              style={{
                                fontSize: "small",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap"
                              }}
                            >
                              {`${matchOption?.name}`}
                              {/*{`${matchOption?.name}${matchOption?.url}`}*/}
                            </li>)
                      )}} />
                    {/* <IconButton onClick={refreshPipeline(row.id, "http://192.168.3.136:5013")}>
                    <ReplayIcon />
                  </IconButton> */}
                  </StyledTableCell>

                  <StyledTableCell align="center">
                    <Select
                      error={row.model_id === -1 && true}
                      displayEmpty
                      onChange={(e) => { setModels(e.target.value as string); updateModelMap(row.id, e.target.value as number); updateSelectedModels(e.target.value as number, row.id) }}
                      input={<OutlinedInput />}
                      value={row.model_id}
                      MenuProps={MenuProps}
                      inputProps={{ 'aria-label': 'Without label' }}
                      sx={{
                        width: '150px',
                        fontSize: "small"
                      }}>
                      <MenuItem disabled sx={{ fontSize: "small" }} >
                        <em> {t("Select Model")}</em>
                      </MenuItem>
                      {models.map((model) => (
                        <MenuItem
                          key={model.id}
                          value={model.id}
                          sx={{
                            fontSize: "small"
                          }}>
                          {model.model_name}
                        </MenuItem>
                      ))}
                    </Select>
                  </StyledTableCell>
                </StyledTableRow>))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </>

  )
};


